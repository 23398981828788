import { InvoiceBalancePresenter } from './../../invoice-balance/presenter/invoice-balance.presenter';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Decimal from 'decimal.js';
import { ConfirmationService } from 'primeng/api';
import { Presenter } from 'src/app/core/presenter';
import { ClientModel } from 'src/app/models/client.model';
import { MeasureConversionComboModel } from 'src/app/models/measure-combo.model';
import { MeasureConversionModel, MeasureUnitPresenter } from 'src/app/models/measure-conversion.model';
import { OrderLine } from 'src/app/models/orderline.model';
import { ProcessModel } from 'src/app/models/process.model';
import { CollectionCenter, Product } from 'src/app/models/product.model';
import { ClearClient } from 'src/app/reducers/client.action';
import { ClearInvoice } from 'src/app/reducers/invoice.action';
import { DataProvider } from 'src/app/services/data.provider';
import { ProductService } from 'src/app/services/product.service';

import {
  AddIncrement,
  AddOrderLine,
  ChangeAutoAddStatus,
  ChangeLineDiscount,
  ChangeLineDiscountRecycling,
  ChangeLineQuantity,
  ChangeLineSuggestedPrice,
  ChangeLineUnitMeasure,
  ChangeMAxQuantity,
  ChangeProcess,
  ClearEditOrder,
  DeleteAllOrderLines,
  DeleteOrderLine,
  SetAdminPriceDiscount,
  SetCreditCardPayment,
  SetDefaultPrice,
  SetObservationInRedux,
} from '../../../../../../reducers/order.action';
import { DefaultconfigurationsService, GeneralConfigurations } from '../../../../../../services/defaultconfigurations.service';
import { SettingPresenter } from '../../../../shared/setting/presenter/setting.presenter';
import { TransferProvider } from '../../../dashboard/tranfers/transfer.provider';
import { InvoiceHeaderPresenter } from '../../invoice-header/presenter/invoice-header.presenter';
import { ProductQuestView } from '../product-quest.view';
import Swal from 'sweetalert2';
import { ClientService } from 'src/app/services/client.service';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { UsernameService } from 'src/app/services/currentUSer.service';
import { TypeStatusQuotation } from 'src/app/models/quotation.model';


@Injectable({
    providedIn: 'root',
})
export class ProductQuestPresenter implements Presenter {
    view: ProductQuestView;
    lines: OrderLine[] = [];
    orderLinesToRestore: OrderLine[] = [];
    procesesSelected: ProcessModel;

    constructor(
        private productService: ProductService,
        private presenterSetting: SettingPresenter,
        public defaultconfigurationsService: DefaultconfigurationsService,
        public clientService: ClientService,
        public defaultConfigurationService: DefaultconfigurationsService,
        public invoiceHeaderPresenter: InvoiceHeaderPresenter,
        public invoiceBalancePresenter: InvoiceBalancePresenter,
        public confirmationService: ConfirmationService,
        private store: Store<{ client: ClientModel, orderLines: OrderLine }>,
        private dataProvider: DataProvider,
        private usernameService: UsernameService,
        public transferProvider?: TransferProvider


    ) {
        this.transferProvider.linesPendingToInvoice$.subscribe(() => {
            this.filterTransferLinesToInvoice();
        });
    }

    init() {

        this.store.pipe(select('orderLines')).subscribe(val => {
            this.view.isCredired = this.view.validateProductCredired(val.orderLines);
            this.lines = val.orderLines;
            this.view.orderLines = val.orderLines;
            this.orderLinesToRestore = val.orderLines;
            this.view.updateOrder = val.updateOrder;
            this.view.orderLinesToShow = this.view.orderLines.filter(line => line.active);
            this.view.updateRowGroupMetaData();
            this.view.addToProductQuest = val.autoAddStatus;
            this.view.creditCardPayment = val.creditCardPayment;
            this.view.adminPriceDiscount = val.isAdminPriceDiscount;
            this.view.procesesSelected = val.procesesSelected;
            this.procesesSelected = val.procesesSelected;
            this.checkDisabledInputs();
        });

        this.store.pipe(select('client')).subscribe(val => {
            if (val.client) {
                this.view.selectedClient = val.client;
            } else {
                this.view.selectedClient = null;
            }
        });

    }



    onSearchProductPos() {
        const searchValue = this.view.search;
        const page = this.view.page;
        const size = this.view.size;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        if (this.view.addToProductQuest) {
            this.view.selectedProduct = null;
            this.view.search = '';
        }
        const clientId = this.view.selectedClient ? this.view.selectedClient.clientId : null;
        this.productService.searchProduct(searchValue, page, size, prceListId, clientId).then(data => {
            const title: Product = {
                referenceCode: 'CÓDIGO',
                name: 'NOMBRE'
            };
            const products: Product[] = data['data'];
            this.view.totalElements = data['totalElements'];
            if (products.length <= 0) {
                this.view.showError('', 'Búsqueda sin registros');
                this.view.products = [];
            } else
                if (this.view.addToProductQuest && products.length === 1) {
                    this.view.selectedProduct = products[0];
                    this.view.selectedProduct.assignedQuantity = this.view.selectedProduct.warehousePresenters[0].assignedQuantity;
                    this.view.selectedProduct.availableQuantity = this.view.selectedProduct.warehousePresenters[0].availableQuantity;
                    this.addOrderLine();
                    this.view.products = [];
                } else {
                    products.splice(0, 0, title);
                    this.view.products = products;
                    this.getCollectionCenterStock(this.view.products);
                    this.setTransferStock();
                }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    getCollectionCenterStock(products: Product[]) {
        const collectionCenter: CollectionCenter[] = [];
        products.forEach(prod => {
            if (prod.productId) {
                prod.assignedQuantity = (prod.warehousePresenters[0].assignedQuantity);
                prod.availableQuantity = (prod.warehousePresenters[0].availableQuantity);
                collectionCenter.push({ productPresenter: { productId: prod.productId, referenceId: prod.referenceId } });
            }
        });
        products.sort((a, b) => b.availableQuantity - a.availableQuantity);
        /*
        this.productService.getCollectionCenterStock(collectionCenter).then((collections: CollectionCenter[]) => {
            if (collections.length > 0) {
                collections.forEach(collection => {
                    products.forEach(prod => {
                        if (collection.productPresenter.productId === prod.productId) {
                            collection.warehousePresenter.availableQuantity = Math.trunc(collection.warehousePresenter.availableQuantity);
                            prod.warehousePresenters.push(collection.warehousePresenter);
                            prod.collectionCenterStock = collection.warehousePresenter.availableQuantity;
                        }
                    });
                });
            }

        }).catch(error => { });*/
    }

    addOrderLine() {
        // this.view.confirmAddCrediRed(this.view.selectedProduct.referenceCode, this.dataProvider.isAdminPriceDiscount);
        // && this.dataProvider.isAdminPriceDiscount}
        let heavy = false;
        let ifExistService = false;
        if (this.view.orderLines.length >= 0) {
            this.view.orderLines.forEach(element => {
                if (element.product.isHeavy) {
                    heavy = true;
                }
                if (element.product.referenceCode.toLowerCase() === 'l20179') {
                    ifExistService = true;
                }
            });
        }
        if (this.view.selectedProduct.referenceCode.toLowerCase() === 'l20179') {
            this.invoiceHeaderPresenter.init();
            this.confirmationService.confirm({
                message: `Los precios se actualizarán a precios normal, ¿Desea continuar?`,
                header: 'Agregar CREDIRED',
                acceptLabel: 'Aceptar',
                rejectLabel: 'Cancelar',
                accept: () => {
                    if (this.procesesSelected === ProcessModel.QUOTATION) {
                        this.view.showError('', 'Opción no permitida en Cotización');
                        this.view.setSearchToNull();
                        return;
                    } else if (this.dataProvider.isHeavy && heavy) {
                        Swal.fire({
                            text: 'Tiene productos identificados como pesados, debe eliminar ' +
                                ' para agregar este servicio',
                            showCloseButton: true,
                        });
                        this.view.setSearchToNull();
                        return;
                    } else {
                        this.addOrderLineConfirm();
                        // this.invoiceBalancePresenter.onChangePaymentTypeCredired()
                        this.invoiceHeaderPresenter.showAdminPasswordCredired();
                        // this.defaultConfigurationService.showCredentialsAdminPriceDiscount = true;
                        this.dataProvider.processPayment = 'credired';
                        this.dataProvider.isCrediRedPrice = true;
                        GeneralConfigurations.creditCardPayment = false;
                        this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard
                            , this.dataProvider.processPayment));
                        // this.store.dispatch(new SetBilleton(false));
                        this.store.dispatch(new SetAdminPriceDiscount(false));
                        this.store.dispatch(new AddIncrement(0));
                    }
                },
                reject: () => {
                    this.view.selectedProduct = null;
                },
            });
        } else {
            if (this.dataProvider.isHeavy && ifExistService && this.view.selectedProduct.isHeavy) {
                Swal.fire({
                    text: 'Tiene agregado el servicio Credired: No se puede agregar productos identificados como pesado',
                    showCloseButton: true,
                });
                this.view.setSearchToNull();
                return;
            } else {
                this.addOrderLineConfirm();
            }
        }
    }

    addOrderLineConfirm() {
        if (this.view.selectedProduct && typeof this.view.selectedProduct !== 'string') {

            if (!this.view.selectedProduct.productId) {
                this.view.setSearchToNull();
                return;
            }
            this.store.dispatch(new ChangeMAxQuantity([this.view.selectedProduct]));

            if (!this.quantityAvalable(this.view.selectedProduct) && this.procesesSelected
                !== ProcessModel.QUOTATION && this.view.selectedProduct.referenceCode !== 'L20179') {
                this.view.showError('', 'stock agotado');
                this.view.setSearchToNull();
                return;
            }
            const maxDiscount = new Decimal(100)
                .minus(new Decimal(this.view.selectedProduct.priceLimitWithoutTax || 1)
                    .mul(100).div(this.view.selectedProduct.priceWithoutTax || 1)).toNumber();
            let discount = !this.view.creditCardPayment ? this.view.selectedProduct.agreementDiscount : 0;
            discount = discount > maxDiscount ? maxDiscount : discount;

            // calculate values for show line
            const line: OrderLine = {
                orderDetailId: null,
                description: this.view.selectedProduct.description,
                name: this.view.selectedProduct.name,
                productId: this.view.selectedProduct.productId,
                referenceCode: this.view.selectedProduct.referenceCode,
                taxToShow: this.view.selectedProduct.taxPresenter.value * this.view.selectedProduct.priceWithoutTax,
                tax: this.view.selectedProduct.taxPresenter,
                price: 0,
                priceWithDiscount: 0,
                priceWithoutTax: this.view.selectedProduct.priceWithoutTax,
                enteredQuantity: this.view.selectedProduct.availableQuantity >= 1 ? 1 : this.view.selectedProduct.availableQuantity,
                convertedQuantity: this.view.selectedProduct.availableQuantity >= 1 ? 1 : this.view.selectedProduct.availableQuantity,
                subTotal: this.view.selectedProduct.priceWithoutTax,
                total: 0,
                product: this.view.selectedProduct,
                active: true,
                priceList: this.presenterSetting.view.selectedPrice,
                measureCombo: this.getCombo(this.view.selectedProduct),
                selectedMeasure: this.getDefault(this.view.selectedProduct),
                maxQuantity: this.view.selectedProduct.availableQuantity,
                discount: discount,
                maxDiscount: maxDiscount >= 0 ? maxDiscount : 0,
                normalPrice: this.view.selectedProduct.normalPriceWithoutTax,
                warehousePresenter: this.view.selectedProduct.warehousePresenters[0],
                inventoryMovementDetailId: null,
                totalWithoutPromo: 0,
                discountPromotion: 0,
                promotions: [],
                isRecycling: this.view.selectedProduct.isRecycling,
                discountRecycling: this.view.selectedProduct.discountRecycling,
                quantityRecycling: 0,
                limit: this.view.selectedProduct.priceLimitWithoutTax,
                stockPDV: this.view.selectedProduct.stockPDV,
                suggestedPrice: this.view.selectedProduct.suggestedPrice,
                suggestedPriceBusinessManager: this.view.selectedProduct.suggestedPriceBusinessManager,
                base: this.view.selectedProduct.basePrice
            };
            line.warehousePresenter.local = line.warehousePresenter.referenceId === this.dataProvider.defaultWarehouse.referenceId;
            if ((this.procesesSelected === ProcessModel.INVOICE || this.procesesSelected === ProcessModel.QUOTATION)) {
                this.validateAddLine(line);
            }
            this.store.dispatch(new AddOrderLine(line, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.view.selectedOrderLine = line;
            this.changeLineDiscount();
            this.view.setSearchToNull();
            if (!this.view.addToProductQuest) {
                this.view.setFocusInTable();
            }
            if (this.view.creditCardPayment) {
                this.store.dispatch(new AddIncrement(this.defaultconfigurationsService.defaultCreditDebitPercentageAmount));
            }
            if (this.view.isCredired) {
                this.invoiceHeaderPresenter.showAdminPasswordCredired();
            }
        }


    }

    clearAll() {
        this.view.selectedProduct = null;
        this.store.dispatch(new ClearEditOrder());
        this.store.dispatch(new ClearClient());
        this.store.dispatch(new DeleteAllOrderLines());
        this.store.dispatch(new ClearInvoice());
        this.store.dispatch(new SetObservationInRedux(''));
        this.store.dispatch(new ChangeProcess(ProcessModel.INVOICE));
        this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.dataProvider.processPayment = undefined;
        this.dataProvider.isNewQuotation = true;
        this.dataProvider.quotationStatus = null;
        this.dataProvider.quotationId = null;
        this.dataProvider.isAdminPriceDiscount = false;
        this.dataProvider.isCrediRedPrice = false;
    }

    quantityAvalable(product: Product): boolean {
        const productId = product.productId;
        if (this.lines) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.lines.length; i++) {
                const line: OrderLine = this.lines[i];
                if (line.productId === productId && line.active &&
                    line.warehousePresenter.referenceId === product.warehousePresenters[0].referenceId) {
                    if (line.maxQuantity - (Number(line.enteredQuantity) + 1) < 0) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        }
        return true;
    }

    private getDefault(product: Product): MeasureConversionModel {
        let comboMeasure: MeasureConversionModel = null;
        if (product.measureUnitConversionPresenters) {
            product.measureUnitConversionPresenters.forEach(unit => {
                if (unit.default) {
                    comboMeasure = unit;
                    return comboMeasure;
                }
            });
        }
        return comboMeasure;

    }

    private getCombo(product: Product): MeasureConversionComboModel[] {
        return product.measureUnitConversionPresenters.map(measure => {
            return { label: measure.measureUnitPresenterTo.name, value: measure };
        });

    }

    getSelectedMeasure(product: Product, measureTo: MeasureUnitPresenter): MeasureConversionModel {
        for (const measure of product.measureUnitConversionPresenters) {
            if (measure.measureUnitPresenterTo.measureUnitId === measureTo.measureUnitId) {
                return measure;
            }
        }
        return null;
    }

    deleteOrderLine() {
        if (this.view.selectedOrderLine) {
            const line = this.view.selectedOrderLine;
            this.store.dispatch(new DeleteOrderLine(line));
            if (this.view.selectedOrderLine.referenceCode  === 'L20179') {
                this.dataProvider.processPayment = undefined;
                this.dataProvider.isCrediRedPrice = false;
                GeneralConfigurations.creditCardPayment = true;
                this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            }
            if (!this.view.adminPriceDiscount && (this.view.roles === '' || this.view.roles === undefined)) {
                this.store.dispatch(new SetDefaultPrice());
            }
            if (this.view.creditCardPayment) {
                this.store.dispatch(new AddIncrement(this.defaultconfigurationsService.defaultCreditDebitPercentageAmount));

            }
            this.view.selectedOrderLine = null;
            this.filterTransferLinesToInvoice();
            this.invoiceBalancePresenter.onChangePaymentType();
        }
        this.filterLines();
        if (this.view.isCredired) {
            this.invoiceHeaderPresenter.showAdminPasswordCredired();
        }
    }
    filterLines() {
        if (this.view.orderLines.length === 0) {
            this.dataProvider.processPayment = undefined;
            this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.store.dispatch(new SetAdminPriceDiscount(false));
        }
    }
    changeLine() {
        if (this.procesesSelected === ProcessModel.QUOTATION) {

            const line = this.view.selectedOrderLine;
            if (isNaN(line.enteredQuantity) || !line.enteredQuantity || Number(line.enteredQuantity) === 0) {
                line.enteredQuantity = 1;
                this.view.showInfo('Prohibido colocar cero productos', 'Se procede a colocar 1');
            }
            this.store.dispatch(new ChangeLineQuantity(line));
            this.view.selectedOrderLine = null;
            return;
        }
        if (this.view.selectedOrderLine) {

            const line = this.view.selectedOrderLine;
            if (!line.enteredQuantity || (isNaN(line.enteredQuantity))) {

                this.view.showError('', 'cantidad erronea');
                if (line.product.availableQuantity > 1) {
                    line.enteredQuantity = 1;
                } else {
                    line.enteredQuantity = line.maxQuantity;
                }

            } else {
                line.maxQuantity = line.product.availableQuantity * line.selectedMeasure.multiplyRate;
                if (line.enteredQuantity <= 0) {
                    this.view.showError('', 'cantidad errónea');
                    line.enteredQuantity = 1;
                }
            }

            this.store.dispatch(new ChangeLineQuantity(line));
            this.view.selectedOrderLine = null;
        }
    }
    changeLineSuggestedPrice() {
        if (this.procesesSelected === ProcessModel.QUOTATION) {

            const line = this.view.selectedOrderLine;
            if (isNaN(line.suggestedPrice) || !line.suggestedPrice || Number(line.suggestedPrice) === 0) {
                line.suggestedPrice = 0; // si el sugerido es 0, coloca el precio de hoy
            }
            this.store.dispatch(new ChangeLineSuggestedPrice(line));
            this.view.selectedOrderLine = null;
        }
    }
    changeLineUnitMeasure() {
        if (this.view.selectedOrderLine) {
            const line = this.view.selectedOrderLine;
            line.maxQuantity = line.product.availableQuantity * line.selectedMeasure.multiplyRate;
            this.store.dispatch(new ChangeLineUnitMeasure(line));
            this.view.selectedOrderLine = null;
        }
    }
    // calculta discount when change price list
    changeLineDiscount() {
        if (!this.view.selectedOrderLine.discount) {
            this.view.selectedOrderLine.discount = 0;
        }
        if (!this.view.selectedOrderLine.product.priceWithoutTax) {
            this.view.selectedOrderLine.product.priceWithoutTax = this.view.selectedOrderLine.selectedMeasure.price;
        }
        this.store.dispatch(new ChangeLineDiscount(this.view.selectedOrderLine));
        this.view.selectedOrderLine = null;
    }


    changeAutoAddStatus() {
        this.store.dispatch(new ChangeAutoAddStatus(this.view.addToProductQuest));
    }

    addTransferLineToOrderLine() {

        const product = this.view.selectedTransferLine.productPresenter;
        const maxDiscount = Number((100 - Number(((product.priceLimitWithoutTax * 100) /
            product.priceWithoutTax))));
        const discount = 0;
        const selectedMeasure = this.getSelectedMeasure(product,
            this.view.selectedTransferLine.measureUnitPresenter);
        product.availableQuantity = new Decimal(this.view.selectedTransferLine.acceptedEnteredQuantity)
            .mul(selectedMeasure.divideRate).toNumber();
        const price = product.priceWithTax;
        product.priceWithoutTax = selectedMeasure.price;
        product.normalPriceWithoutTax = selectedMeasure.normalPrice;
        product.priceLimitWithoutTax = selectedMeasure.priceLimit;
        product.specialPrice = selectedMeasure.specialPrice;
        product.wholesalerPrice = selectedMeasure.wholesalerPrice;
        product.administratorPrice = selectedMeasure.administratorPrice;
        product.normalPriceWithoutTax = selectedMeasure.normalPrice;
        product.priceLimitWithoutTax = selectedMeasure.priceLimit;
        product.basePrice = selectedMeasure.basePrice;

        const line: OrderLine = {
            orderDetailId: null,
            transferDetailPresenter: this.view.selectedTransferLine,
            description: product.description,
            name: product.name,
            productId: product.productId,
            referenceCode: product.referenceCode,
            taxToShow: product.taxPresenter.value * product.priceWithoutTax,
            tax: product.taxPresenter,
            price: price,
            priceWithDiscount: price,
            priceWithoutTax: product.priceWithoutTax,
            enteredQuantity: this.view.selectedTransferLine.acceptedEnteredQuantity,
            convertedQuantity: this.view.selectedTransferLine.requestedConvertedQuantity,
            subTotal: product.priceWithoutTax,
            total: price,
            product: product,
            active: true,
            priceList: this.presenterSetting.view.selectedPrice,
            measureCombo: this.getCombo(product),
            selectedMeasure: selectedMeasure,
            maxQuantity: product.availableQuantity,
            discount: discount,
            maxDiscount: maxDiscount >= 0 ? maxDiscount : 0,
            normalPrice: product.normalPriceWithoutTax,
            warehousePresenter: this.view.selectedTransferLine.origin,
            inventoryMovementDetailId: null,
            totalWithoutPromo: price,
            discountPromotion: 0,
            promotions: [],
            disableMeasure: true,
            discountRecycling: product.discountRecycling,
            isRecycling: product.isRecycling
        };
        this.validateAddLine(line);
        this.store.dispatch(new AddOrderLine(line, this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.store.dispatch(new ChangeLineUnitMeasure(line));
        this.view.selectedOrderLine = line;
        this.changeLineDiscount();
        this.view.setSearchToNull();
        this.filterTransferLinesToInvoice();
        if (!this.view.addToProductQuest) {
            this.view.setFocusInTable();
        }
        if (this.view.creditCardPayment) {
            this.store.dispatch(new AddIncrement(this.defaultconfigurationsService.defaultCreditDebitPercentageAmount));
        }
    }



    filterTransferLinesToInvoice() {
        this.transferProvider.filteredLinesToInvoice = this.transferProvider.linesPendingToInvoice
            .filter(l =>
                !(this.lines.find(line => (line.product.productId === l.productPresenter.productId &&
                    line.warehousePresenter.warehouseId === l.origin.warehouseId)
                    || (line.product.productId === l.productPresenter.productId &&
                        line.selectedMeasure.measureUnitPresenterTo.referenceId !== l.measureUnitPresenter.referenceId)
                )));
    }

    setTransferStock() {
        this.view.products.forEach(product => {
            product.transferStock = this.transferProvider.linesPendingToInvoice
                .filter(line => line.productPresenter.referenceId === product.referenceId)
                .map(l => l.acceptedConvertedQuantity)
                .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);
        });

    }

    validateAddLine(line: OrderLine) {
        this.view.orderLinesToShow.forEach((orderLine: OrderLine) => {
            if (orderLine.productId === line.productId) {
                // Transfer line
                if (line.transferDetailPresenter) {
                    orderLine.selectedMeasure = line.selectedMeasure;
                    orderLine.measureCombo = line.measureCombo;
                    orderLine.product.priceWithoutTax = line.selectedMeasure.price;
                    orderLine.product.normalPriceWithoutTax = line.selectedMeasure.normalPrice;
                    orderLine.product.priceLimitWithoutTax = line.selectedMeasure.basePrice;
                    orderLine.product.specialPrice = line.selectedMeasure.specialPrice;
                    orderLine.product.wholesalerPrice = line.selectedMeasure.wholesalerPrice;
                    orderLine.product.administratorPrice = line.selectedMeasure.administratorPrice;
                    orderLine.product.normalPriceWithoutTax = line.selectedMeasure.normalPrice;
                    orderLine.product.priceLimitWithoutTax = line.selectedMeasure.basePrice;
                    orderLine.maxDiscount = line.transferDetailPresenter.acceptedEnteredQuantity;
                    this.store.dispatch(new ChangeLineUnitMeasure(orderLine));
                    // Collection center line or local line
                } else {
                    line.selectedMeasure = orderLine.selectedMeasure;
                    line.measureCombo = orderLine.measureCombo;
                    line.maxQuantity = new Decimal(line.selectedMeasure.multiplyRate).
                        mul(line.product.availableQuantity).toNumber();
                    line.product.priceWithoutTax = orderLine.selectedMeasure.price;
                    line.product.normalPriceWithoutTax = orderLine.selectedMeasure.normalPrice;
                    line.product.priceLimitWithoutTax = orderLine.selectedMeasure.basePrice;
                    line.product.specialPrice = orderLine.selectedMeasure.specialPrice;
                    line.product.wholesalerPrice = orderLine.selectedMeasure.wholesalerPrice;
                    line.product.administratorPrice = orderLine.selectedMeasure.administratorPrice;
                    line.product.normalPriceWithoutTax = orderLine.selectedMeasure.normalPrice;
                    line.product.priceLimitWithoutTax = orderLine.selectedMeasure.basePrice;
                    this.store.dispatch(new ChangeLineUnitMeasure(line));
                }

            }


        });
    }

    checkDisabledInputs() {
        this.dataProvider.disableRecycling = false;
        this.view.orderLinesToShow.forEach(orderLine => {
            orderLine.disableMeasure =
                this.view.orderLines.filter(l => l.product.productId === orderLine.product.productId && l.transferDetailPresenter)
                    .length > 0;

            orderLine.disableAdminPrice =
                this.view.orderLines.filter(l => l.product.productId === orderLine.product.productId && l.discountPromotion)
                    .length > 0;
            this.view.isRecycling =
                this.view.orderLines.filter(l => l.isRecycling)
                    .length > 0;

            this.dataProvider.disableRecycling =
                this.view.orderLines.filter(l => l.quantityRecycling >= 1).length > 0;
        });

    }

    changeIsRecycling(line: OrderLine) {
        this.store.dispatch(new ChangeLineDiscountRecycling(line));
    }

    validateProductCredired() {
        return this.view.orderLinesToShow.some((element) => element.product.referenceCode === 'L20179');
    }



    loadUserPriceId() {
        this.usernameService.getCurrentUser().subscribe(
            (data: any) => {
                const defaultUser = data;
                this.clientService.getUserPrices(defaultUser.userId).subscribe(
                    (res: any) => {
                        if (res && res.price) {
                            this.view.loadUserPrice = res;
                            this.view.roles = this.view.loadUserPrice.price;
                            const roleValues = {
                                ESPECIAL: 1,
                                WHOLE_SALER: 2,
                                ADMINISTRATOR: 3,
                                LIMIT: 4,
                                BASE: 5
                            };
                            const rolesArray = this.view.roles.split(',');
                            this.view.highestRole = rolesArray[0];
                            for (let i = 1; i < rolesArray.length; i++) {
                                if (roleValues[rolesArray[i]] > roleValues[this.view.highestRole]) {
                                    this.view.highestRole = rolesArray[i];
                                }
                            }
                            console.log(this.view.highestRole);
                        } else {
                            this.resetPrice();
                        }
                    },
                    (error) => {
                        console.error('Error al recuperar los precios de los usuarios', error);
                        this.resetPrice();
                    }
                );
            }
        );
    }
    resetPrice() {
        this.view.loadUserPrice = null;
        this.view.roles = '';
    }
}
