import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AbstractView } from '../../../../../core/abstract_view';
import { MessageService, DynamicDialogRef, SelectItem, DialogService } from 'primeng/api';
import { Router } from '@angular/router';
import { Payment } from '../../../../../models/payment.model';
import { PaymentMethod } from '../../../../../models/paymentmethod';
import { BankAccount } from '../../../../../models/bankaccount.model';
import { ClientModel, UserModel } from '../../../../../models/client.model';
import { PaymentsDetailPresenter } from './presenter/payments-detail.presenter';
import { Bank, Card } from 'src/app/models/bank.model';
import { RelationTypeBankAccount } from '../../../../../models/relationaltyebankaccount.model';
import { CreditCardEntity } from 'src/app/models/bankentity';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import Swal from 'sweetalert2';
import { CreditCardType, CreditDeferredType } from 'src/app/models/creditcardtype.model';
import { DataProvider } from '../../../../../services/data.provider';
import { AdminPricePasswordComponent } from '../../../shared/admin-price-password/admin-price-password.component';
import { DomainAction } from 'src/app/models/rol.model';
import Decimal from 'decimal.js';
import { PaymentsDetailView } from './payments-detail.view';
import { GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';
@Component({
    selector: 'app-payments-detail',
    templateUrl: './payments-detail.component.html',
    styleUrls: ['./payments-detail.component.scss'],
})
export class PaymentsDetailComponent extends AbstractView implements OnInit, PaymentsDetailView, AfterViewInit {



    @ViewChild('cash', { read: false, static: false }) cashbox: ElementRef;
    payments: Payment[];

    paymentsMethods: { label: string, value: PaymentMethod }[] = [
        { label: 'Efectivo', value: PaymentMethod.CASH },
        { label: 'Cheque', value: PaymentMethod.CHECK },
        { label: 'Nota de crédito', value: PaymentMethod.CREDIT_MEMO },
        { label: 'Retención', value: PaymentMethod.WITHHOLD },
        { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER },
        { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD },
        { label: 'Tarjeta de Débito', value: PaymentMethod.DEBIT },
        { label: 'Total sin despacho', value: PaymentMethod.TOTAL_WITHOUT_DISPATCH },
        { label: 'Credired', value: PaymentMethod.CREDIRED}
    ];
    paymentMethodSelected: { label: string, value: PaymentMethod } = this.paymentsMethods[0];
    fee: number;
    amountFee: number;

    banksEntities: Card[] = [];
    creditCardSelected: Card;
    debitCardSelected: Card;
    creditEntitySelected: CreditCardEntity;
    creditBankSelected: Bank;
    debitBankSelected: Bank;
    debitEntitySelected: CreditCardEntity;


    creditCardTypes: { label: string, value: CreditCardType }[] = [];
    creditCardTypeSelected: CreditCardType;
    creditDeferredTypes: { label: string, value: CreditDeferredType }[] = [];
    creditDeferredTypeSelected: CreditDeferredType;

    paymentFees: { label: string, value: number }[];
    paymentFeeSelected: any;

    butDisabled: false;
    requestOverdraft: false;

    clientBankAccounts: { label: string, value: BankAccount }[];
    subTotal12: number;
    defaultTax: any;
    subTotal0: number;
    subTotal: number;
    total: number;
    tax: number;
    checkNumber: string;
    checkAmount: number;
    effectiveDate = new Date();
    minDate = new Date();
    clientBankAccountSelected: { label: string, value: BankAccount };
    client: ClientModel;
    banks: { label: string, value: Bank }[];
    bankSelected: { label: string, value: Bank };
    relationTypeBankAccounts: { label: string, value: RelationTypeBankAccount }[];
    relationTypeBankAccountSelected: { label: string, value: RelationTypeBankAccount };
    accountNumber: string;
    checkConfirmationCode: string;
    totalInvoice = 0;
    totalPayments: number;
    totalAdvance: number;
    cashAmount: number;
    subventionAmount: number;
    voucherNumber: string;
    creditCardAmount: number;
    transferAmount: number;
    transferNumber: string;
    withholdAmount: number;
    withholdNumber: string;
    creditMemoAmount: number;
    creditMemoNumber: string;
    debitCardAmount: number;
    voucherNumberDebit: string;
    isChange = 'Cambio';
    selectedRowIndex = -1;
    isInvoiceProcess = false;
    credit = false;
    blockDetails = false;
    blockCard = false;
    creditLote: string;
    creditReference: string;
    creditAuth: string;
    pending = 0;

    debitLote: string;
    debitReference: string;
    debitAuth: string;

    cardBanks: Bank[] = [];
    cardBanksCopy: Bank[] = [];
    selectedCreditCardBank: Bank;
    selectedDebitCardBank: Bank;
    selectedBank: Bank;
    searchBankValue: string;
    adviser: ClientModel;
    ownBanks: Bank[];
    filteredBankAccounts: BankAccount[];
    bankAccountSelected: BankAccount;

    paymentsDiferenOfCash = 0;
    paymentSubvention = 0;
    paymentCash = 0;

    isGrace = false;
    graces: { label: string, value: number }[] = [
        { label: '1', value: 1 },
        { label: '2', value: 2 },

    ];

    feesCredired: { label: string, value: number }[] = [
        { label: '3', value: 3 },
        { label: '6', value: 6 },
        { label: '9', value: 9 },
        { label: '12', value: 12 },
        { label: '18', value: 18 },
        { label: '24', value: 24 },
        { label: '36', value: 36 },
    ];

    selectedGrace = null;
    isBilleton = false;
    debitCards: Card[] = [];
    creditCards: Card[] = [];
    public ref;
    isPromoDay = false;
    isisLowerPrice = false;
    isQuotation = false;
    isEntryByCredired = false;
    crediRedCode = '';
    selectedQuotesCredired = { label: '3', value: 3 };
    seller: UserModel;
    deUna = false;
    constructor(
        private messageService: MessageService,
        public router: Router,
        private hotKey: HotkeysService,
        public paymentsDetailPresenter: PaymentsDetailPresenter,
        private dynamicDialogRef: DynamicDialogRef,
        public dataProvider: DataProvider,
        public dialogService: DialogService) {
        super(messageService, router);
        paymentsDetailPresenter.view = this;

    }

    shortcuts() {
        this.hotKey.add(new Hotkey(
            ['alt+g', 'ctrl+s', 'alt+s', 'ctrl+shift+g', 'alt+shift+g', 'ctrl+shift+s', 'alt+shift+s'],
            (event: KeyboardEvent): boolean => {
                this.generateInvoice();
                return false;
            }, ['input', 'textarea']));
    }

    ngOnInit(): void {
        this.paymentsDetailPresenter.init();
        this.paymentsDetailPresenter.loadBanksFromAtix();
        this.paymentsDetailPresenter.getBanksByCriteria();
        this.paymentsDetailPresenter.getConnectionCpos();
        this.paymentsDetailPresenter.getCoupons();
    }

    ngAfterViewInit(): void {
        this.cashbox.nativeElement.focus();
        this.shortcuts();
    }

    onClickSaveBankAccount() {
        this.paymentsDetailPresenter.saveBankAccount();
    }

    addPayment() {
        this.dataProvider.isConfigurationCpos ? this.paymentsDetailPresenter.addPaymentCpos() : this.paymentsDetailPresenter.addPayment();
    }

    deleteRow(payment: Payment, evt) {
        this.selectedRowIndex = evt;
        if (payment.paymentMethod === PaymentMethod.CREDIT_CARD || payment.paymentMethod === PaymentMethod.DEBIT) {
            this.showConfirmationDelete(payment);
        } else {
            this.paymentsDetailPresenter.deletePayment();
        }
    }

    showConfirmationDelete(payment: Payment) {
        const paymentName = payment.paymentMethod === PaymentMethod.CREDIT_CARD ? 'Crédito' : 'Débito';
        Swal.fire({
            text: '¿Eliminar el pago de Tarjeta de ' + paymentName + '?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.value) {
                // this.showAdminPassword(payment);
                this.dataProvider.isConfigurationCpos
                  ? this.paymentsDetailPresenter.deleteCardPayment(payment)
                  : this.paymentsDetailPresenter.deletePayment();
            }
        });
    }

    generateInvoice() {
        this.dataProvider.isConfigurationCpos
          ? this.paymentsDetailPresenter.validateCpos()
          : this.paymentsDetailPresenter.validate();
    }

    evaluateCredit() {
        this.clearAllInputs();
        this.paymentsDetailPresenter.evaluatePayemnts();
    }
    clearAllInputs() {
        this.cashAmount = null;
        this.checkAmount = null;
        this.checkNumber = null;
        this.checkConfirmationCode = null;
        this.clientBankAccountSelected = null;
        this.selectedBank = null;
        this.accountNumber = null;
        this.transferAmount = null;
        this.transferNumber = null;
        this.selectedBank = null;
        this.bankAccountSelected = null;
        this.creditMemoAmount = null;
        this.creditMemoNumber = null;
        this.withholdAmount = null;
        this.withholdNumber = null;
        this.creditCardAmount = null;
        this.creditBankSelected = null;
        this.creditCardSelected = null;
        this.creditCardTypeSelected = null;
        this.paymentFeeSelected = null;
        this.selectedGrace = null;
        this.creditLote = null;
        this.creditReference = null;
        this.creditAuth = null;
        this.debitCardAmount = null;
        this.debitBankSelected = null;
        this.debitCardSelected = null;
        this.debitLote = null;
        this.debitReference = null;
        this.debitAuth = null;
        this.subventionAmount = null;
    }

    dispose(viewMessage: boolean) {
        if (!viewMessage) {
            const paymentsCards = this.payments.filter(
                (payment) =>
                    payment.paymentMethod === PaymentMethod.CREDIT_CARD ||
                    payment.paymentMethod === PaymentMethod.DEBIT
            ).length;
            const paymentsDeUna = this.payments.filter(
                (payment) =>
                    payment.paymentMethod === PaymentMethod.DE_UNA
            ).length;
            if (paymentsCards > 0) {
                Swal.fire({
                    title: 'Alerta!',
                    text: 'Tiene pagos con tarjetas, debe anularlos para salir',
                });
                return;
            }
            if (paymentsDeUna > 0) {
                Swal.fire({
                    title: 'Alerta!',
                    text: 'Tiene pagos con De Una, debe anularlos para salir',
                });
                return;
            }
        }
        this.dynamicDialogRef.close(true);
    }

    showConfirmation() {
        if (this.blockDetails) {
            return;
        }
        Swal.fire({
            text: '¿Desea generar la factura?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Facturar'
        }).then((result) => {
            if (result.value) {
                this.paymentsDetailPresenter.generateInvoice();
            }
        });
    }
    showConfirmationOverdraft() {
        if (this.blockDetails) {
            return;
        }
        Swal.fire({
            text: '¿Desea generar sobregiro?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Generar'
        }).then((result) => {
            if (result.value) {
                this.paymentsDetailPresenter.generateOverdraft();
            }
        });
    }
    filterCardBanks(filter: string) {
        this.searchBankValue = filter;
        this.paymentsDetailPresenter.getBanksByCriteria();
    }
    filterAccounts() {
        this.paymentsDetailPresenter.filterBankAccounts();
    }
    change() {
        if (!this.isGrace) {
            this.selectedGrace = null;
        }
    }
    onCardChange() {
        this.selectedGrace = null;
        this.paymentFeeSelected = null;
        this.paymentsDetailPresenter.filterCards();
        this.paymentsDetailPresenter.validatePromoBank();
    }

    onTypeCreditChange() {
        this.creditDeferredTypeSelected = null;
        this.paymentFeeSelected = null;
        if (this.creditCardTypeSelected.valueOf() !== CreditCardType.CREDIT_WITHOUT_INTEREST) {
            this.selectedGrace = null;
        }
        if (this.creditCardTypeSelected.valueOf() === CreditCardType.CURRENT_PAYMENT) {
            this.creditDeferredTypes = [
                { label: 'Normal o corriente', value: CreditDeferredType.NORMAL_OR_CURRENT },
            ];
            if (this.creditCardSelected) {
                this.creditCardSelected.paymentFeesBank = null;
                this.creditCardSelected.paymentFeesBank = [{ number: 1, name: '1' }];
                this.paymentFeeSelected = { number: 1, name: '1' };
                this.creditDeferredTypeSelected = CreditDeferredType.NORMAL_OR_CURRENT;
            }
        } else {
            this.paymentsDetailPresenter.filterCards();
        }
        this.paymentsDetailPresenter.onTypeCreditChange();
    }
    onBankChange() {
        this.creditCardSelected = null;
        this.debitCardSelected = null;
        this.paymentFeeSelected = null;
        this.creditCardTypeSelected = null;
        this.creditDeferredTypeSelected = null;
        this.paymentsDetailPresenter.filterCardsPresenter();
    }
    showBanksSpecialDay() {
        Swal.fire({
            title: 'HOY hasta 12 meses sin intereses + Meses 2 de Gracia con Bancos y Cooperativas participantes ',
            text: '¿Desea confirmar la promoción ?',
            showCancelButton: true,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar',
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                if (this.payments.length >= 1) {
                    this.showConfirmDeletePayments();
                } else {
                    this.paymentsDetailPresenter.setNewInvoice(true);
                    this.paymentsDetailPresenter.validateTotal();
                }
            }
            if (result.dismiss === Swal.DismissReason.cancel) {
                this.isPromoDay = false;
                GeneralConfigurations.applyPromoDay = false;
                this.paymentsDetailPresenter.setPayments();
            }
        });
    }
    showSelectedBanksSpecialDay() {
        Swal.fire({
            title: 'Banco seleccionado con descuento',
            text: '¿Desea confirmar la promoción ?',
            showCancelButton: true,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar',
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                if (this.payments.length >= 1) {
                    this.showConfirmDeletePayments();
                } else {
                    this.paymentsDetailPresenter.setNewInvoice(true);
                    this.paymentsDetailPresenter.validateTotal();
                }
            }
            if (result.dismiss === Swal.DismissReason.cancel) {
                this.isPromoDay = false;
                GeneralConfigurations.applyPromoDay = false;
                this.paymentsDetailPresenter.setPayments();
            }
        });
    }
    showConfirmDeletePayments() {
        Swal.fire({
            title: 'Existen pagos agregados',
            text: '¿Desea eliminarlos, para continuar ?',
            showCancelButton: true,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar',
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                this.paymentsDetailPresenter.setNewInvoice(false);
            }
            if (result.dismiss === Swal.DismissReason.cancel) {
                this.isPromoDay = false;
            }
        });
    }
    showAdminPassword(payment: Payment) {
        const name = 'Credenciales de Administrador';
        this.ref = this.dialogService.open(AdminPricePasswordComponent, {
            showHeader: true,
            closeOnEscape: true,
            height: '400px',
            width: '400px',
            data: { permissions: DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS, name: name }
        });
        this.ref.onClose.subscribe((aproveAdmin: boolean) => {
            if (aproveAdmin) {
                this.paymentsDetailPresenter.deleteCardPayment(payment);
            } else {
                this.showWarn('', 'El pago no se eliminó');
            }
        });
    }
    showCoupon() {
        Swal.fire({
            title: 'Cupon disponible',
            text: 'Productos generarán cupón de descuento',
            showCancelButton: false,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar',
        });
    }

    onAmountChange() {
        if (this.isPromoDay && this.creditCardSelected) {

            if (this.creditCardAmount && this.creditCardAmount >= GeneralConfigurations.defaultAmountCreditCard) {
                this.creditCardSelected.paymentFeesBank = null;
                this.creditCardSelected.paymentFeesBank = [
                    { number: 3, name: '3' },
                    { number: 6, name: '6' },
                    { number: 9, name: '9' },
                    { number: 12, name: '12' },
                    { number: 15, name: '15' },
                    { number: 18, name: '18' },
                    { number: 21, name: '21' },
                    { number: 24, name: '24' }
                ];
            } else {
                this.creditCardSelected.paymentFeesBank = null;
                this.creditCardSelected.paymentFeesBank = [
                    { number: 3, name: '3' },
                    { number: 6, name: '6' },
                    { number: 9, name: '9' },
                    { number: 12, name: '12' },
                ];
            }
            this.creditCardSelected.graceMonthsBank = null;
            this.creditCardSelected.graceMonthsBank = [{ number: 2, name: '2' }];
            this.selectedGrace = { number: 2, name: '2' };
        }
    }


    validatecashAmount() {
        this.cashAmount = this.cashAmount ? new Decimal(this.cashAmount)
            .toDecimalPlaces(2).toNumber() : this.cashAmount;
    }
    validatecheckAmount() {
        this.checkAmount = this.checkAmount ? new Decimal(this.checkAmount)
            .toDecimalPlaces(2).toNumber() : this.checkAmount;
    }
    validatetransferAmount() {
        this.transferAmount = this.transferAmount ? new Decimal(this.transferAmount)
            .toDecimalPlaces(2).toNumber() : this.transferAmount;
    }
    validatecreditMemoAmount() {
        this.creditMemoAmount = this.creditMemoAmount ? new Decimal(this.creditMemoAmount)
            .toDecimalPlaces(2).toNumber() : this.creditMemoAmount;
    }
    validatewithholdAmount() {
        this.withholdAmount = this.withholdAmount ? new Decimal(this.withholdAmount)
            .toDecimalPlaces(2).toNumber() : this.withholdAmount;
    }
    validatecreditCardAmount() {
        this.creditCardAmount = this.creditCardAmount ? new Decimal(this.creditCardAmount)
            .toDecimalPlaces(2).toNumber() : this.creditCardAmount;
    }
    validatedebitCardAmount() {
        this.debitCardAmount = this.debitCardAmount ? new Decimal(this.debitCardAmount)
            .toDecimalPlaces(2).toNumber() : this.debitCardAmount;
    }
    validatesubventionAmount() {
        this.subventionAmount = this.subventionAmount ? new Decimal(this.subventionAmount)
            .toDecimalPlaces(2).toNumber() : this.subventionAmount;
    }
    showConfirmCreditCardPayment() {
        let creditCard = '';
        if (this.creditCardTypeSelected === CreditCardType.CREDIT_WITHOUT_INTEREST) {
            creditCard = 'Crédito sin intereses';
        } else if (this.creditCardTypeSelected === CreditCardType.CREDIT_WITH_INTEREST) {
            creditCard = 'Crédito con intereses';
        } else if (this.creditCardTypeSelected === CreditCardType.CURRENT_PAYMENT) {
            creditCard = 'Contado';
        }

        const message =
            '<p>Pago de: $' + this.creditCardAmount +
            '<p>Tarjeta: ' + this.creditCardSelected.name +
            '<p>Banco: ' + this.creditBankSelected.name +
            '<p>Tipo de crédito: ' + creditCard + ' Cuotas: ' + this.paymentFeeSelected.name;

        Swal.fire({
            title: 'Confirmar Pago',
            html: message,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            type: 'success',
        }).then((result) => {
            if (result.value) {
                this.paymentsDetailPresenter.addCreditCArdPayment();
            }
            if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }
    showConfirmDebitCardPayment() {

        const message =
            '<p>Pago de: $' + this.debitCardAmount +
            '<p>Tarjeta: ' + this.debitCardSelected.name +
            '<p>Banco: ' + this.debitBankSelected.name;

        Swal.fire({
            title: 'Confirmar Pago',
            html: message,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            type: 'success',
        }).then((result) => {
            if (result.value) {
                this.paymentsDetailPresenter.addDebitCardPayment();
            }
            if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

}
