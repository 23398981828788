export enum PaymentMethod {
    CASH = 'CASH',
    CHECK = 'CHECK',
    DEBIT = 'DEBIT',
    CREDIT = 'CREDIT',
    CREDIT_CARD = 'CREDIT_CARD',
    CREDIT_MEMO = 'CREDIT_MEMO',
    BANK_TRANSFER = 'BANK_TRANSFER',
    WITHHOLD = 'WITHHOLD',
    TOTAL_WITHOUT_DISPATCH = 'TOTAL_WITHOUT_DISPATCH',
    SUBVENTION = 'SUBVENTION',
    PAYMENT_COLLECTIONS = 'PAYMENT_COLLECTIONS',
    OTHER = 'OTHER',
    CREDIRED = 'CREDIRED',
    DE_UNA = 'DE_UNA'
}
