import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PanelMasterComponent } from './panelmaster.component';
import { BreadscrumbComponent } from './shared/breadscrumb/breadscrumb.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SubMenuComponent, MenuComponent } from './shared/menu/menu.component';
import { TopbarComponent } from './shared/topbar/topbar.component';
import {
  ScrollPanelModule,
  DropdownModule,
  AutoCompleteModule,
  ConfirmationService,
  KeyFilterModule,
  CalendarModule,
  PaginatorModule,
  SplitButtonModule,
  InputMaskModule,
  DialogService,
  ButtonModule,
  InputTextareaModule,
  ChartModule,
  InputSwitchModule,
  TabViewModule,
  OverlayPanelModule,
  InputTextModule,
  RadioButtonModule,
  FileUploadModule,
  ConfirmDialogModule,
  ListboxModule,
  DialogModule,
} from 'primeng/primeng';
import { ProductsModule } from './pages/product/products.module';

import { AppRoutes } from '../../app.routes';
import { MaterialExampleModule } from '../shared/shared/material.module';
import { CardModule } from 'primeng/card';
import { PosModule } from './pages/pos/pos.module';

import { ToastModule } from 'primeng/toast';
import { ManageClientComponent } from './pages/client/manage-client/manage-client.component';
import { SearchClientComponent } from './pages/client/search-client/search-client.component';
import { ClientsModule } from './pages/client/clients.module';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { TableModule } from 'primeng/table';
import { SlideshowModule } from 'ng-simple-slideshow';
import { SearchOrderComponent } from './pages/pos/search-order/search-order.component';
import { PaymentsDetailComponent } from './pages/pos/payments-detail/payments-detail.component';
import { SettingComponent } from './shared/setting/setting.component';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { SearchInvoiceComponent } from './pages/invoice/search-invoice/search-invoice.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenerateCreditNoteComponent } from './pages/credit-memo/generate-credit-note/generate-credit-note.component';
import { CreditMemoComponent } from './pages/credit-memo/credit-memo/credit-memo.component';
import { GenerateDispatchComponent } from './pages/dispatch/generate-dispatch/generate-dispatch.component';
import { OpenCashRegisterComponent } from './pages/cash-register/open-cash-register/open-cash-register.component';
import { CloseCashRegisterComponent } from './pages/cash-register/close-cash-register/close-cash-register.component';
import { HotkeyModule } from 'angular2-hotkeys';
import { HelpComponent } from './pages/help/help.component';
import { GenerateAdvanceComponent } from './pages/cash-register/generate-advance/generate-advance.component';
import { CashRegisterComponent } from './pages/dashboard/cash-register/cash-register/cash-register.component';
import { CloseDayReportComponent } from './pages/dashboard/close-day-report/close-day-report.component';
import { SetObservationComponent } from './pages/dashboard/set-observation/set-observation.component';
import { RolComponent } from './pages/rol/rol.component';
import { SearchCreditMemoComponent } from './pages/credit-memo/search-credit-memo/search-credit-memo.component';
import { OpenDayAdvanceComponent } from './pages/dashboard/open-day-advance/open-day-advance.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AboutComponent } from './pages/about/about.component';
import { AproveComponent } from './pages/aprove/aprove.component';
import { CreditMemoVoidComponent } from './pages/credit-memo/credit-memo-void/credit-memo-void.component';
import { AproveCreditMemoComponent } from './pages/credit-memo/aprove-credit-memo/aprove-credit-memo.component';
import { AproveCashReturnComponent } from './pages/credit-memo/aprove-cash-return/aprove-cash-return.component';
import { FieldsetModule } from 'primeng/fieldset';
import { CheckboxModule } from 'primeng/checkbox';
import { EnterPasswordComponent } from './pages/pos/enter-password/enter-password.component';
import { SearchQuotationComponent } from './pages/pos/search-quotation/search-quotation.component';
import { CreditCardReportComponent } from './pages/dashboard/credit-card-report/credit-card-report.component';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SettingService } from 'src/app/services/setting.service';
import { InitDataComponent } from './pages/init-data/init-data.component';
import { AdminPricePasswordComponent } from './shared/admin-price-password/admin-price-password.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TransferRequestComponent } from './pages/dashboard/tranfers/components/transfer-request/transfer-request.component';
import { TransferDocumentComponent } from './pages/dashboard/tranfers/components/transfer-document/transfer-document.component';
import { CreateTranfersComponent } from './pages/dashboard/tranfers/components/create-transfers/create-tranfers.component';
import { TransfersComponent } from './pages/dashboard/tranfers/components/transfers/transfers.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { MatCardModule } from '@angular/material/card';
import { SearchTransfersComponent } from './pages/dashboard/tranfers/components/search-transfers/search-transfers.component';
import { AproveTransferComponent } from './pages/dashboard/tranfers/components/aprove-transfer/aprove-transfer.component';
import { TransfersReportComponent } from './pages/dashboard/tranfers/components/transfers-report/transfers-report.component';
import { SearchSupplyingComponent } from './pages/dashboard/tranfers/components/search-supplying/search-supplying.component';
import { AproveSupplyingComponent } from './pages/dashboard/tranfers/components/aprove-supplying/aprove-supplying.component';
import { SharedModule } from '../shared/shared/shared.module';

import { StockReportComponent } from './pages/dashboard/stock/stock-report/stock-report.component';
import { StockComponent } from './pages/dashboard/stock/stock/stock.component';
import { KardexReportComponent } from './pages/dashboard/stock/kardex-report/kardex-report.component';
import {
    ReceiptsPaymentsMenuComponent,
} from './pages/dashboard/receipts-payments/receipts-payments-menu/receipts-payments-menu.component';
import {
    DisableControlDirective,
} from './pages/dashboard/receipts-payments/receipts-payments-process/DisableControlDirective ';
import {
    ReceiptsPaymentsComponent,
} from './pages/dashboard/receipts-payments/receipts-payments-process/receipts-payments.component';

import { ChartsComponent } from './pages/dashboard/sales-report/charts/charts.component';
import { SalesReportsComponent } from './pages/dashboard/sales-report/sales-reports/sales-reports.component';
import { ItemComponent } from './pages/dashboard/item/item.component';
import { StockReportAcopioComponent } from './pages/dashboard/stock/stock-report-acopio/stock-report-acopio.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatStepperModule,
  MatToolbarModule,
} from '@angular/material';
import { AproveDevolutionComponent } from './pages/dashboard/tranfers/components/aprove-devolution/aprove-devolution.component';
import { InvoicesComponent } from './pages/invoice/invoices/invoices.component';
import { InvoicesPendingComponent } from './pages/invoice/invoices-pending/invoices-pending.component';
import { ProductSupplierReportComponent } from './pages/dashboard/sales-report/product-supplier-report/product-supplier-report.component';
// tslint:disable-next-line: max-line-length
import { ProductCategorySalesReportComponent } from './pages/dashboard/sales-report/product-category-sales-report/product-category-sales-report.component';
import {
    ReceiptsPaymentsHistoricComponent
} from './pages/dashboard/receipts-payments/receipts-payments-historic/receipts-payments-historic.component';
import {
    ReceiptsPaymentsReportComponent
} from './pages/dashboard/receipts-payments/receipts-payments-report/receipts-payments-report.component';
import {
    ReceiptsPaymentsDailyComponent
} from './pages/dashboard/receipts-payments/receipts-payments-daily/receipts-payments-daily.component';
import { GeneralComponent } from './pages/general/general/general.component';
import { PrintPriceComponent } from './pages/general/print-price/print-price.component';
import { VaultManagmentComponent } from './pages/general/vault/vault-managment.component';
import { OpenDayReportComponent } from './pages/dashboard/cash-register/open-day-report/open-day-report.component';
import { ValuedStockReportComponent } from './pages/dashboard/stock/valued-stock-report/valued-stock-report.component';
import { ReportPricesSalesComponent } from './pages/dashboard/sales-report/report-prices-sales/report-prices-sales.component';
import { ProductHistoryComponent } from './pages/dashboard/sales-report/product-history/product-history.component';
import { GenerateCashRegisterComponent } from './pages/cash-register/generate-cash-register/generate-cash-register.component';
import { SearchSalesDepositComponent } from './pages/dashboard/cash-register/search-sales-deposit/search-sales-deposit.component';
import { AssingPricesComponent } from './pages/dashboard/users-admin/assing-prices/assing-prices.component';
import { UsersAdminComponent } from './pages/dashboard/users-admin/users-admin/users-admin.component';
import { MultiSelectModule } from 'primeng/primeng';
import { ReportCommissionComponent } from './pages/dashboard/sales-report/report-commission/report-commission.component';
@NgModule({
  declarations: [
    PanelMasterComponent,
    BreadscrumbComponent,
    FooterComponent,
    MenuComponent,
    TopbarComponent,
    SubMenuComponent,
    SettingComponent,
    SearchInvoiceComponent,
    GenerateCreditNoteComponent,
    SearchCreditMemoComponent,
    AproveCreditMemoComponent,
    CreditMemoVoidComponent,
    AproveCashReturnComponent,
    GenerateDispatchComponent,
    OpenCashRegisterComponent,
    CloseCashRegisterComponent,
    HelpComponent,
    GenerateAdvanceComponent,
    SetObservationComponent,
    CloseDayReportComponent,
    RolComponent,
    OpenDayReportComponent,
    OpenDayAdvanceComponent,
    ChartsComponent,
    SettingsComponent,
    AboutComponent,
    AproveComponent,
    PrintPriceComponent,
    VaultManagmentComponent,
    StockReportComponent,
    CreditCardReportComponent,
    InitDataComponent,
    AdminPricePasswordComponent,
    TransferRequestComponent,
    TransferDocumentComponent,
    CreateTranfersComponent,
    TransfersComponent,
    SearchTransfersComponent,
    AproveTransferComponent,
    TransfersReportComponent,
    SearchSupplyingComponent,
    AproveSupplyingComponent,
    AdminPricePasswordComponent,
    StockComponent,
    KardexReportComponent,
    SalesReportsComponent,
    ProductCategorySalesReportComponent,
    ReportPricesSalesComponent,
    ProductHistoryComponent,
    ReportCommissionComponent,
    ProductSupplierReportComponent,
    ItemComponent,
    StockReportAcopioComponent,
    ChangePasswordComponent,
    InvoicesComponent,
    InvoicesPendingComponent,
    AproveDevolutionComponent,
    ReceiptsPaymentsComponent,
    ReceiptsPaymentsReportComponent,
    ReceiptsPaymentsHistoricComponent,
    ReceiptsPaymentsMenuComponent,
    CreditMemoComponent,
    CashRegisterComponent,
    GeneralComponent,
    DisableControlDirective,
    ReceiptsPaymentsDailyComponent,
    ValuedStockReportComponent,
    GenerateCashRegisterComponent,
    SearchSalesDepositComponent,
    UsersAdminComponent,
    AssingPricesComponent
  ],

  imports: [
    TableModule,
    DropdownModule,
    AutoCompleteModule,
    SlideshowModule,
    CommonModule,
    ListboxModule,
    ScrollPanelModule,
    ProductsModule,
    DynamicDialogModule,
    ClientsModule,
    ToastModule,
    CardModule,
    PosModule,
    SidebarModule,
    TooltipModule,
    CalendarModule,
    FormsModule,
    PaginatorModule,
    KeyFilterModule,
    SplitButtonModule,
    InputMaskModule,
    ButtonModule,
    HotkeyModule.forRoot(),
    InputTextareaModule,
    ChartModule,
    InputSwitchModule,
    TabViewModule,
    OverlayPanelModule,
    InputTextModule,
    FieldsetModule,
    CheckboxModule,
    RadioButtonModule,
    VirtualScrollerModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    ProgressSpinnerModule,
    MatCardModule,
    SharedModule,
    FileUploadModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatStepperModule,
    MaterialExampleModule,
    CommonModule,
    FormsModule,
    TableModule,
    PaginatorModule,
    AutoCompleteModule,
    ButtonModule,
    ScrollPanelModule,
    ToastModule,
    KeyFilterModule,
    OverlayPanelModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    AppRoutes,
    CalendarModule,
    TooltipModule,
    MatExpansionModule,
    MatPaginatorModule,
    SharedModule,
    ConfirmDialogModule,
    DialogModule,
    MultiSelectModule
  ],
  providers: [ConfirmationService, DialogService, SettingService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    SearchQuotationComponent,
    SearchClientComponent,
    EnterPasswordComponent,
    SearchOrderComponent,
    PaymentsDetailComponent,
    SearchInvoiceComponent,
    GenerateCreditNoteComponent,
    ManageClientComponent,
    GenerateDispatchComponent,
    OpenCashRegisterComponent,
    CloseCashRegisterComponent,
    HelpComponent,
    GenerateAdvanceComponent,
    SetObservationComponent,
    CloseDayReportComponent,
    RolComponent,
    OpenDayReportComponent,
    OpenDayAdvanceComponent,
    SettingsComponent,
    AboutComponent,
    AproveComponent,
    InitDataComponent,
    AdminPricePasswordComponent,
    AproveTransferComponent,
    AproveSupplyingComponent,
    ChangePasswordComponent,
    AproveDevolutionComponent,
    GenerateCashRegisterComponent,
  ],
})
export class PanelMasterModule {}
