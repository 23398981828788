import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Decimal from 'decimal.js';
import { timeout } from 'rxjs/operators';
import { Presenter } from 'src/app/core/presenter';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { ClientModel } from 'src/app/models/client.model';
import { MeasureConversionComboModel } from 'src/app/models/measure-combo.model';
import { OrderLine } from 'src/app/models/orderline.model';
import { ProcessModel } from 'src/app/models/process.model';
import {
  QuotationDetailModel,
  QuotationModel,
  TypeStatusQuotation,
} from 'src/app/models/quotation.model';
import { ClientService } from 'src/app/services/client.service';
import { GetSalesPreviusMonthService } from 'src/app/services/get-sales-previus-month.service';

import { round } from '../../../../../../core/utils';
import { InvoiceModel } from '../../../../../../models/invoice.model';
import {
  MeasureConversionModel,
  MeasureUnitPresenter,
} from '../../../../../../models/measure-conversion.model';
import { Product, WareHouse } from '../../../../../../models/product.model';
import {
  AddClient,
  ClearClient,
} from '../../../../../../reducers/client.action';
import { ClearInvoice } from '../../../../../../reducers/invoice.action';
import {
  AddIncrement,
  AddOrderLine,
  AllowInvoiceProcess,
  ChangeProcess,
  ClearEditOrder,
  DeleteAllOrderLines,
  SetClientAgreementDiscount,
  SetCreditCardPayment,
  SetObservationInRedux,
} from '../../../../../../reducers/order.action';
import { DataProvider } from '../../../../../../services/data.provider';
import {
  DefaultconfigurationsService,
  GeneralConfigurations,
} from '../../../../../../services/defaultconfigurations.service';
import { ProductService } from '../../../../../../services/product.service';
import { QuotationService } from '../../../../../../services/quotation.service';
import { SearchQuotationView } from '../search-quotation.view';

@Injectable({
  providedIn: 'root',
})
export class SearchQuotationPresenter implements Presenter {
  view: SearchQuotationView;
  constructor(
    private clientService: ClientService,
    private quotationService: QuotationService,
    private store: Store<{
      client: ClientModel;
      orderLines: OrderLine;
      invoice: InvoiceModel;
    }>,
    private defaultconfigurationsService?: DefaultconfigurationsService,
    private productService?: ProductService,
    private dataProvider?: DataProvider,
    private getSalesPreviusMonth?: GetSalesPreviusMonthService,
    private settingPresenter?: SettingPresenter
  ) {}

  onSearchClient() {
    const searchValue = this.view.searchClient;
    const page = this.view.pageClient;
    const size = this.view.sizeClient;
    this.clientService
      .searchClient(searchValue, page, size)
      .then((data) => {
        const clients: ClientModel[] = data['data'];
        this.view.clients = clients;
      })
      .catch((error: HttpErrorResponse) => {
        this.view.showError('Atención', error.error.message);
      });
  }

  clearClient() {
    this.view.showSelectedClient = false;
    this.view.selectedClient = null;
    this.findQuotations();
  }

  findQuotationsByClient() {
    if (
      this.view.selectedClient &&
      typeof this.view.selectedClient !== 'string'
    ) {
      this.view.showSelectedClient = true;
      this.findQuotations();
    }
  }

  findQuotations() {
    let initDate = '';
    let endDate = '';
    let clientId = '';
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    if (this.view.initDate) {
      initDate = formatDate(this.view.initDate, format, locale);
    }
    if (this.view.endDate) {
      endDate = formatDate(this.view.endDate, format, locale);
    }
    if (this.view.selectedClient) {
      clientId = this.view.selectedClient.clientId;
    }
    this.view.blockUi();
    this.quotationService
      .findQuotations(
        clientId,
        initDate,
        endDate,
        this.view.documentNo,
        this.view.page
      )
      .subscribe(
        (res) => {
          this.view.quotations = res.data;
        },
        (error: HttpErrorResponse) => {
          this.view.showError('', error.error.message);
        }
      );
  }
  findQuotationAndCreateInvoice() {
    this.view.blockUi();
    this.quotationService
      .findQuotation(this.view.selectedQuotation.quotationId)
      .subscribe(
        (res) => {
          this.clearPos();
          this.view.closeView();
          this.view.selectedQuotation = res;
          this.view.selectedQuotation.emissionPointPresenter = res.emissionPointPresenter;
          this.view.selectedQuotation.quotationDetailPresenters.sort(
            (a, b) => b.lineNumber - a.lineNumber
          );
          this.addLinesQuotation();
        },
        (error: HttpErrorResponse) => {
          this.view.showError('', error.error.message);
        }
      );
  }
  setSelectedQuotationToInvoice() {
    if (!this.view.linesOld) {
      return;
    }
    this.store.dispatch(
      new SetObservationInRedux(this.view.selectedQuotation.observation)
    );
    this.store.dispatch(
      new AddClient(this.view.selectedQuotation.clientPresenter)
    );
    if (this.view.selectedQuotation.isCreditCardPayment) {
      this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
      GeneralConfigurations.creditCardPayment = true;
      this.dataProvider.processPayment = 'deferred';
    } else {
      this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
      GeneralConfigurations.creditCardPayment = false;
      this.dataProvider.processPayment = 'current';
    }
    this.view.linesOld.forEach((line) => {
      this.store.dispatch(new AddOrderLine(line, this.dataProvider.commissionCard, this.dataProvider.processPayment));
    });
    this.store.dispatch(
      new AddIncrement(
        this.defaultconfigurationsService.defaultCreditDebitPercentageAmount
      )
    );
    this.view.closeView();

    if (this.view.procesesSelected === ProcessModel.QUOTATION) {
        this.view.showInfo('', 'Cotización lista para editarse');
        this.store.dispatch(new ChangeProcess(ProcessModel.QUOTATION));
    } else {
        this.view.showInfo('', 'Proforma lista para facturarse');
        this.store.dispatch(new ChangeProcess(ProcessModel.INVOICE));
    }
  }

  addLinesQuotation() {
    this.view.linesNew = [];
    this.view.linesOld = [];
    for (const line of this.view.selectedQuotation.quotationDetailPresenters) {
      const product = line.product;
      line.price = line.product.priceWithoutTax;
      line.enteredQuantity = line.product.availableQuantity;
      line.product.enteredQuantity = line.product.availableQuantity;

      product.observationHq = line.observationHq ? line.observationHq : '';
      product.suggestedPrice = line.suggestedPrice;
      product.suggestedPriceBusinessManager = line.suggestedPriceBusinessManager;
      product.suggestedQuantityBusinessManager = line.suggestedQuantityBusinessManager;
      product.observation = line.observation;
      product.statusColor = line.product.statusColor;
      product.commissionPercentage = line.commissionPercentage;
      product.checkedApproveSuggestion = line.product.statusColor === 'BLUE' ? true : false;

      product.priceWithoutTax = new Decimal(line.price)
        .toDecimalPlaces(6)
        .toNumber();
      product.priceLimitWithoutTax = new Decimal(product.priceLimitWithoutTax)
        .toDecimalPlaces(6)
        .toNumber();
      product.specialPrice = product.specialPrice
        ? new Decimal(product.specialPrice).toDecimalPlaces(6).toNumber()
        : product.priceLimitWithoutTax;
      product.wholesalerPrice = product.wholesalerPrice
        ? new Decimal(product.wholesalerPrice).toDecimalPlaces(6).toNumber()
        : product.priceLimitWithoutTax;
      product.administratorPrice = product.administratorPrice
        ? new Decimal(product.administratorPrice).toDecimalPlaces(6).toNumber()
        : product.priceLimitWithoutTax;
      product.measureUnitConversionPresenters.forEach((unitConvertion) => {
        unitConvertion.price = new Decimal(unitConvertion.price)
          .toDecimalPlaces(6)
          .toNumber();
        unitConvertion.priceLimit = unitConvertion.priceLimit ? new Decimal(unitConvertion.priceLimit)
          .toDecimalPlaces(6)
          .toNumber()
          : unitConvertion.basePrice;
        unitConvertion.specialPrice = unitConvertion.specialPrice
          ? new Decimal(unitConvertion.specialPrice)
            .toDecimalPlaces(6)
            .toNumber()
          : unitConvertion.basePrice;
        unitConvertion.wholesalerPrice = unitConvertion.wholesalerPrice
          ? new Decimal(unitConvertion.wholesalerPrice)
            .toDecimalPlaces(6)
            .toNumber()
          : unitConvertion.basePrice;
        unitConvertion.administratorPrice = unitConvertion.administratorPrice
          ? new Decimal(unitConvertion.administratorPrice)
            .toDecimalPlaces(6)
            .toNumber()
          : unitConvertion.priceLimit;
        unitConvertion.basePrice = new Decimal(unitConvertion.basePrice)
          .toDecimalPlaces(6)
          .toNumber();
      });
      const warehousePresenter: WareHouse = line.product.warehousePresenters[0];
      line.product.availableQuantity = warehousePresenter.availableQuantity;
      const selectedMeasure = this.getSelectedMeasure(
        line.product,
        line.measureUnitTo
      );
      selectedMeasure.price = this.dataProvider.quotationStatus === TypeStatusQuotation.TO_BE_INVOICED ? selectedMeasure.price : line.price  ;
      line.product.priceWithoutTax = line.price || selectedMeasure.price;
      line.product.normalPriceWithoutTax = selectedMeasure.normalPrice;
      line.product.priceLimitWithoutTax = selectedMeasure.basePrice;
      line.product.specialPrice = selectedMeasure.specialPrice;
      line.product.wholesalerPrice = selectedMeasure.wholesalerPrice;
      line.product.basePrice = selectedMeasure.basePrice;
      const maxQuiantity =
        line.product.availableQuantity * selectedMeasure.multiplyRate;
      /*  if (maxQuiantity === 0) {
         this.view.linesNew.push(
           this.createLine(
             line,
             selectedMeasure,
             line.enteredQuantity,
             maxQuiantity
           )
         );
       }
       if (maxQuiantity && maxQuiantity < line.enteredQuantity) {
         const quantityNew = new Decimal(line.enteredQuantity)
           .minus(maxQuiantity)
           .toDecimalPlaces(2)
           .toNumber();
         const quantityOld = new Decimal(maxQuiantity)
           .toDecimalPlaces(2)
           .toNumber();

         this.view.linesNew.push(
           this.createLine(line, selectedMeasure, quantityNew, maxQuiantity)
         );
         this.view.linesOld.push(
           this.createLine(line, selectedMeasure, quantityOld, maxQuiantity)
         );
       } */
      //   if (maxQuiantity >= line.enteredQuantity) {
      this.view.linesOld.push(
        this.createLine(
          line,
          selectedMeasure,
          line.enteredQuantity,
          maxQuiantity
        )
      );
      //   }
    }
    /* if (
      !this.view.linesOld.length &&
      this.view.linesNew.length ===
        this.view.selectedQuotation.quotationDetailPresenters.length
    ) {
      this.view.showWarn('Advertencia', 'Sin disponibilidad de stock');
    } else {
       if (this.view.linesNew.length >= 1) {
           this.view.showNewQuotationConfirmation(); // ya no se muestran los productos disponibles
    } */
    this.setSelectedQuotationToInvoice();
  }
  createLine(
    line: QuotationDetailModel,
    selectedMeasure: MeasureConversionModel,
    quantity: number,
    maxQuiantity: number
  ): OrderLine {
    let maxDiscount =
      100 - (selectedMeasure.priceLimit * 100) / selectedMeasure.price;
    maxDiscount = maxDiscount >= 0 ? maxDiscount : 0;
    const convertedQuantity = Number(quantity * selectedMeasure.divideRate);
    const tax =
      round(line.price * convertedQuantity, 2) *
      line.product.taxPresenter.value;
    const subtotal = new Decimal(line.product.priceWithoutTax)
      .mul(quantity)
      .toDecimalPlaces(2)
      .toNumber();
    const li: OrderLine = {
      discount: line.discountRate,
      maxDiscount: maxDiscount,
      measureCombo: this.getCombo(line.product),
      selectedMeasure: selectedMeasure,
      orderDetailId: line.quotationDetailId,
      description: line.product.description,
      name: line.product.name,
      price: line.price,
      priceWithDiscount: line.price * (1 + line.product.taxPresenter.value),
      priceList: null,
      priceWithoutTax: line.price,
      product: line.product,
      productId: line.product.productId,
      enteredQuantity: quantity,
      convertedQuantity: convertedQuantity,
      referenceCode: line.product.referenceCode,
      // subTotal: null,
      // total: total,
      taxToShow: line.convertedQuantity * line.product.taxPresenter.value,
      tax: line.product.taxPresenter,
      active: true,
      maxQuantity: maxQuiantity,
      warehousePresenter: line.product.warehousePresenters[0],
      inventoryMovementDetailId: null,
      // discountPromotion: 0,
      discountRecycling: line.product.discountRecycling
        ? line.product.discountRecycling
        : 0,
      isRecycling: line.product.isRecycling,

      discountPromotion: line.discountPromotion,
      administratorDiscount: line.administratorDiscount,
      specialDiscount: line.specialDiscount,
      wholesalerDiscount: line.wholesalerDiscount,
      subtotal: subtotal,
      total: new Decimal(subtotal).add(tax).toDecimalPlaces(2).toNumber(),
      chainPrice: line.chainPrice,
      quantityRecycling: 0,
      suggestedPrice: line.product.suggestedPrice,
      suggestedPriceBusinessManager: line.product.suggestedPriceBusinessManager,
      ifPriceElderly: true,
      limitDiscount: line.limitDiscount,
    };
    li.warehousePresenter.local =
      li.warehousePresenter.referenceId ===
      this.dataProvider.defaultWarehouse.referenceId;
    return li;
  }

  newQuotation() {
    if (!this.view.linesNew) {
      return;
    }
    this.view.blockUi();
    const quotationDetails: QuotationDetailModel[] = [];
    let actualLine = 1;
    this.view.linesNew.forEach((line) => {
      if (!line.orderDetailId) {
        const detail: QuotationDetailModel = {
          product: line.product,
          enteredQuantity: line.enteredQuantity,
          convertedQuantity: line.convertedQuantity,
          taxPresenter: line.product.taxPresenter,
          unitPrice: line.priceWithoutTax,
          lineNumber: actualLine,
          discount: new Decimal(line.selectedMeasure.price)
            .minus(line.priceWithoutTax)
            .toNumber(),
          discountRate: line.discount,
          divideRate: line.selectedMeasure.divideRate,
          multiplyRate: line.selectedMeasure.multiplyRate,
          measureUnitFrom: line.selectedMeasure.measureUnitPresenterFrom,
          measureUnitTo: line.selectedMeasure.measureUnitPresenterTo,
          price: line.product.priceWithoutTax,

          discountPromotion: line.discountPromotion,
          administratorDiscount: line.administratorDiscount,
          specialDiscount: line.specialDiscount,
          wholesalerDiscount: line.wholesalerDiscount,
          subtotal: line.subtotal,
          total: line.total,
          chainPrice: line.selectedMeasure.price,
          suggestedPrice: line.suggestedPrice,
          stockPDV: line.stockPDV,
          specialPrice: line.product.specialPrice,
          wholesalerPrice: line.product.wholesalerPrice,
          administratorPrice: line.product.administratorPrice,
          limitPrice: line.product.priceLimitWithoutTax,
          observation: line.observation
        };
        quotationDetails.push(detail);
        actualLine++;
      }
    });
    const subtotalTaxed = this.getSubtotalTaxed(this.view.linesNew);
    const subtotalZeroTax = this.getSubtotalZeroTax(this.view.linesNew);
    const tax = this.getTax(this.view.linesNew);
    const subtotal = new Decimal(subtotalTaxed)
      .add(subtotalZeroTax)
      .toDecimalPlaces(2)
      .toNumber();
    const total = new Decimal(subtotal).add(tax).toDecimalPlaces(2).toNumber();
    const newQuotation: QuotationModel = {
      quotationId: this.view.selectedQuotation.quotationId,
      clientPresenter: this.view.selectedQuotation.clientPresenter,
      subtotal: subtotal,
      total: total,
      tax: tax,
      priceListPresenter: this.settingPresenter.view.selectedPrice,
      pdf: null,
      emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
      observation: this.view.selectedQuotation.observation,
      defaultTax: null,
      discount: null,
      quotationDetailPresenters: quotationDetails,
      subtotalTaxed: subtotalTaxed,
      subtotalZeroTax: subtotalZeroTax,
      isCreditCardPayment: this.view.selectedQuotation.isCreditCardPayment,
      hasQuotation: true,
      created: this.view.selectedQuotation.created,
    };

    this.quotationService.saveNewQuotation(newQuotation).subscribe(
      (res) => {
        this.view.showSuccess('', 'Cotización guardada con éxito');
        this.clearPos();
        this.view.showPdf(res);
        this.setSelectedQuotationToInvoice();
      },
      (error: HttpErrorResponse) => {
        this.view.showError('Atención', error.error.message);
      }
    );
  }
  getPdf() {
    this.view.blockUi();
    this.quotationService
      .getPdf(this.view.selectedQuotation.quotationId)
      .pipe(timeout(300000))
      .subscribe(
        (data) => {
          this.view.showPdf(data);
        },
        () => {
          this.view.allowUi();
          this.view.showError('', 'Error imprimiendo cotización');
        }
      );
  }

  getTotalAmountPreviousMontht() {
    this.getSalesPreviusMonth
      .getMaxDiscountAdminPrice()
      .toPromise()
      .then((res: number) => {
        this.dataProvider.amountInitialBox = new Decimal(res)
          .toDecimalPlaces(2)
          .toNumber();
        this.dataProvider.amountInitialBox =
          this.dataProvider.amountInitialBox < 0
            ? 0
            : this.dataProvider.amountInitialBox;
      });
  }

  private getCombo(product: Product): MeasureConversionComboModel[] {
    const comboMeasure: MeasureConversionComboModel[] = [];
    product.measureUnitConversionPresenters.forEach((unit) => {
      comboMeasure.push({
        label: unit.measureUnitPresenterTo.name,
        value: unit,
      });
    });
    return comboMeasure;
  }

  getSelectedMeasure(product: Product, measureTo: MeasureUnitPresenter): MeasureConversionModel {
    for (const measure of product.measureUnitConversionPresenters) {
      if (measure.measureUnitPresenterTo.measureUnitId === measureTo.measureUnitId) {
        return measure;
      }
    }
    return null;
  }

  clearPos() {
    this.store.dispatch(new ClearEditOrder());
    this.store.dispatch(new ClearClient());
    this.store.dispatch(new DeleteAllOrderLines());
    this.store.dispatch(new ClearInvoice());
    this.store.dispatch(new SetObservationInRedux(''));
    this.store.dispatch(new AllowInvoiceProcess());
  }

  getClientAgreementDiscount(clientId: string, productIds: string[]) {
    this.productService
      .getProductDiscounts(clientId, productIds)
      .subscribe((res) => {
        this.store.dispatch(new SetClientAgreementDiscount(res, false));
      });
  }

  getTotal(orderLines: OrderLine[]): number {
    let totalTax = 0;
    const subTotal = orderLines
      .filter((line) => line.active && line.product.taxPresenter.value)
      .map((line) => new Decimal(line.subTotal).toDecimalPlaces(2).toNumber())
      .reduce((a, b) => new Decimal(a).add(b).toDecimalPlaces(2).toNumber(), 0);
    totalTax = new Decimal(subTotal)
      .mul(GeneralConfigurations.defaultTax || 0.12)
      .toDecimalPlaces(2)
      .toNumber();
    return new Decimal(totalTax).toDecimalPlaces(2).toNumber();
  }
  getSubtotalTaxed(orderLines: OrderLine[]): number {
    let subtotal = 0;
    orderLines.forEach((line) => {
      if (line.active) {
        if (line.tax.value !== 0) {
          subtotal = new Decimal(subtotal)
            .add(
              new Decimal(line.enteredQuantity)
                .mul(line.price)
                .toDecimalPlaces(2)
            )
            .toNumber();
        }
      }
    });
    return new Decimal(subtotal).toDecimalPlaces(2).toNumber();
  }
  getSubtotalZeroTax(orderLines: OrderLine[]): number {
    let subtotal = 0;
    orderLines.forEach((line) => {
      if (line.active) {
        if (line.tax.value === 0) {
          subtotal = new Decimal(subtotal)
            .add(new Decimal(line.enteredQuantity).mul(line.price))
            .toNumber();
        }
      }
    });
    return subtotal;
  }
  getTax(orderLines: OrderLine[]): number {
    let totalTax = 0;
    const subTotal = orderLines
      .filter((line) => line.active && line.product.taxPresenter.value)
      .map((line) =>
        new Decimal(line.enteredQuantity)
          .mul(line.price)
          .toDecimalPlaces(2)
          .toNumber()
      )
      .reduce((a, b) => new Decimal(a).add(b).toDecimalPlaces(2).toNumber(), 0);
    totalTax = new Decimal(subTotal)
      .mul(GeneralConfigurations.defaultTax || 0.12)
      .toDecimalPlaces(2)
      .toNumber();
    return totalTax;
  }

  resynchronizeQuotation(quotationId: string) {
    this.quotationService.resynchronizeQuotation(quotationId).subscribe(res => {
        this.view.showSuccess('', 'Sincronizado exitoso');
    }, (error: HttpErrorResponse) => {
        this.view.showError('Atención', error.error.message);
    });
}
}
