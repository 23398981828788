import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Decimal } from 'decimal.js';
import { Presenter } from 'src/app/core/presenter';
import { round } from 'src/app/core/utils';
import { ClientModel } from 'src/app/models/client.model';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { CreditStatus, Order } from 'src/app/models/order.model';
import { OrderLine, OrderLineToSave } from 'src/app/models/orderline.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { PaymentTerm } from 'src/app/models/paymentterm';
import { ProcessModel } from 'src/app/models/process.model';
import { PromotionMessageModel, Status } from 'src/app/models/promotion-message.model';
import { QuotationDetailModel, QuotationModel, TypeStatusQuotation } from 'src/app/models/quotation.model';
import { Tax } from 'src/app/models/tax.model';
import { AddClient, ClearClient } from 'src/app/reducers/client.action';
import { ClearInvoice, SelectInvoice } from 'src/app/reducers/invoice.action';
import { IAppState } from 'src/app/reducers/order.reducer';
import { DefaultconfigurationsService, GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentTermService } from 'src/app/services/paymentterm.service';
import { PromotionProvider } from 'src/app/services/promotion.provider';
import { PromotionService } from 'src/app/services/promotion.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { TaxService } from 'src/app/services/tax.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import Swal from 'sweetalert2';

import {
    AddIncrement,
    ApplyPromotions,
    DeleteAllOrderLines,
    DenyInvoce,
    SetAdminPriceDiscount,
    SetBilleton,
    SetClientAgreementDiscount,
    SetCreditCardPayment,
    SetObservationInRedux,
} from '../../../../../../reducers/order.action';
import { DataProvider } from '../../../../../../services/data.provider';
import { SettingPresenter } from '../../../../shared/setting/presenter/setting.presenter';
import { InvoiceBalanceView } from '../invoice-balance.view';
import { GetSalesPreviusMonthService } from 'src/app/services/get-sales-previus-month.service';
import { ProductService } from 'src/app/services/product.service';
import { Payment } from 'src/app/models/payment.model';
import { ProudctCategoryType } from 'src/app/models/product.model';
import { ProductQuestView } from '../../product-quest/product-quest.view';
import { ClientService } from 'src/app/services/client.service';
import { UsernameService } from 'src/app/services/currentUSer.service';




@Injectable({
    providedIn: 'root',
})
export class InvoiceBalancePresenter implements Presenter {
    view: InvoiceBalanceView;
    productQuestView: ProductQuestView;
    procesesSelected: ProcessModel = ProcessModel.INVOICE;
    collectionCenterLines = false;
    loadUserPrice = '';
    highestRole = '';
    priceLow = 0;
    lastPromotionMessage: PromotionMessageModel = {};
    payments: Payment[] = [];
    quotationGoToHQ: boolean;
    constructor(
        public store: Store<{ client: ClientModel, adviser: ClientModel, orderLines: OrderLine }>,
        private orderService: OrderService,
        private paymentTermService: PaymentTermService,
        private usernameService: UsernameService,
        public settingPresenter: SettingPresenter,
        public taxService?: TaxService,
        private warehouseService?: WarehouseService,
        private quotationService?: QuotationService,
        private dataProvider?: DataProvider,
        private promotionService?: PromotionService,
        private promotionProvider?: PromotionProvider,
        private getSalesPreviusMonth?: GetSalesPreviusMonthService,
        public defaultconfigurationsService?: DefaultconfigurationsService,
        private productService?: ProductService,
        private clientService?: ClientService

    ) {
        this.promotionProvider.enabled$.subscribe(() => this.getPromotions());
    }

    init() {

        this.usernameService.getCurrentUser().subscribe(
            (data: any) => {
                const defaultUser = data;
                this.clientService.getUserPrices(defaultUser.userId).subscribe(
                    (res: any) => {
                        if (res && res.price) {
                            this.loadUserPrice = res;
                            const roleValues = {
                                ESPECIAL: 1,
                                WHOLE_SALER: 2,
                                ADMINISTRATOR: 3,
                                LIMIT: 4,
                                BASE: 5
                            };
                            const rolesArray = res.price.split(',');
                            this.highestRole = rolesArray[0];
                            for (let i = 1; i < rolesArray.length; i++) {
                                if (roleValues[rolesArray[i]] > roleValues[this.highestRole]) {
                                    this.highestRole = rolesArray[i];
                                }
                            }
                            console.log(this.highestRole);
                        } else {
                            this.loadUserPrice = null;
                        }
                    },
                    (error) => {
                        console.error('Error al recuperar los precios de los usuarios', error);
                        this.loadUserPrice = null;
                    }
                );
            }
        );

        this.store.pipe(select('orderLines')).subscribe((value: IAppState) => {
            if (value.isBilleton && !value.billetonAmount) {
                this.store.dispatch(new SetBilleton(false));
            }
            this.view.ifExistProductCreditRed = false;
            value.orderLines.forEach(element => {
                if (element.product.referenceCode === 'L20179') {
                    this.view.ifExistProductCreditRed = true;
                }
            });
            this.view.discountRecycling = value.discountRecycling;
            this.view.creditCardPayment = value.creditCardPayment;
            this.view.discountPromotion = value.discountPromotion;
            this.view.isBilleton = value.isBilleton;
            this.view.isAdminPriceDiscount = value.isAdminPriceDiscount;
            this.view.lines = value.orderLines;
            this.view.billetonAmount = this.dataProvider.processPayment === 'current'
                ? value.billetonAmount.subC : value.billetonAmount.subD;
            // this.view.subTotal12 = value.subTotal12;
            this.view.pvpSubtotal = value.pvpSubtotal;
            // this.view.subTotal0 = value.subTotal0;
            this.view.current = value.current;
            this.view.deferred = value.deferred;
            this.view.credired = value.creditred;
            // this.view.subTotal12 = round(this.view.subTotal12, 2);
            // this.view.subTotal0 = round(this.view.subTotal0, 2);
            this.view.taxAndSuntotalDinamicList = value.taxModelList;
            this.view.subTotal = this.view.taxAndSuntotalDinamicList ? this.view.taxAndSuntotalDinamicList.subtotal : 0;
            this.view.totalTax = this.view.taxAndSuntotalDinamicList ? this.view.taxAndSuntotalDinamicList.totalTaxList : 0;
            this.view.agreementDiscount = new Decimal(value.totalAgreementDiscount < 0 ? 0 : value.totalAgreementDiscount)
                .toDecimalPlaces(2).toNumber();
            this.view.feDiscount = round(value.totalFEDiscount - this.view.agreementDiscount, 2);
            this.view.cardsIncrement = value.totalCardsIncrement;
            this.view.totalDiscount = new Decimal(this.view.feDiscount)
                .add(this.view.discountPromotion)
                .add(this.view.agreementDiscount)
                .add(this.view.discountRecycling).toDecimalPlaces(2).toNumber();
            this.view.updateOrder = value.updateOrder;
            this.view.isInvoiceAllowed = value.isInvoiceAllowed;
            this.procesesSelected = value.procesesSelected;
            this.view.observation = value.observation;
            if (this.view.isBilleton) {
                /*
                if (this.view.subTotal12 > this.view.billetonAmount) {
                    this.view.subTotal12 = new Decimal(this.view.subTotal12).minus(this.view.billetonAmount).toDecimalPlaces(2).toNumber();
                    this.view.totalTax = new Decimal(value.totalTax)
                        .minus(new Decimal(this.view.defaultTax || 12).mul(this.view.billetonAmount || 0)
                            .div(100)).toDecimalPlaces(2).toNumber();
                } else {
                    this.view.subTotal0 = new Decimal(this.view.subTotal0).minus(this.view.billetonAmount).toDecimalPlaces(2).toNumber();
                    this.view.totalTax = this.view.totalTax; // ????
                }
                */



                this.view.subTotal = new Decimal(this.view.subTotal).minus(this.view.billetonAmount).toDecimalPlaces(2).toNumber();
                this.view.agreementDiscount = new Decimal(this.view.agreementDiscount)
                    .add(this.view.billetonAmount).toDecimalPlaces(2).toNumber();
                this.view.totalDiscount = new Decimal(this.view.totalDiscount)
                    .add(this.view.billetonAmount).toDecimalPlaces(2).toNumber();
            }
            this.view.total = this.view.subTotal +
                (this.view.taxAndSuntotalDinamicList ? this.view.taxAndSuntotalDinamicList.totalTaxList : 0);

            if (this.procesesSelected === ProcessModel.INVOICE) {
                this.view.isInvoiceProcess = true;
            } else {
                this.view.isInvoiceProcess = false;
            }
            if (this.view.updateOrder) {
                this.view.paymentTermSelected = this.view.updateOrder.paymentTermPresenter;
                this.view.paymentMethodSelected = this.view.updateOrder.paymentMethod;
                this.view.buttonTitle = 'ACTUALIZAR ORDEN';
                this.view.isInvoiceAllowed = value.isInvoiceAllowed;
            } else {
                this.view.buttonTitle = 'GUARDAR ORDEN';
            }
            this.getPromotions();
            this.checkErrors();
            this.checkInfo();
            this.checkObs();
            this.checkCommissionPercentage();
        });
        this.store.pipe(select('client')).subscribe(val => {
            if (val.client) {
                this.view.client = val.client;
                if (!this.view.updateOrder) {
                    this.view.paymentTermSelected = this.view.client.paymentTermPresenter;
                    this.view.paymentMethodSelected = this.view.client.paymentMethod;
                } else {
                    this.view.paymentTermSelected = this.view.updateOrder.paymentTermPresenter;
                    this.view.paymentMethodSelected = this.view.updateOrder.paymentMethod;
                }

            } else {
                this.view.paymentTermSelected = null;
                this.view.paymentMethodSelected = null;
                this.view.client = null;
            }


        });

        this.store.pipe(select('adviser')).subscribe(val => {
            if (val && val.adviser) {
                this.view.adviser = val.adviser;
            }
        });
        this.store.pipe(select('invoice')).subscribe(val => {
            if (val && val.payments) {
                this.payments = val.payments;
                this.view.paymentsCards = this.payments.filter(payment => payment.paymentMethod === PaymentMethod.CREDIT_CARD
                    || payment.paymentMethod === PaymentMethod.DEBIT).length;
            }
        });

        this.loadPaymentMethod();
        this.loadPaymentTerm();
        // this.getDefaultTax();
    }




    public loadPaymentMethod() {

        const paymentMethod: { label: string, value: PaymentMethod }[] = [];
        paymentMethod.push({ label: 'EFECTIVO', value: PaymentMethod.CASH });
        paymentMethod.push({ label: 'CHEQUE', value: PaymentMethod.CHECK });
        paymentMethod.push({ label: 'CRÉDITO', value: PaymentMethod.CREDIT });
        paymentMethod.push({ label: 'TARJETA DE CRÉDITO', value: PaymentMethod.CREDIT_CARD });
        paymentMethod.push({ label: 'TARJETA DE DÉBITO', value: PaymentMethod.DEBIT });
        paymentMethod.push({ label: 'NOTA DE CRÉDITO', value: PaymentMethod.CREDIT });
        this.view.paymentMethod = paymentMethod;

    }

    public loadPaymentTerm() {
        this.paymentTermService.searchPaymentTerm().then(paymentTerms => {
            const paymentTermsToView: { label: string, value: PaymentTerm }[] = [];
            paymentTerms.forEach(paymentTerm => {
                paymentTermsToView.push({ label: paymentTerm.description, value: paymentTerm });
            });

            this.view.paymentTerm = paymentTermsToView;
        }).catch(error => {
            this.view.showError('Atención', 'Ocurrió un error en la comunicación!');
        });
    }
    geTotalAmountPreviousMontht() {
        this.view.blockUi();
        return this.getSalesPreviusMonth.getMaxDiscountAdminPrice()
            .toPromise()
            .then((res: number) => {
                this.dataProvider.amountInitialBox = new Decimal(res).toDecimalPlaces(2).toNumber();
                this.dataProvider.amountInitialBox = this.dataProvider.amountInitialBox < 0 ? 0 : this.dataProvider.amountInitialBox;
            });
    }
    async validateData() {
        this.collectionCenterLines = false;
        if (!this.view.client) {
            this.view.showError('', 'Ingrese un cliente');
            return;
        }

        let trueLines = false;
        this.view.lines.forEach(line => {
            if (line.active) {
                trueLines = true;
            }
        });
        if (this.view.lines.length < 1 || !trueLines) {
            this.view.showError('', 'Ingrese items a vender');
            return;
        }
        if (!this.view.paymentMethodSelected) {
            this.view.showError('', 'Seleccione un método de pago');
            return;
        }

        if (this.view.paymentMethodSelected !== PaymentMethod.CASH && !this.view.paymentTermSelected) {
            this.view.showError('', 'Seleccione un término de pago');
            return;
        }

        if (this.view.client.dni === '9999999999999' && this.view.total >
            this.settingPresenter.defaultconfigurationsService.defaultMaxFinalConsumerAmount) {
            this.view.showError('', 'Consumidor final no puede exceder $'
                + this.settingPresenter.defaultconfigurationsService.defaultMaxFinalConsumerAmount);
            return;
        }

        if (this.view.client.dni === '9999999999999' && this.view.total >
            this.settingPresenter.defaultconfigurationsService.defaultMinFinalConsumerAmount && !this.view.observation) {
            this.view.showError('', 'Debe ingresar una observación ');
            return;
        }

        this.view.lines.forEach(line => {
            if (line.warehousePresenter.referenceId === this.dataProvider.defaultWarehouse.collectionCenterReferenceId &&
                line.active) {
                this.collectionCenterLines = true;
            }
        });
        if (this.view.client.dni === '9999999999999' && this.collectionCenterLines) {
            this.view.showError('', 'Stock de acopio necesita datos de cliente');
            return;
        }
        if ((!this.view.observation || this.view.observation.length < 5) && this.collectionCenterLines) {
            this.view.showError('', 'Detalle dirección de entrega en observación');
            return;
        }
        if (this.view.creditStatus !== 'Sin crédito' && this.view.client.totalAmountAvailable <= 0) {
            this.view.showError('', 'El cliente no dispone de crédito');
            return;
        }
        if (this.dataProvider.isSalesExpress && !this.view.adviser) {
            this.view.showError('Venta Vecino Express', 'Debe seleccionar un asesor');
            return;
        }
        if (this.view.error) {
            this.view.showError('', this.view.error);
            return;
        }
        if (this.view.isAdminPriceDiscount && this.view.agreementDiscount) {
            await this.geTotalAmountPreviousMontht();
            if (this.view.agreementDiscount > this.dataProvider.amountInitialBox) {
                this.view.showError('Descuento disponible USD ' + new Decimal(this.dataProvider.amountInitialBox).toDecimalPlaces(2),
                    'Descuento no aplicado, usted no dispone de USD ' + this.view.agreementDiscount);
                return;
            }
        }
        if (this.procesesSelected !== ProcessModel.ORDER && !this.dataProvider.processPayment) {
            this.view.showError('Advertencia', 'Debe seleccionar el tipo de pago');
            return;
        }

        if (this.collectionCenterLines) {
            this.view.showTransportConfirmation();
            return;
        }

        if (this.procesesSelected === ProcessModel.INVOICE) {
            if (!this.isValidateDataProcessInvoice()) {
                return;
            }
        }

        if (this.procesesSelected === ProcessModel.QUOTATION && (this.dataProvider.quotationStatus === null)) {
            if (this.isValidateDataProcessNewQuotation()) {
                this.view.showQuotationConfirmation(this.quotationGoToHQ ?
                    'La Cotización será enviada para su aprobación' :
                    'La Cotización no necesita aprobación');
            }
            return;
        }

        if (this.procesesSelected === ProcessModel.QUOTATION && this.dataProvider.quotationStatus !== null) {
            if (this.isValidateDataProcessUpdateQuotationApproved()) {
                this.view.showUpdateQuotation(this.view.action);
            }
            return;
        }

        /* if (this.dataProvider.quotationStatus === 'TO_BE_INVOICED')
        {
            if (this.isValidateDataProcessNewQuotation()) {

            this.view.showUpdateQuotation(this.view.action);
            }
            return;
        } */

        this.save();

    }

    isValidateDataProcessInvoice(): boolean {
        let valid = true;
        this.view.lines.forEach(line => {
            if (line.active) {
                if (line.maxQuantity === 0) {
                    this.view.showError('', 'Sin stock en ' + line.product.name);
                    valid = false;
                }
            }
        });
        return valid;
    }

    isValidateDataProcessNewQuotation(): boolean {
        this.quotationGoToHQ = false;
        let valid = true;
        this.view.lines.forEach(line => {
            if (line.active) {
                if (line.suggestedPrice === 0) {
                    line.suggestedPrice = line.priceWithoutTax;
                }
                if (line.product.suggestedPrice > 0 && line.product.suggestedPrice < line.selectedMeasure.basePrice
                    && !line.product.observation) {
                    this.view.showError('', 'Producto ' + line.product.referenceCode + ' sin observación');
                    valid = false;
                }
                if (line.enteredQuantity === 0) {
                    this.view.showError('', 'Cantidad 0 ingresada en ' + line.product.name);
                    valid = false;
                }
                if (!line.product.checkedApproveSuggestion && this.dataProvider.quotationStatus === TypeStatusQuotation.TO_BE_APPROVED) {
                    this.view.showError('', 'Falta aprobación en ' + line.product.name);
                    valid = false;
                }
                if (line.base < line.suggestedPrice && line.suggestedPrice < line.priceWithoutTax
                    && !this.dataProvider.isAdminPriceDiscount) {
                    this.showEnablePriceSpecial();
                    valid = false;
                }
                if (line.product.suggestedPrice < line.base && line.product.suggestedPrice > 0) {
                    this.quotationGoToHQ = true;
                }
            }
        });
        return valid;
    }

    isValidateDataProcessUpdateQuotationApproved(): boolean {
        let valid = true;
        if (this.view.selectedState.code === TypeStatusQuotation.APPROVED) {
            this.view.lines.forEach(line => {
                if (line.active) {
                    if (!line.product.checkedApproveSuggestion) {
                        this.view.showError('', 'Sin aprobación en ' + line.product.name);
                        valid = false;
                    }
                }
            });
        }

        if (this.dataProvider.quotationStatus === 'TO_BE_INVOICED') {
            this.view.lines.forEach(line => {
                if (line.active) {
                    if (line.product.suggestedPrice > 0 && line.product.suggestedPrice < line.selectedMeasure.basePrice
                        && !line.product.observation) {
                        this.view.showError('', 'Producto ' + line.product.referenceCode + ' sin observación');
                        valid = false;
                    }
                }
            });
        }

        return valid;
    }

    showEnablePriceSpecial() {
        Swal.fire({
            title: 'Active la opción Precios Aprobador Especial',
            text: 'Para el rango de valores en el precio sugerido',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar'
        });
    }

    save() {
        if (this.view.isInvoiceProcess) {
            this.saveInvoceOrderAndDispatch(true);
        } else
            if (this.view.isInvoiceAllowed) {
                this.updateOrderAndGenerateInvoice();
            } else {
                this.view.showConfirmation();
            }
    }

    saveInvoceOrderAndDispatch(isProdQuest: boolean) {
        if (GeneralConfigurations.creditCardPayment === true && this.view.total < GeneralConfigurations.minimumAmountDeferrer) {
            this.view.showWarn('Advertencia ', 'Monto mínimo para facturar con tarjeta de crédito: $'
                + GeneralConfigurations.minimumAmountDeferrer);
            return;
        }

        const orderDetails: OrderLineToSave[] = [];
        let actualLine = 1;
        this.view.lines.forEach(line => {
            // if (!line.orderDetailId) {
            const detail: OrderLineToSave = {
                orderDetailId: line.orderDetailId ? line.orderDetailId : null,
                product: line.product,
                enteredQuantity: line.enteredQuantity,
                convertedQuantity: line.convertedQuantity,
                measureUnitConversionPresenter: line.selectedMeasure,
                tax: line.product.taxPresenter,
                unitPrice: line.priceWithoutTax,
                lineNumber: actualLine,
                active: true,
                discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                discountRate: line.discount,
                warehousePresenter: line.warehousePresenter,
                inventoryMovementDetailId: line.inventoryMovementDetailId,
                normalPrice: line.product.normalPriceWithoutTax,
                specialDiscount: line.specialDiscount,
                wholesalerDiscount: line.wholesalerDiscount,
                administratorDiscount: line.administratorDiscount,
                discountPromotion: line.discountPromotion,
                transferDetailPresenter: line.transferDetailPresenter,
                subtotal: line.subTotal,
                total: line.total,
                quantityRecycling: line.quantityRecycling || 0,
                discountRecycling: new Decimal(line.discountRecycling || 0).mul(line.quantityRecycling || 0).toNumber(),
                agreementType: line.agreementType,
                limitDiscount: line.limitDiscount,
                chainPrice: line.chainPrice,
                baseDiscount: line.baseDiscount,
            };
            detail.discount = detail.discount < 0 ? 0 : detail.discount;
            orderDetails.push(detail);
            actualLine++;
            // }
        });


        const newOrder: Order = {
            number: null,
            orderId: null,
            clientPresenter: this.view.client,
            paymentMethod: this.view.client.paymentMethod,
            paymentTermPresenter: this.view.client.paymentTermPresenter,
            orderDetailPresenters: orderDetails,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            transactionDate: null,
            emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
            observation: this.view.observation,
            creditStatus: this.view.creditStatus === 'Sin crédito' ? CreditStatus.NOT_REQUESTED : CreditStatus.APPROVED,
            isCreditCardPayment: this.view.creditCardPayment,
            billetonAmount: this.view.isBilleton ? this.view.billetonAmount : 0,
            isAdminPrice: this.view.isAdminPriceDiscount,
            discount: this.view.agreementDiscount,
            discountPromotion: this.view.discountPromotion,
            discountIncrement: 0,
            discountRecycling: this.view.discountRecycling,
            quotationId: this.dataProvider.quotationId,
            isQuotation: this.dataProvider.quotationId ? true : false,
            sendBusinessManager: this.dataProvider.sendBusinessManagerQuotation,
            quotationNumber: this.dataProvider.numberReferenceQuotation

        };
        // review
        const creditCardPayment = this.view.lines.filter(line =>
            (line.specialDiscount > 0 && line.product.isHeavy) ||
            (line.wholesalerDiscount > 0 && line.product.isHeavy) ||
            (line.administratorDiscount > 0 && line.product.isHeavy) ||
            (line.limitDiscount > 0 && line.product.isHeavy)
        ).length;
        this.dataProvider.blockPaymentsByAdminPrice = false;
        if (creditCardPayment && this.view.isAdminPriceDiscount) {
            this.dataProvider.blockPaymentsByAdminPrice = false;
        }
        const invoice: InvoiceModel = {};
        invoice.orderPresenter = newOrder;
        invoice.messagePresenter = this.promotionProvider.promotionMessage;
        invoice.discountPromotion = this.view.discountPromotion;
        this.store.dispatch(new SelectInvoice(invoice));
        if (isProdQuest) {
            this.view.onCreateInvoice();
        }
    }

    // validations are updated for base price

    checkErrors() {
        this.view.error = '';
        this.view.validateProductCredired();
        this.view.lines.filter(line => line.active).forEach(line => {

            this.priceLow = line.selectedMeasure.price;

            switch (this.highestRole) {
                case 'BASE':
                    this.priceLow = line.selectedMeasure.basePrice;
                    break;
                case 'LIMIT':
                    this.priceLow = line.selectedMeasure.priceLimit;
                    break;
                case 'ADMINISTRATOR':
                    this.priceLow = line.selectedMeasure.administratorPrice;
                    break;
                case 'WHOLE_SALER':
                    this.priceLow = line.selectedMeasure.wholesalerPrice;
                    break;
                case 'ESPECIAL':
                    this.priceLow = line.selectedMeasure.specialPrice;
                    break;
            }

            line.error = '';
            line.limit = this.view.isCredired ? line.product.normalPriceWithoutTax : line.product.priceLimitWithoutTax;
            if (this.procesesSelected === ProcessModel.QUOTATION || this.dataProvider.quotationId) {
                if (this.view.isAdminPriceDiscount) {
                    const maxDiscount = new Decimal(100)
                        .minus(new Decimal(line.base || line.selectedMeasure.basePrice).mul(100).div(line.product.priceWithoutTax)).toNumber();
                    line.maxDiscount = maxDiscount < 0 ? 0 : maxDiscount;
                    line.specialDiscount = 0;
                    line.wholesalerDiscount = 0;
                    line.administratorDiscount = 0;
                    this.dataProvider.priceListAdmin = '';
                    line.wholesalerDiscount = 0;
                    line.administratorDiscount = 0;
                    line.specialDiscount = 0;
                    line.limitDiscount = 0;
                    line.baseDiscount = 0;
                    line.limit = line.product.specialPrice;
                    if (this.view.subTotal >= GeneralConfigurations.specialPriceLimit && this.view.subTotal <
                        GeneralConfigurations.wholesalerPriceLimit) {
                        line.limit = line.product.specialPrice;
                        this.dataProvider.priceListAdmin = 'Especial';

                    } else if (this.view.subTotal >= GeneralConfigurations.wholesalerPriceLimit && this.view.subTotal <
                        GeneralConfigurations.administratorPriceLimit) {
                        line.limit = line.product.wholesalerPrice;
                        this.dataProvider.priceListAdmin = 'Mayorista';

                    } else if (this.view.subTotal >= GeneralConfigurations.administratorPriceLimit) {
                        line.limit = line.product.administratorPrice;
                        this.dataProvider.priceListAdmin = 'Administrador';

                    }

                    if (line.priceWithoutTax < line.selectedMeasure.price) {
                        if (line.priceWithoutTax >= line.product.specialPrice) {
                            line.specialDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.specialPrice
                            && line.priceWithoutTax >= line.product.wholesalerPrice) {
                            line.wholesalerDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.wholesalerPrice
                            && line.priceWithoutTax >= line.product.administratorPrice) {
                            line.administratorDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.administratorPrice
                            && line.priceWithoutTax >= line.product.priceLimitWithoutTax) {
                            line.limitDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.administratorPrice
                            && line.priceWithoutTax < line.product.priceLimitWithoutTax) {

                            line.baseDiscount = (this.dataProvider.processPayment === 'deferred' ?
                                (line.selectedMeasure.price + (line.selectedMeasure.price * this.dataProvider.commissionCard)) :
                                line.selectedMeasure.price) - line.priceWithoutTax;
                        }
                    }

                    if (line.priceWithoutTax < line.product.basePrice) {
                        line.product.suggestedPrice = line.product.priceWithoutTax;
                        line.product.priceWithoutTax = line.product.basePrice;
                    }
                }

                if (line.maxQuantity === 0) {
                    line.error = 'Stock disponible: ' + line.maxQuantity; // Error de stock no disponible
                    // this.view.showWarn('Advertencia', 'Stock disponible: ' + line.maxQuantity + ' en ' + line.referenceCode);
                }
                if (line.error && this.procesesSelected === ProcessModel.INVOICE) {
                    this.view.error = 'No se puede facturar';
                }
                if (!this.view.isAdminPriceDiscount) {
                    if (this.procesesSelected !== 'FACTURA') {
                        if (line.product.priceWithoutTax < this.priceLow && this.priceLow !== line.selectedMeasure.basePrice) {
                            line.error = 'Precio ingresado no puede ser menor al precio autorizado';
                            this.view.error = 'No se puede cotizar';
                            /* line.product.suggestedPrice = line.product.priceWithoutTax
                            line.product.priceWithoutTax = this.priceLow; */
                        }
                    }
                }
            } else {
                if (this.view.isAdminPriceDiscount) {
                    line.specialDiscount = 0;
                    line.wholesalerDiscount = 0;
                    line.administratorDiscount = 0;
                    this.dataProvider.priceListAdmin = '';
                    line.wholesalerDiscount = 0;
                    line.administratorDiscount = 0;
                    line.specialDiscount = 0;
                    line.limitDiscount = 0;
                    line.baseDiscount = 0;
                    line.limit = line.product.specialPrice;
                    if (this.view.subTotal >= GeneralConfigurations.specialPriceLimit && this.view.subTotal <
                        GeneralConfigurations.wholesalerPriceLimit) {
                        line.limit = line.product.specialPrice;
                        this.dataProvider.priceListAdmin = 'Especial';

                    } else if (this.view.subTotal >= GeneralConfigurations.wholesalerPriceLimit && this.view.subTotal <
                        GeneralConfigurations.administratorPriceLimit) {
                        line.limit = line.product.wholesalerPrice;
                        this.dataProvider.priceListAdmin = 'Mayorista';

                    } else if (this.view.subTotal >= GeneralConfigurations.administratorPriceLimit) {
                        line.limit = line.product.priceLimitWithoutTax;
                        this.dataProvider.priceListAdmin = 'Administrador';

                    }
                    const maxDiscount = new Decimal(100)
                        .minus(new Decimal(line.base).mul(100).div(line.product.priceWithoutTax))
                        .toNumber();
                    line.maxDiscount = maxDiscount < 0 ? 0 : maxDiscount;
                    if (line.priceWithoutTax < Number(new Decimal(line.selectedMeasure.basePrice).toDecimalPlaces(4))) {
                        line.error = 'El precio no puede ser menor al precio: ' + new Decimal(line.selectedMeasure.basePrice)
                            .toDecimalPlaces(6);
                    }

                    if (this.view.subTotal < GeneralConfigurations.specialPriceLimit) {
                        this.view.error = 'DESCUENTO NO APLICABLE, Monto mínimo de venta USD ' +
                            new Decimal(GeneralConfigurations.specialPriceLimit).toDecimalPlaces(2);
                    }

                    if (line.priceWithoutTax < line.selectedMeasure.price) {
                        if (line.priceWithoutTax >= line.product.specialPrice) {
                            line.specialDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.specialPrice
                            && line.priceWithoutTax >= line.product.wholesalerPrice) {
                            line.wholesalerDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.wholesalerPrice
                            && line.priceWithoutTax >= line.product.administratorPrice) {
                            line.administratorDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.administratorPrice
                            && line.priceWithoutTax >= line.product.priceLimitWithoutTax) {
                            line.limitDiscount = line.selectedMeasure.price - line.priceWithoutTax;
                        } else if (line.priceWithoutTax < line.product.priceLimitWithoutTax
                            && line.priceWithoutTax >= line.product.basePrice) {
                            line.baseDiscount = (this.dataProvider.processPayment === 'deferred' ?
                                (line.selectedMeasure.price + (line.selectedMeasure.price * this.dataProvider.commissionCard)) :
                                line.selectedMeasure.price) - line.priceWithoutTax;
                        }
                    }

                } else {
                    const maxDiscount = Number((100 - Number(((line.selectedMeasure.basePrice * 100) /
                        line.product.priceWithoutTax))));

                    line.maxDiscount = maxDiscount < 0 ? 0 : maxDiscount;

                    if (line.product.priceWithoutTax < this.priceLow) {
                        line.error = 'Precio menor al asignado';
                    }
                }


                if (!this.view.creditCardPayment && !this.view.isAdminPriceDiscount) {
                    const value = this.view.isCredired && line.product.referenceCode !== 'L20179' ?
                        line.selectedMeasure.normalPrice : this.priceLow;
                    // line.selectedMeasure.normalPrice : line.selectedMeasure.price;

                    if (line.product.priceWithoutTax < value) {
                        line.error = 'Precio ingresado no puede ser menor a precio autorizado';
                    }
                } else {
                    if (line.product.priceWithoutTax < this.priceLow && !this.view.isAdminPriceDiscount) {
                        line.error = 'Precio ingresado no puede ser menor a precio autorizado';
                    }
                    if (line.product.priceWithoutTax < line.selectedMeasure.basePrice && this.view.isAdminPriceDiscount) {
                        line.error = 'Precio ingresado no puede ser menor a precio Base';
                    }
                }



                if (line.enteredQuantity < 1) {
                    line.error = 'Cantidad no puede ser menor a 1';
                }
                if (line.quantityRecycling > line.enteredQuantity) {
                    line.error = 'Cantidad de reciclaje incorrecto';
                }

                line.maxQuantity = new Decimal(line.maxQuantity || 0).toDecimalPlaces(2).toNumber();
                if (line.enteredQuantity > line.maxQuantity) {
                    line.error = 'Cantidad errónea, Stock disponible: ' + line.maxQuantity;
                }
                if (line.error) {
                    this.view.error = 'No se puede facturar';
                }
            }
        });
    }

    checkInfo() {
        this.view.info = '';
        this.view.lines.filter(line => line.active).forEach(line => {
            line.info = '';
            // line.limit = line.product.priceLimitWithoutTax;
            if (this.procesesSelected === ProcessModel.QUOTATION && this.dataProvider.processPayment === 'current') {
                line.info = 'P.B:' + line.selectedMeasure.basePrice;
            }
        });
    }

    checkObs() {
        this.view.obs = '';
        this.view.lines.filter(line => line.active).forEach(line => {
            line.obs = '';
            if (this.procesesSelected === ProcessModel.QUOTATION && typeof line.product.observationHq !== 'undefined') {
                if (line.product.observationHq === '') {
                    line.obs = '';
                } else {
                    line.obs = 'Autorizador: ' + line.product.observationHq;
                }
            }
        });
    }
    checkCommissionPercentage() {
        this.view.comission = '';
        this.view.lines.filter(line => line.active).forEach(line => {
            line.comission = '';
            if (this.procesesSelected === ProcessModel.QUOTATION && typeof line.product.commissionPercentage !== 'undefined') {
                if (line.product.commissionPercentage === 0) {
                    line.comission = '';
                } else {
                    line.comission = 'Comisión: ' + line.product.commissionPercentage + ' %';
                }
            }
        });
    }

    saveOrder() {

        if (this.view.updateOrder) {
            this.updateOrder();

        } else {
            this.saveNewOrder();
        }

    }

    saveNewOrder() {
        this.view.blockUi();
        const orderDetails: OrderLineToSave[] = [];
        let actualLine = 1;
        this.view.lines.forEach(line => {
            if (!line.orderDetailId) {
                const detail: OrderLineToSave = {
                    orderDetailId: null,
                    product: line.product,
                    enteredQuantity: line.enteredQuantity,
                    convertedQuantity: line.convertedQuantity,
                    measureUnitConversionPresenter: line.selectedMeasure,
                    tax: line.product.taxPresenter,
                    unitPrice: line.priceWithoutTax,
                    lineNumber: actualLine,
                    discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                    discountRate: line.discount,
                    active: true,
                    warehousePresenter: line.warehousePresenter,
                    inventoryMovementDetailId: line.inventoryMovementDetailId,
                    normalPrice: line.product.normalPriceWithoutTax,
                    discountPromotion: line.discountPromotion,
                    administratorDiscount: 0,
                    specialDiscount: 0,
                    unitPriceWithTax: 0,
                    wholesalerDiscount: 0,
                    subtotal: line.subTotal,
                    total: line.total,
                    quantityRecycling: line.quantityRecycling,
                    discountRecycling: line.discountRecycling,
                    chainPrice: line.chainPrice
                };
                orderDetails.push(detail);
                actualLine++;
            }
        });


        const newOrder: Order = {
            number: null,
            orderId: null,
            clientPresenter: this.view.client,
            paymentMethod: this.view.client.paymentMethod,
            paymentTermPresenter: this.view.client.paymentTermPresenter,
            orderDetailPresenters: orderDetails,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            transactionDate: null,
            emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
            observation: this.view.observation,
            creditStatus: CreditStatus.PENDING,
            isCreditCardPayment: this.view.creditCardPayment,
            billetonAmount: 0,
            discount: 0,
            isAdminPrice: false,
            discountIncrement: 0,
            discountRecycling: this.view.discountRecycling || 0
        };

        this.orderService.saveOrder(newOrder).then(data => {
            this.view.showSuccess('', 'La orden se guardo con éxito');
            this.clearPos();
            this.view.showPdf(data);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    checkDiscount() {
        const orderDetails: OrderLineToSave[] = [];
        let actualLine = 1;
        this.view.lines.forEach(line => {
            if (line.active) {
                const detail: OrderLineToSave = {
                    orderDetailId: null,
                    product: line.product,
                    enteredQuantity: line.enteredQuantity,
                    convertedQuantity: line.convertedQuantity,
                    measureUnitConversionPresenter: line.selectedMeasure,
                    tax: line.product.taxPresenter,
                    unitPrice: line.priceWithoutTax,
                    lineNumber: actualLine,
                    discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                    discountRate: line.discount,
                    active: true,
                    warehousePresenter: line.warehousePresenter,
                    inventoryMovementDetailId: line.inventoryMovementDetailId,
                    normalPrice: line.product.normalPriceWithoutTax,
                    discountPromotion: line.discountPromotion,
                    subtotal: line.subTotal,
                    total: line.total,
                    chainPrice: line.chainPrice
                };
                orderDetails.push(detail);
                actualLine++;
            }
        });

        if (orderDetails.length === 0) {
            this.view.showError('Atención', 'No hay líneas');
            return;
        }


        const newOrder: Order = {
            number: null,
            orderId: null,
            clientPresenter: null,
            paymentMethod: null,
            paymentTermPresenter: null,
            orderDetailPresenters: orderDetails,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            transactionDate: null,
            emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
            observation: this.view.observation,
            creditStatus: CreditStatus.NOT_REQUESTED,
            isCreditCardPayment: this.view.creditCardPayment,
            billetonAmount: 0,
            discountIncrement: 0,

        };
        this.view.blockUi();
        this.orderService.checkDiscount(newOrder).subscribe(res => {
            this.view.showEnterPassword(newOrder);
        }, (error: HttpErrorResponse) => {
            this.view.showError('Descuento por total de factura', error.error.message);
        });
    }

    updateOrder() {
        this.view.blockUi();
        const orderDetails: OrderLineToSave[] = [];
        let actualLine = 1;
        this.view.lines.forEach(line => {
            const detail: OrderLineToSave = {
                orderDetailId: line.orderDetailId,
                product: line.product,
                enteredQuantity: line.enteredQuantity,
                convertedQuantity: line.convertedQuantity,
                measureUnitConversionPresenter: line.selectedMeasure,
                tax: line.tax,
                unitPrice: line.priceWithoutTax,
                lineNumber: actualLine,
                active: line.active,
                discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                discountRate: line.discount,
                warehousePresenter: line.warehousePresenter,
                inventoryMovementDetailId: line.inventoryMovementDetailId,
                normalPrice: line.selectedMeasure.normalPrice,
                administratorDiscount: 0,
                specialDiscount: 0,
                unitPriceWithTax: 0,
                wholesalerDiscount: 0,
                discountPromotion: line.discountPromotion,
                subtotal: line.subTotal,
                total: line.total,
                chainPrice: line.selectedMeasure.price
            };
            orderDetails.push(detail);
            actualLine++;
        });
        const updateOrder: Order = {
            number: this.view.updateOrder.number,
            orderId: this.view.updateOrder.orderId,
            clientPresenter: this.view.client,
            paymentMethod: this.view.client.paymentMethod,
            paymentTermPresenter: this.view.client.paymentTermPresenter,
            orderDetailPresenters: orderDetails,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            emissionPointPresenter: null,
            transactionDate: null,
            observation: this.view.observation,
            creditStatus: this.view.updateOrder.creditStatus,
            isCreditCardPayment: this.view.creditCardPayment,
            billetonAmount: 0,
            discount: 0,
            isAdminPrice: false,
            discountIncrement: 0
        };
        this.orderService.updateOrder(updateOrder).then(data => {
            this.view.showWarn('', 'Orden actualizada con éxito');
            this.clearPos();
        }).catch((error: any) => {
            this.view.showError('Atención', error.error.message);
        });
    }
    updateOrderAndGenerateInvoice() {
        this.view.blockUi();
        const orderDetails: OrderLineToSave[] = [];
        let actualLine = 1;
        this.view.lines.forEach(line => {
            const detail: OrderLineToSave = {
                orderDetailId: line.orderDetailId,
                product: line.product,
                enteredQuantity: line.enteredQuantity,
                convertedQuantity: line.convertedQuantity,
                measureUnitConversionPresenter: line.selectedMeasure,
                tax: line.tax,
                unitPrice: line.priceWithoutTax,
                lineNumber: actualLine,
                active: line.active,
                discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                discountRate: line.discount,
                warehousePresenter: line.warehousePresenter,
                inventoryMovementDetailId: line.inventoryMovementDetailId,
                normalPrice: line.selectedMeasure.normalPrice,
                administratorDiscount: 0,
                specialDiscount: 0,
                wholesalerDiscount: 0,
                discountPromotion: line.discountPromotion,
                subtotal: line.subTotal,
                total: line.total,
                chainPrice: line.selectedMeasure.price,
                agreementType: line.agreementType
            };
            orderDetails.push(detail);
            actualLine++;
        });

        const updateOrder: Order = {
            number: this.view.updateOrder.number,
            orderId: this.view.updateOrder.orderId,
            clientPresenter: this.view.client,
            paymentMethod: this.view.client.paymentMethod,
            paymentTermPresenter: this.view.client.paymentTermPresenter,
            orderDetailPresenters: orderDetails,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            emissionPointPresenter: null,
            transactionDate: null,
            observation: this.view.observation,
            creditStatus: this.view.updateOrder.creditStatus,
            isCreditCardPayment: this.view.creditCardPayment,
            billetonAmount: 0,
            discountIncrement: 0,
            discount: 0,
            isAdminPrice: false,
            invoiceAdminPercentage: 0
        };
        this.orderService.updateOrder(updateOrder).then(data => {
            const invoice: InvoiceModel = {};
            invoice.orderPresenter = updateOrder;
            this.store.dispatch(new SelectInvoice(invoice));
            this.view.onCreateInvoice();
            this.dataProvider.isOrder = false;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    saveNewQuotation() {
        this.view.blockUi();
        const quotationDetails: QuotationDetailModel[] = [];
        let actualLine = 1;
        this.view.lines.forEach(line => {
            if (!line.orderDetailId) {
                const detail: QuotationDetailModel = {
                    product: line.product,
                    enteredQuantity: line.enteredQuantity,
                    convertedQuantity: line.convertedQuantity,
                    taxPresenter: line.product.taxPresenter,
                    unitPrice: line.priceWithoutTax,
                    lineNumber: actualLine,
                    discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                    discountRate: line.discount,
                    divideRate: line.selectedMeasure.divideRate,
                    multiplyRate: line.selectedMeasure.multiplyRate,
                    measureUnitFrom: line.selectedMeasure.measureUnitPresenterFrom,
                    measureUnitTo: line.selectedMeasure.measureUnitPresenterTo,
                    price: line.product.priceWithoutTax,


                    discountPromotion: line.discountPromotion,
                    administratorDiscount: line.administratorDiscount,
                    specialDiscount: line.specialDiscount,
                    wholesalerDiscount: line.wholesalerDiscount,
                    subtotal: line.subTotal,
                    total: line.total,
                    chainPrice: line.selectedMeasure.price,
                    stockPDV: line.product.availableQuantity,
                    suggestedPrice: line.product.suggestedPrice,
                    specialPrice: line.product.specialPrice,
                    wholesalerPrice: line.product.wholesalerPrice,
                    administratorPrice: line.product.administratorPrice,
                    limitPrice: line.product.priceLimitWithoutTax,
                    observation: line.product.observation,
                    limitDiscount: line.limitDiscount,
                    baseDiscount: line.baseDiscount,
                    basePrice: line.product.basePrice,
                };
                quotationDetails.push(detail);
                actualLine++;
            }
        });
        const subTotal12List = this.view.taxAndSuntotalDinamicList.dinamicTax.filter(line => line.taxModel.value !== 0);
        let subTotal12 = 0;
        if (subTotal12List) {
            subTotal12 = subTotal12List
                .map(line => line.subtotal)
                .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);
        }
        const subTotal0List = this.view.taxAndSuntotalDinamicList.dinamicTax.filter(line => line.taxModel.value === 0);
        let subTotal0 = 0;
        if (subTotal0List) {
            subTotal0 = subTotal0List
                .map(line => line.subtotal)
                .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);
        }

        const newQuotation: QuotationModel = {
            clientPresenter: this.view.client,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
            observation: this.view.observation,
            defaultTax: null,
            discount: null,
            quotationDetailPresenters: quotationDetails,
            subtotalTaxed: subTotal12,
            subtotalZeroTax: subTotal0,
            isCreditCardPayment: this.view.creditCardPayment,
            hasQuotation: false,
            created: null
        };

        this.quotationService.saveNewQuotation(newQuotation).subscribe(res => {
            this.view.showSuccess('', 'Cotización creada exitosamente');
            this.clearPos();
            this.view.showPdf(res);
            this.store.dispatch(new SetAdminPriceDiscount(false));
        }, (error: HttpErrorResponse) => {
            this.view.showError('Atención', error.error.message);
        });
    }

    clearPos() {
        this.view.creditStatus = 'Sin crédito';
        this.store.dispatch(new ClearClient());
        this.store.dispatch(new DenyInvoce());
        this.store.dispatch(new DeleteAllOrderLines());
        this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.store.dispatch(new SetObservationInRedux(''));
        this.view.paymentMethodSelected = PaymentMethod.CASH;
        this.dataProvider.processPayment = undefined;
        this.dataProvider.quotationStatus = null;
        this.dataProvider.isNewQuotation = true;
        this.view.observation = null;
        this.view.selectedState = null;
        this.dataProvider.isOrder = false;
        this.dataProvider.isAdminPriceDiscount = false;
    }

    setObservation() {
        this.store.dispatch(new SetObservationInRedux(this.view.observation));
    }

    getPromotions() {
        if (this.procesesSelected !== ProcessModel.INVOICE || !this.promotionProvider.enabled || this.dataProvider.isOrder) {
            this.lastPromotionMessage = {};
            this.dataProvider.disableBilleton = false;
            return;
        }
        const message: PromotionMessageModel = {
            status: Status.SALES,
            terminal: this.settingPresenter.view.selectedPointEmission.number,
            customerAdd: [],
            paymentAdd: [],
            itemAdd: []
        };
        let lineNumber = 0;
        this.view.lines.forEach(line => {
            if (line.active) {
                const family = line.product.productCategoryPresenters
                    .find(cf => cf.productCategoryType === ProudctCategoryType.FAMILY);
                const category = line.product.productCategoryPresenters
                    .find(cc => cc.productCategoryType === ProudctCategoryType.MOTHER_CATEGORY);
                const subFamily = line.product.productCategoryPresenters
                    .find(csf => csf.productCategoryType === ProudctCategoryType.SUBFAMILY);
                lineNumber++;
                message.itemAdd.push({
                    seq: lineNumber,
                    qty: line.enteredQuantity,
                    unitprice: line.selectedMeasure.price,
                    code: line.product.referenceCode,
                    xprice: new Decimal(line.selectedMeasure.price).mul(line.enteredQuantity).toNumber(),
                    measureUnit: line.selectedMeasure.measureUnitPresenterTo.symbol,
                    level2: family ? family.name : '',
                    level3: category ? category.name : '',
                    level4: subFamily ? subFamily.name : '',
                });
            }
        });

        if (!lineNumber && !this.view.client) {
            this.promotionProvider.suggestions = [];
            this.lastPromotionMessage = {};
            return;
        }
        message.customerAdd = this.view.client ? [{
            seq: 1,
            type: 'C',
            id: this.view.client.dni,
            email: this.view.client.email,
            name: this.view.client.firstName + this.view.client.middleName,
            lastName: this.view.client.lastName,
            segment: ''
        }] : [];
        if (JSON.stringify(this.lastPromotionMessage) === JSON.stringify(message)) {
            return;
        }
        this.lastPromotionMessage = JSON.parse(JSON.stringify(message));
        this.promotionProvider.promotionMessage = null;

        this.promotionService.getPromotions(message).subscribe((res: PromotionMessageModel) => {
            this.dataProvider.lastPromotion = res;
            if (!this.promotionProvider.enabled) { return; }
            if (res.optional) {
                // this.dataProvider.disableBilleton = true;
                this.store.dispatch(new SetBilleton(false));
                this.store.dispatch(new ApplyPromotions(res.optional.promo));
                message.optional = res.optional;
                message.status = Status.FINISH;
                this.promotionProvider.promotionMessage = message;
            } else {
                this.dataProvider.disableBilleton = false;
                this.promotionProvider.promotionMessage = null;
                this.store.dispatch(new ApplyPromotions([]));
            }
            if (res.suggestions) {
                this.promotionProvider.suggestions = res.suggestions.promo;
            } else {
                this.promotionProvider.suggestions = [];
            }
        }
        );
    }

    onChangePaymentType() {
        this.view.validateProductCredired();
        const processPayment = this.dataProvider.processPayment;
        if (processPayment === 'deferred') {
            GeneralConfigurations.creditCardPayment = true;
            this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            // this.store.dispatch(new SetBilleton(false));
            this.store.dispatch(new SetAdminPriceDiscount(this.view.isAdminPriceDiscount ? true : false));
            this.store.dispatch(new AddIncrement(0));
        } else if (this.dataProvider.processPayment === 'current' && !this.view.isCredired) {
            GeneralConfigurations.creditCardPayment = false;
            this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.store.dispatch(new SetAdminPriceDiscount(this.view.isAdminPriceDiscount ? true : false));
            this.store.dispatch(new AddIncrement(0));
            this.defaultconfigurationsService.showAdminPriceDiscount = true;
        } else if (this.dataProvider.processPayment === 'current' && this.view.isCredired) {
            GeneralConfigurations.creditCardPayment = false;
            this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.store.dispatch(new SetAdminPriceDiscount(false));
            this.store.dispatch(new AddIncrement(0));
        } else if (processPayment === 'credired') {
            GeneralConfigurations.creditCardPayment = false;
            this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            // this.store.dispatch(new SetBilleton(false));
            this.store.dispatch(new SetAdminPriceDiscount(false));
            this.store.dispatch(new AddIncrement(0));
        }
        this.getPromotions();
        if (this.dataProvider.lastPromotion) {
            const res = this.dataProvider.lastPromotion;
            if (res.optional) {
                this.store.dispatch(new ApplyPromotions(res.optional.promo));
            }
            if (res.suggestions) {
                this.promotionProvider.suggestions = res.suggestions.promo;
            }
        }
    }

    getClientAgreementDiscount() {
        if (this.view.lines.length > 0 && this.view.client !== null) {
            const client = this.view.client;
            this.store.dispatch(new AddClient(client));
            const clientAgreement = { clientId: client.clientId, productIds: [] };
            this.view.lines.forEach(line => {
                clientAgreement.productIds.push(line.product.productId);
            });
            this.productService.getProductDiscounts(clientAgreement.clientId, clientAgreement.productIds).subscribe(res => {
                this.store.dispatch(new SetClientAgreementDiscount(res, false));
            }
            );
        }
    }
    deletePayments() {
        this.store.dispatch(new ClearInvoice());
        this.onChangePaymentType();
    }

    updateQuotation() {
        this.view.blockUi();
        const quotationDetails: QuotationDetailModel[] = [];
        let actualLine = 1;
        let reviewRequired = false;
        let sendBusinessManager = false;
        let validateObservation = false;
        this.view.lines.forEach(line => {
            const detail: QuotationDetailModel = {
                quotationDetailId: line.orderDetailId,
                product: line.product,
                enteredQuantity: line.enteredQuantity,
                convertedQuantity: line.convertedQuantity,
                taxPresenter: line.product.taxPresenter,
                unitPrice: line.priceWithoutTax,
                lineNumber: actualLine,
                discount: new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax).toNumber(),
                discountRate: line.discount,
                divideRate: line.selectedMeasure.divideRate,
                multiplyRate: line.selectedMeasure.multiplyRate,
                measureUnitFrom: line.selectedMeasure.measureUnitPresenterFrom,
                measureUnitTo: line.selectedMeasure.measureUnitPresenterTo,
                price: line.product.priceWithoutTax,

                discountPromotion: line.discountPromotion,
                administratorDiscount: line.administratorDiscount,
                specialDiscount: line.specialDiscount,
                wholesalerDiscount: line.wholesalerDiscount,
                subtotal: line.subTotal,
                total: line.total,
                chainPrice: line.selectedMeasure.price,
                stockPDV: line.product.availableQuantity,
                suggestedPrice: line.product.suggestedPrice,
                specialPrice: line.product.specialPrice,
                wholesalerPrice: line.product.wholesalerPrice,
                administratorPrice: line.product.administratorPrice,
                observation: line.product.observation,
                limitDiscount: line.limitDiscount,
                limitPrice: line.product.priceLimitWithoutTax,
                baseDiscount: line.baseDiscount,
                basePrice: line.product.basePrice,
                suggestedPriceBusinessManager: line.product.suggestedPriceBusinessManager,
                suggestedQuantityBusinessManager: line.product.suggestedQuantityBusinessManager,


            };
            if (detail.suggestedPrice < line.product.basePrice && detail.suggestedPrice > 0 && this.dataProvider.quotationStatus === 'TO_BE_INVOICED') {
                reviewRequired = true;
                sendBusinessManager = true;
            }

            if (line.product.suggestedPrice > 0 && line.product.suggestedPrice < line.selectedMeasure.basePrice
                && !line.product.observation) {
                    validateObservation = true;
            }
            quotationDetails.push(detail);
            actualLine++;
        });
        const subTotal12List = this.view.taxAndSuntotalDinamicList.dinamicTax.filter(line => line.taxModel.value !== 0);
        let subTotal12 = 0;
        if (subTotal12List) {
            subTotal12 = subTotal12List
                .map(line => line.subtotal)
                .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);
        }
        const subTotal0List = this.view.taxAndSuntotalDinamicList.dinamicTax.filter(line => line.taxModel.value === 0);
        let subTotal0 = 0;
        if (subTotal0List) {
            subTotal0 = subTotal0List
                .map(line => line.subtotal)
                .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);
        }

        console.log(this.view.client);

        const quotationStatus = reviewRequired ? 'INIT' : this.view.selectedState.code;

        const quotation: QuotationModel = {
            quotationId: this.dataProvider.quotationId,
            clientPresenter: this.view.client,
            subtotal: this.view.subTotal,
            total: this.view.total,
            tax: this.view.totalTax,
            priceListPresenter: this.settingPresenter.view.selectedPrice,
            pdf: null,
            emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
            observation: this.view.observation,
            defaultTax: null,
            discount: null,
            quotationDetailPresenters: quotationDetails,
            subtotalTaxed: subTotal12,
            subtotalZeroTax: subTotal0,
            isCreditCardPayment: this.view.creditCardPayment,
            hasQuotation: false,
            created: null,
            quotationStatus: quotationStatus,
            sendBusinessManager: sendBusinessManager
        };

        this.quotationService.updateQuotation(quotation).subscribe(res => {
            this.view.showSuccess('', reviewRequired ? 'Cotización necesita aprobación' : 'Cotización enviada');
            this.clearPos();
            this.view.showPdf(res);
            this.store.dispatch(new SetAdminPriceDiscount(false));
        }, (error: HttpErrorResponse) => {
            this.view.showError('Atención', validateObservation ? 'Productos sin obseración' : error.error.message);
        });
    }

}
