import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrls: ['./cash-register.component.scss']
})
export class CashRegisterComponent extends AbstractView implements OnInit {

  navLinks = [];
  constructor(
    public router: Router,
    public service: MessageService

  ) {
    super(service, router);
  }
  ngOnInit() {
    this.navLinks = [
      {
        label: 'Reportes de caja',
        link: './open-day-report',
        index: 1,
        visible: this.getAuth('ALLOW_POS_REPORT_CASH_OPENING')
      },
      {
        label: 'Reportes cierre de caja',
        link: './close-day-report',
        index: 2,
        visible: this.getAuth('ALLOW_POS_REPORT_CASH_CLOSE')
      },
      {
        label: 'Depósito venta',
        link: './search-sales-deposit',
        index: 3,
        visible: this.getAuth('ALLOW_POS_REPORT_CASH_CLOSE')
      }];
  }

}
