import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReportCloseDayModel, SystemReportModel, ReportOpenDayModel } from '../models/report.model';
import { DayRegisterModel } from '../models/cash-register.model';
import { InvoiceModel } from '../models/invoice.model';
import { Product } from '../models/product.model';
import { ClientModel } from '../models/client.model';
import { Payment } from '../models/payment.model';
import { ReportSalesPriceModel } from '../models/reportsalesprice.modal';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }



  findDayRegisters(
    searchValue: string, initDate: string, endDate: string,
    page: number, size: number): Promise<ReportCloseDayModel[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ReportCloseDayModel[]>(environment.apiUrl + '/findDayRegisters?initDate=' + initDate + '&endDate=' + endDate
        + '&searchValue=' + searchValue + '&page=' + page + '&size=' + size
        , { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  findOpenDayRegisters(
    searchValue: string, initDate: string, endDate: string,
    page: number, size: number): Promise<ReportOpenDayModel[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ReportOpenDayModel[]>(environment.apiUrl + '/findCashRegisters?initDate=' + initDate + '&endDate=' + endDate
        + '&searchValue=' + searchValue + '&page=' + page + '&size=' + size
        , { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }


  getCreditCardReport(
    clientPresenter: ClientModel, initDate: string, endDate: string,
    documentNo: string, page: number, size: number): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this.http.post<Payment[]>(environment.apiUrl + '/findCreditCard?initDate=' + initDate + '&endDate=' + endDate
        + '&documentNo=' + documentNo + '&page=' + page + '&size=' + size
        , clientPresenter, { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  getSystemReport(dayRegisterId: string): Promise<SystemReportModel> {
    return new Promise((resolve, reject) => {
      this.http.get<SystemReportModel>(environment.apiUrl + '/getSystemDayRegisterDetail?dayRegisterId=' + dayRegisterId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getDailyReport(dayRegisterId: string): Promise<ReportCloseDayModel> {
    return new Promise((resolve, reject) => {
      this.http.get<ReportCloseDayModel>(environment.apiUrl + '/findDayRegister?dayRegisterId=' + dayRegisterId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }


  saveObservation(dayRegisterModel: DayRegisterModel): Promise<DayRegisterModel> {
    return new Promise((resolve, reject) => {
      this.http.post<DayRegisterModel>(environment.apiUrl + '/saveDayRegisterObservation', dayRegisterModel)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  getPdfReport(dayRegisterId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/printDayRegisterReport?dayRegisterId=' + dayRegisterId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }


  getSystemPaymentsAmount(
    initDate: string, endDate: string, userId: string): Promise<SystemReportModel> {
    if (userId) {
      userId = '&userId=' + userId;
    } else {
      userId = '';
    }
    return new Promise((resolve, reject) => {
      this.http.get<SystemReportModel>(environment.apiUrl + '/getSystemPaymentsAmount?initDate=' + initDate + '&endDate=' + endDate
        + userId
        , { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  getInvoices(
    initDate: string, endDate: string, userId: string, page: number, size: number): Promise<InvoiceModel[]> {
    if (userId) {
      userId = '&userId=' + userId;
    } else {
      userId = '';
    }
    return new Promise((resolve, reject) => {
      this.http.get<InvoiceModel[]>(environment.apiUrl + '/getInvoices?initDate=' + initDate + '&endDate=' + endDate
        + '&page=' + page + '&size=' + size
        + userId
        , { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  getInvoiceConsolidado(initDate: string, endDate: string, invoice: number, codeProduct: string, user: string) {
    return this.http.get(environment.apiUrl + '/getReportPricesSales?initDate=' + initDate + '&endDate=' + endDate
    + '&invoice=' + invoice + '&codeProduct=' + codeProduct + '&user=' + user);
  }

  getInvoiceProduct(initDate: string, endDate: string, productId: string) {
    return this.http.get(environment.apiUrl + '/getReportProductHistory?initDate=' + initDate + '&endDate=' + endDate
    + '&productId=' + productId);
  }

  getPaginateProductHistory(initDate: string, endDate: string, productId: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/getPaginateProductHistory?initDate=' + initDate + '&endDate=' + endDate
    + '&productId=' + productId + '&page=' + page + '&size=' + size);
  }

  getInvoiceHeavyOrhardware(initDate: string, endDate: string, invoice: number, codeProduct: string,
                            user: string, category: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/getReportInvoiceHeavyOrHardware?initDate=' + initDate + '&endDate=' + endDate
    + '&invoice=' + invoice + '&codeProduct=' + codeProduct + '&user=' + user + '&category='
    + category + '&page=' + page + '&size=' + size);
  }

  getReportSalesCommission(initDate: string, endDate: string, invoice: number, codeProduct: string,
                           user: string, category: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/getReportSalesCommission?initDate=' + initDate + '&endDate=' + endDate
    + '&invoice=' + invoice + '&codeProduct=' + codeProduct + '&user=' + user + '&category='
    + category + '&page=' + page + '&size=' + size);
  }

  getReportSalesCommissionSumary(initDate: string, endDate: string, invoice: number, codeProduct: string, user: string) {
    return this.http.get(environment.apiUrl + '/getReportSalesCommissionSumary?initDate=' + initDate + '&endDate=' + endDate
    + '&invoice=' + invoice + '&codeProduct=' + codeProduct + '&user=' + user);
  }

  usersByCriterion(searchValue: string, page: number, size: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + '/usersByCriterion?searchValue=' +
        searchValue + '&page=' + page + '&size=' + size
        , { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  printSalesReport(initDate: string, endDate: string, userId: string) {
    if (userId) {
      userId = '&userId=' + userId;
    } else {
      userId = '';
    }
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/printSalesReport?initDate=' + initDate + '&endDate=' + endDate
        + userId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  getTotalAmount(initDate: string, endDate: string, userId: string): Promise<any> {
    if (userId) {
      userId = '&userId=' + userId;
    } else {
      userId = '';
    }
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/getTotalAmount?initDate=' + initDate + '&endDate=' + endDate
        + userId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getCashRegisterResumeById(closeCashRegisterId: string) {
    return this.http.get(environment.apiUrl + '/getPaymentsQuantity?dayRegisterId=' + closeCashRegisterId);
  }

  printOpenCashRegister(cashRegisterId: string) {
    return this.http.get(environment.apiUrl + '/printOpenCashRegister?cashRegisterId=' + cashRegisterId);
  }



  getDaySales(date: string) {
    return this.http.get(environment.apiUrl + '/printDaySales?date=' + date);
  }

  getReportStock(productId: string) {
    return this.http.get(environment.apiUrl + '/stockProduct?productId=' + productId);
  }
  getReportStockValued(productId: string) {
    return this.http.get(environment.apiUrl + '/allDataStockValue?productId=' + productId);
  }
  paginatedProductStock(productId: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/paginatedProductStock?productId=' + productId + '&page=' + page + '&size=' + size);
  }
  paginatedProductStockValued(productId: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/paginatedStockValue?productId=' + productId + '&page=' + page + '&size=' + size);
  }
  getProductKardex(productId: string, initDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/getProductKardex?productId=' + productId +
      '&initDate=' + initDate + '&endDate=' + endDate);
  }
  getProductByReferenceId(productReferenceId: string, initDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/getProductByReferenceId?productReferenceId=' + productReferenceId +
    '&initDate=' + initDate + '&endDate=' + endDate);
  }
  getInvoiceSales(startDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/getInvoiceSales/' + startDate + '/' + endDate);
  }
  paginatedProductStockAcopio(referenceId: string, page: number, pageSize: number, print: boolean) {
    return this.http.get(environment.apiUrl + '/productsStockCollectionCenter?referenceId=' + referenceId + '&page='
      + page + '&pageSize=' + pageSize + '&print=' + print);
  }
  getProductSupplierReport(page: number, size: number, productId: string, searchValue: string, initDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/getReportSupplierProduct?productId=' + productId + '&page=' + page + '&size=' + size
      + '&searchValue=' + searchValue + '&initDate=' + initDate + '&endDate=' + endDate);
  }
}



