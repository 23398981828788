import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Decimal from 'decimal.js';
import { round } from 'src/app/core/utils';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { Bank, GraceMonthsBank, PaymentFees } from 'src/app/models/bank.model';
import {
  CardType,
  CreditCardType,
  CreditDeferredType,
  ProcessType,
  TransactionPresenter,
  TransactionType,
} from 'src/app/models/creditcardtype.model';
import { CreditStatus } from 'src/app/models/order.model';
import { OrderLine } from 'src/app/models/orderline.model';
import { ProcessModel } from 'src/app/models/process.model';
import { IAppState } from 'src/app/reducers/order.reducer';
import { AdvanceService } from 'src/app/services/advance.service';
import { BankService } from 'src/app/services/bank.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { CouponService } from 'src/app/services/coupon.service';
import {
  DefaultconfigurationsService,
  GeneralConfigurations,
} from 'src/app/services/defaultconfigurations.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Presenter } from '../../../../../../core/presenter';
import { BankAccount } from '../../../../../../models/bankaccount.model';
import { ClientModel } from '../../../../../../models/client.model';
import { InvoiceModel } from '../../../../../../models/invoice.model';
import { Payment, PaymentToShow } from '../../../../../../models/payment.model';
import { PaymentMethod } from '../../../../../../models/paymentmethod';
import { RelationTypeBankAccount } from '../../../../../../models/relationaltyebankaccount.model';
import { ClearAdviser } from '../../../../../../reducers/adviser.action';
import { ClearClient } from '../../../../../../reducers/client.action';
import {
  AddPaymentToInvoice,
  ClearInvoice,
  DeletePayment,
} from '../../../../../../reducers/invoice.action';
import {
  ApplyPromotions,
  ClearEditOrder,
  DeleteAllOrderLines,
  SetAdminPriceDiscount,
  SetBilleton,
  SetCreditCardPayment,
  SetObservationInRedux,
} from '../../../../../../reducers/order.action';
import { ClientService } from '../../../../../../services/client.service';
import { DataProvider } from '../../../../../../services/data.provider';
import { OrderService } from '../../../../../../services/order.service';
import { InvoiceBalancePresenter } from '../../invoice-balance/presenter/invoice-balance.presenter';
import { PaymentsDetailView } from '../payments-detail.view';
import { ClearSeller } from 'src/app/reducers/seller.action';
import { ProductQuestPresenter } from '../../product-quest/presenter/product-quest.presenter';

@Injectable({
  providedIn: 'root',
})
export class PaymentsDetailPresenter implements Presenter {
  view: PaymentsDetailView;
  invoice: InvoiceModel = null;
  procesesSelected: ProcessModel;
  creditControl = 0;
  debitControl = 0;
  creditCardPayment: boolean;
  orderLines: OrderLine[] = [];
  paymentsBanksPromo = false;
  businessUnit = '';

  constructor(
    private store: Store<{
      client: ClientModel;
      adviser: ClientModel;
      orderLines: OrderLine;
    }>,
    private clientService: ClientService,
    private bankService: BankService,
    private invoiceService: InvoiceService,
    public advanceService: AdvanceService,
    public settingPresenter: SettingPresenter,
    private orderService: OrderService,
    private dataProvider: DataProvider,
    private defaultconfigurationsService?: DefaultconfigurationsService,
    private creditCardService?: CreditCardService,
    private invoiceBalancePresenter?: InvoiceBalancePresenter,
    private couponService?: CouponService,
    private productQuestPresenter?: ProductQuestPresenter
  ) { }

  init() {
    this.view.isQuotation = false;
    this.view.isLowerPrice = false;
    this.businessUnit = this.dataProvider.defaultWarehouse.businessUnit;
    this.store.pipe(select('orderLines')).subscribe((valor: IAppState) => {
      this.view.subTotal12 = valor.subTotal12;
      this.view.subTotal0 = valor.subTotal0;
      this.view.tax = valor.totalTax;
      this.view.subTotal12 = new Decimal(this.view.subTotal12).toNumber();
      this.view.subTotal0 = new Decimal(this.view.subTotal0).toNumber();
      this.view.tax = new Decimal(this.view.tax).toNumber();
      this.view.subTotal = this.view.subTotal12 + this.view.subTotal0;
      this.view.total = this.view.subTotal + this.view.tax;
      this.procesesSelected = valor.procesesSelected;
      this.creditCardPayment = valor.creditCardPayment;
      this.view.isBilleton = valor.isBilleton;
      this.orderLines = valor.orderLines.filter((line) => line.active);
      if (this.procesesSelected === ProcessModel.INVOICE) {
        this.view.isInvoiceProcess = true;
      } else {
        this.view.isInvoiceProcess = false;
      }
    });
    this.store.pipe(select('client')).subscribe((valor) => {
      this.view.client = null;
      this.view.clientBankAccountSelected = null;
      this.view.clientBankAccounts = [];
      if (valor.client) {
        this.view.client = valor.client;
      }
    });
    this.store.pipe(select('adviser')).subscribe((valor) => {
      this.view.adviser = null;
      if (valor.adviser) {
        this.view.adviser = valor.adviser;
      }
    });
    this.store.pipe(select('seller')).subscribe((valor) => {
      this.view.seller = null;
      if (valor.seller) {
        this.view.seller = valor.seller;
      }
    });
    this.clientService
      .findBankAccount(this.view.client)
      .then((bankAccounts) => {
        this.view.clientBankAccounts = [];
        bankAccounts.forEach((bankAccount) => {
          this.view.clientBankAccounts.push({
            label: bankAccount.accountNumber,
            value: bankAccount,
          });
        });
        if (this.view.clientBankAccounts.length) {
          this.view.clientBankAccountSelected = this.view.clientBankAccounts[0];
        }
      });
    this.bankService.findAllBanks().then((banks) => {
      this.view.banks = [];
      banks.forEach((bank) => {
        this.view.banks.push({
          label: bank.name,
          value: bank,
        });
      });
      if (this.view.banks.length > 0) {
        this.view.bankSelected = this.view.banks[0];
      }
    });
    this.view.relationTypeBankAccounts = [
      { label: 'Personal', value: RelationTypeBankAccount.PERSONAL },
      { label: 'Familia', value: RelationTypeBankAccount.FAMILY },
      { label: 'Otro', value: RelationTypeBankAccount.OTHER },
    ];
    this.view.relationTypeBankAccountSelected =
      this.view.relationTypeBankAccounts[0];
    this.store.pipe(select('invoice')).subscribe((state) => {
      if (state.invoice && state.invoice.orderPresenter && state.invoice.orderPresenter.orderDetailPresenters) {
        state.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
          if (round(element.unitPrice, 4) < round(element.chainPrice, 4)) {
            this.view.isLowerPrice = true;
          }
        });
      } else {
        console.log('El orderPresenter o invoice está undefined o null');
      }

      if (state.invoice && state.invoice.orderPresenter) {
        if (state.invoice.orderPresenter.isQuotation !== undefined) {
          this.view.isQuotation = state.invoice.orderPresenter.isQuotation;
        }
      } else {
        console.log('El orderPresenter o invoice está undefined o null');
      }
      this.view.payments = [];
      if (state && state.invoice) {
        this.invoice = state.invoice;
        this.view.totalInvoice = state.invoice.orderPresenter.total;
        this.view.totalInvoice = new Decimal(this.view.totalInvoice)
          .toDecimalPlaces(2)
          .toNumber();
        let totalPayment = 0;
        this.creditControl = 0;
        this.debitControl = 0;
        this.paymentsBanksPromo = false;
        if (state && state.payments) {
          state.payments.forEach((payment) => {
            this.view.payments.push(payment);
            totalPayment = totalPayment + Number(payment.amount);
            if (payment.paymentMethod === PaymentMethod.CREDIT_CARD) {
              this.creditControl += Number(payment.amount);
              if (!payment.bankPresenter.promoBank) {
                this.paymentsBanksPromo = true;
              }
            }
            if (payment.paymentMethod === PaymentMethod.DEBIT) {
              this.debitControl += Number(payment.amount);
            }
          });
          this.creditControl = new Decimal(this.creditControl).toDecimalPlaces(2).toNumber();
          this.debitControl = new Decimal(this.debitControl).toDecimalPlaces(2).toNumber();
        }
        if (
          (this.view.client.totalAmountAvailable > 0 &&
            this.invoice.orderPresenter.creditStatus ===
            CreditStatus.APPROVED) ||
          this.invoice.orderPresenter.creditStatus ===
          CreditStatus.APPROVED_OVERDRAFT
        ) {
          this.view.paymentsMethods = [
            { label: 'Crédito', value: PaymentMethod.CREDIT },
          ];
          this.view.paymentMethodSelected = {
            label: 'Crédito',
            value: PaymentMethod.CREDIT,
          };
          this.view.amountFee = round(
            this.view.totalInvoice /
            this.view.client.paymentTermPresenter.paymentTermFees,
            2
          );
          this.view.amountFee = round(
            this.view.totalInvoice /
            this.view.client.paymentTermPresenter.paymentTermFees,
            2
          );
          this.view.credit = true;
          this.view.totalPayments = this.view.totalInvoice;
        } else {
          this.setPayments();
        }
        this.view.totalAdvance = round(
          totalPayment - this.view.totalInvoice,
          2
        );
        this.view.pending = round(this.view.totalInvoice - totalPayment, 2);
        this.view.totalPayments = totalPayment;
      } else {
        this.invoice = null;
        this.view.payments = [];
        this.view.totalInvoice = 0;
        this.view.totalAdvance = 0;
        this.view.totalPayments = 0;
      }
    });
  }
  setPayments() {
    const minimumAmountDeferrer = GeneralConfigurations.minimumAmountDeferrer;
    const credit = { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD };
    const debit = { label: 'Tarjeta de Débito', value: PaymentMethod.DEBIT };
    const deuna = { label: 'De Una', value: PaymentMethod.DE_UNA };
    const cash = { label: 'Efectivo', value: PaymentMethod.CASH };
    const check = { label: 'Cheque', value: PaymentMethod.CHECK };
    const creditMemo = { label: 'Nota de Crédito', value: PaymentMethod.CREDIT_MEMO };
    const withHold = { label: 'Retención', value: PaymentMethod.WITHHOLD };
    const bankTransfer = { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER };

    if (GeneralConfigurations.applyPromoDay) {
      this.view.paymentsMethods = [
        // { label: 'Efectivo', value: PaymentMethod.CASH },
        // { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER },
        { label: 'Tarjeta de Débito', value: PaymentMethod.DEBIT },
        { label: 'De Una', value: PaymentMethod.DE_UNA },
      ];
      if (this.view.total > minimumAmountDeferrer) {
        this.view.paymentsMethods.push(credit);
      }
      this.view.cardBanks = null;
      this.view.cardBanks = this.dataProvider.banksSpecialDay;
    } else if (this.dataProvider.blockPaymentsByAdminPrice) {
      this.view.paymentsMethods = [
        { label: 'Efectivo', value: PaymentMethod.CASH },
        { label: 'Retención', value: PaymentMethod.WITHHOLD },
        { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER },
        { label: 'Nota de crédito', value: PaymentMethod.CREDIT_MEMO }
      ];
    } else if (this.invoice.orderPresenter.creditStatus === CreditStatus.APPROVED) {
      this.view.paymentsMethods = [{ label: 'Crédito', value: PaymentMethod.CREDIT }];
      this.view.paymentMethodSelected = { label: 'Crédito', value: PaymentMethod.CREDIT };
      this.view.credit = true;
      this.view.totalPayments = this.view.totalInvoice;
    } else if (this.view.client.dni.valueOf() === '9999999999999') {
      if (!this.invoice.orderPresenter.isCreditCardPayment) {
        this.view.paymentsMethods = [
          { label: 'Efectivo', value: PaymentMethod.CASH },
          { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER }
        ];
      } else {
        this.view.paymentsMethods = [];
        this.view.paymentsMethods.push(credit);
        this.view.paymentsMethods.push(debit);
        this.view.paymentsMethods.push(deuna);
        this.view.paymentsMethods.push(cash);
        this.view.paymentsMethods.push(check);
        this.view.paymentsMethods.push(withHold);
        this.view.paymentsMethods.push(bankTransfer);
        this.view.paymentsMethods.push(creditMemo);
        this.view.paymentMethodSelected = { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD };
      }
    } else {
      if (this.invoice.orderPresenter.isCreditCardPayment || this.dataProvider.processPayment === 'deferred') {
        this.view.paymentsMethods = [];
        this.view.paymentsMethods.push(credit);
        this.view.paymentsMethods.push(debit);
        this.view.paymentsMethods.push(deuna);
        this.view.paymentsMethods.push(cash);
        this.view.paymentsMethods.push(check);
        this.view.paymentsMethods.push(withHold);
        this.view.paymentsMethods.push(bankTransfer);
        this.view.paymentsMethods.push(creditMemo);
        this.view.paymentMethodSelected = { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD };

      } else {
        this.view.paymentsMethods = [
          { label: 'Efectivo', value: PaymentMethod.CASH },
          { label: 'Cheque', value: PaymentMethod.CHECK },
          { label: 'Crédito', value: PaymentMethod.CREDIT },
          { label: 'Retención', value: PaymentMethod.WITHHOLD },
          { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER },
          { label: 'Nota de crédito', value: PaymentMethod.CREDIT_MEMO }
        ];
      }
    }
  }

  evaluatePayemnts() {

    this.view.cardBanks = this.view.cardBanksCopy;
    if (this.view.paymentMethodSelected.value === PaymentMethod.CREDIT_CARD.valueOf()) {
      // this. getBanksByCriteria()
      if (this.dataProvider.banksSpecialDay.length > 0) {
        this.view.showBanksSpecialDay();
      }
      this.view.cardBanks = this.view.cardBanksCopy.filter(option => option.referenceId !== '5637144580').sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    this.view.deUna = false;
    if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CREDIT.valueOf()
    ) {
      if (this.view.client.block) {
        this.invoice.orderPresenter.creditStatus = CreditStatus.NOT_REQUESTED;
        this.view.paymentMethodSelected = {
          label: 'Efectivo',
          value: PaymentMethod.CASH,
        };
        this.view.showWarn(
          'No se puede agregar',
          'Cliente con crédito deshabilitado'
        );
        return;
      }
      if (this.view.client.invoicesOverdue) {
        this.invoice.orderPresenter.creditStatus = CreditStatus.NOT_REQUESTED;
        this.view.paymentMethodSelected = {
          label: 'Efectivo',
          value: PaymentMethod.CASH,
        };
        this.view.showWarn(
          'No se puede agregar',
          'Cliente con facturas vencidas'
        );
        return;
      }
      if (
        this.view.payments.length ||
        (this.view.client && this.view.client.dni === '9999999999999') ||
        (this.view.client &&
          this.view.client.paymentTermPresenter.netDays <= 0) ||
        (this.view.client && this.view.client.totalAmountAvailable <= 0) ||
        this.view.isBilleton ||
        this.creditCardPayment
      ) {
        const message =
          (this.view.client &&
            this.view.client.paymentTermPresenter.netDays <= 0) ||
            (this.view.client && this.view.client.totalAmountAvailable <= 0)
            ? 'Cliente sin Crédito'
            : 'Método no aceptado.';
        this.invoice.orderPresenter.creditStatus = CreditStatus.NOT_REQUESTED;
        this.view.paymentMethodSelected = {
          label: 'Efectivo',
          value: PaymentMethod.CASH,
        };
        this.view.showWarn('No se puede agregar', message);
        return;
      } else {
        this.view.credit = true;
        this.view.isChange = 'Cambio';
        this.view.totalPayments = this.view.totalInvoice;
        this.invoice.orderPresenter.creditStatus = CreditStatus.APPROVED;
        this.view.amountFee = new Decimal(this.view.totalInvoice)
          .div(this.view.client.paymentTermPresenter.paymentTermFees || 0)
          .toDecimalPlaces(2)
          .toNumber();
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.SUBVENTION.valueOf()
    ) {
      this.view.subventionAmount = this.view.pending;
      const differentPayments = this.view.payments.filter(
        (payment) => payment.paymentMethod !== PaymentMethod.CASH
      ).length;
      if (differentPayments) {
        this.view.paymentMethodSelected = {
          label: 'Efectivo',
          value: PaymentMethod.CASH,
        };
        this.view.showWarn('No se puede agregar', 'Método no aceptado');
        this.view.cashAmount = this.view.pending;
        return;
      }
    } else if (
      this.view.paymentMethodSelected.value.valueOf() !==
      PaymentMethod.CASH.valueOf() && this.view.paymentMethodSelected.value.valueOf() !==
      PaymentMethod.DE_UNA.valueOf()
    ) {
      const differentPayments = this.view.payments.filter(
        (payment) => payment.paymentMethod === PaymentMethod.SUBVENTION
      ).length;
      if (differentPayments) {
        this.view.paymentMethodSelected = {
          label: 'Efectivo',
          value: PaymentMethod.CASH,
        };
        this.view.showWarn(
          'No se puede agregar',
          'Método SUBVENCION agregado previamente'
        );
        this.view.cashAmount = this.view.pending;
        return;
      }
      this.view.credit = false;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.DE_UNA.valueOf()
    ) {
      this.view.isChange = 'Cambio';
      this.view.deUna = true;
    } else {
      this.view.deUna = false;
      this.view.credit = false;
    }
  }

  addPaymentCpos() {
    const withoutDispatch = this.view.payments.find(
      (payment) =>
        payment.paymentMethod === PaymentMethod.TOTAL_WITHOUT_DISPATCH
    );
    if (withoutDispatch) {
      this.view.showWarn(
        'Validación',
        'Pago contado sin despacho previamente agregado'
      );
      return;
    }
    if (!this.view.paymentMethodSelected) {
      this.view.showWarn('Validación', 'Seleccionar un método de pago');
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CHECK.valueOf()
    ) {
      let checkNumber = 0;
      let checkConfirmationCode = 0;
      if (!this.view.checkAmount || !(this.view.checkAmount > 0)) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un monto del cheque válido'
        );
        return;
      }
      if (!this.view.checkNumber || this.view.checkNumber.trim().length === 0) {
        this.view.showWarn('Validación', 'Debe ingresar un número de cheque');
        return;
      }
      // tslint:disable-next-line: radix
      checkNumber = parseInt(this.view.checkNumber);
      if (checkNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de cheque ingresado incorrecto'
        );
        return;
      }
      if (
        !this.view.checkConfirmationCode ||
        this.view.checkConfirmationCode.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de confirmación para el cheque'
        );
        return;
      }
      // tslint:disable-next-line: radix
      checkConfirmationCode = parseInt(this.view.checkConfirmationCode);
      if (checkConfirmationCode <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de confirmación ingresado incorrecto'
        );
        return;
      }
      const actualDate: Date = new Date();
      actualDate.setHours(0, 0, 0, 0);
      if (!this.view.effectiveDate) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar una fecha de efectivización para el cheque'
        );
        return;
      } else if (!(this.view.effectiveDate.getTime() >= actualDate.getTime())) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar una fecha de efectivización igual o superior a la actual'
        );
        return;
      }
      if (!this.view.clientBankAccountSelected) {
        this.view.showWarn(
          'Validación',
          'Debe seleccionar una cuenta bancaria del cliente'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = ' ';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const format = 'yyyy-MM-dd hh:mm:ss';
      const locale = 'en-US';
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CHECK,
        referenceNumber: this.view.checkNumber,
        confirmationCode: this.view.checkConfirmationCode,
        amount: this.view.checkAmount,
        bankAccountPresenter: this.view.clientBankAccountSelected.value,
        effectiveDate: formatDate(this.view.effectiveDate, format, locale),
        name: 'Cheque',
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.checkAmount = null;
      this.view.checkNumber = null;
      this.view.checkConfirmationCode = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CASH.valueOf()
    ) {
      if (!this.view.cashAmount || !(this.view.cashAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.dataProvider.isCrediRedPrice && !this.view.isEntryByCredired) {
        this.view.showWarn('', 'No tiene activada la opción de entrada para pago de credired');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CASH,
        referenceNumber: null,
        amount: this.view.cashAmount,
        bankAccountPresenter: null,
        name: 'Efectivo',
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.cashAmount = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CREDIT_CARD.valueOf()
    ) {
      if (!this.view.creditCardAmount || !(this.view.creditCardAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.view.creditCardAmount > this.view.totalInvoice) {
        this.view.showWarn(
          'Validación',
          'El monto no puede ser mayor al total de la factura'
        );
        return;
      }
      const value = new Decimal(this.debitControl || 0)
        .add(this.creditControl || 0)
        .add(this.view.creditCardAmount || 0)
        .toDecimalPlaces(2)
        .toNumber();
      if (value > this.view.totalInvoice) {
        this.view.showWarn(
          'Validación',
          'El valor entre tarjetas de crédito y débito no puede ser mayor al total de la factura'
        );
        return;
      }
      if (
        this.view.client.dni.valueOf() === '9999999999999' &&
        this.creditControl + Number(this.view.creditCardAmount) >
        this.settingPresenter.defaultconfigurationsService
          .defaultMaxCreditDebitFinalConsumerAmount
      ) {
        this.view.showError(
          '',
          'Consumidor final no puede exceder $' +
          this.settingPresenter.defaultconfigurationsService
            .defaultMaxCreditDebitFinalConsumerAmount
        );
        return;
      }
      if (
        !(this.view.creditBankSelected && this.view.creditBankSelected.bankId)
      ) {
        this.view.showWarn('', 'Debe seleccionar un banco');
        return;
      }
      if (!this.view.creditCardSelected) {
        this.view.showWarn('', 'Debe seleccionar una tarjeta de crédito');
        return;
      }
      if (!this.view.creditCardTypeSelected) {
        this.view.showWarn('', 'Debe seleccionar tipo de crédito');
        return;
      }
      if ((this.dataProvider.isConfigurationCpos && this.dataProvider.isConnectionCpos) && !this.view.creditDeferredTypeSelected) {
        this.view.showWarn('', 'Debe seleccionar tipo de diferido');
        return;
      }
      if (!this.view.paymentFeeSelected) {
        this.view.showWarn('', 'Debe seleccionar el numero de cuotas');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.showConfirmCreditCardPayment();
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.BANK_TRANSFER.valueOf()
    ) {
      let transferNumber = 0;
      if (!this.view.transferAmount || !(this.view.transferAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.dataProvider.isCrediRedPrice && !this.view.isEntryByCredired) {
        this.view.showWarn('', 'No tiene activada la opción de entrada para pago de credired');
        return;
      }
      if (
        !this.view.transferNumber ||
        this.view.transferNumber.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de transferencia'
        );
        return;
      }
      // tslint:disable-next-line: radix
      transferNumber = parseInt(this.view.transferNumber);
      if (transferNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de transferencia ingresado incorrecto'
        );
        return;
      }
      if (!this.view.selectedBank) {
        this.view.showWarn('Validación', 'Debe  seleccionar un banco.');
        return;
      }
      if (!this.view.bankAccountSelected) {
        this.view.showWarn(
          'Validación',
          'Debe  seleccionar una cuenta bancaria.'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.BANK_TRANSFER,
        referenceNumber: this.view.transferNumber,
        amount: this.view.transferAmount,
        name: 'Transferencia bancaria',
        bankAccountPresenter: this.view.bankAccountSelected,
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.transferNumber = null;
      this.view.transferAmount = null;
      this.view.selectedBank = null;
      this.view.bankAccountSelected = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.WITHHOLD.valueOf()
    ) {
      let withholdNumber = 0;
      if (!this.view.withholdAmount || !(this.view.withholdAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (
        !this.view.withholdNumber ||
        this.view.withholdNumber.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de documento'
        );
        return;
      }
      // tslint:disable-next-line: radix
      withholdNumber = parseInt(this.view.withholdNumber);
      if (withholdNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de documento ingresado incorrecto'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.withholdNumber = this.view.withholdNumber.split('-').join('');
      const withholdPayemnt: PaymentToShow = {
        paymentMethod: PaymentMethod.WITHHOLD,
        referenceNumber: this.view.withholdNumber,
        amount: this.view.withholdAmount,
        name: 'Retención',
      };
      this.store.dispatch(new AddPaymentToInvoice(withholdPayemnt));
      this.view.withholdNumber = null;
      this.view.withholdAmount = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CREDIT_MEMO.valueOf()
    ) {
      let creditMemoNumber = 0;
      if (!this.view.creditMemoAmount || !(this.view.creditMemoAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (
        !this.view.creditMemoNumber ||
        this.view.creditMemoNumber.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de documento'
        );
        return;
      }
      // tslint:disable-next-line: radix
      creditMemoNumber = parseInt(this.view.creditMemoNumber);
      if (creditMemoNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de documento ingresado incorrecto'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.creditMemoNumber = this.view.creditMemoNumber
        .split('-')
        .join('');
      const creditMemoPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CREDIT_MEMO,
        referenceNumber: this.view.creditMemoNumber,
        amount: this.view.creditMemoAmount,
        name: 'Nota de crédito',
      };
      this.store.dispatch(new AddPaymentToInvoice(creditMemoPayment));
      this.view.creditMemoNumber = null;
      this.view.creditMemoAmount = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.DEBIT.valueOf()
    ) {
      /* DEBIT */
      if (!this.view.debitCardAmount || !(this.view.debitCardAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.view.debitCardAmount > this.view.totalInvoice) {
        this.view.showWarn(
          'Validación',
          'El monto no puede ser mayor al total de la factura'
        );
        return;
      }
      const value = new Decimal(this.debitControl || 0)
        .add(this.creditControl || 0)
        .add(this.view.creditCardAmount || 0)
        .toDecimalPlaces(2)
        .toNumber();
      if (value > this.view.totalInvoice) {
        this.view.showWarn(
          'Validación',
          'El valor entre tarjetas de crédito y débito no puede ser mayor al total de la factura'
        );
        return;
      }
      if (
        this.view.client.dni.valueOf() === '9999999999999' &&
        this.debitControl + Number(this.view.debitCardAmount) >
        this.settingPresenter.defaultconfigurationsService
          .defaultMaxCreditDebitFinalConsumerAmount
      ) {
        this.view.showError(
          '',
          'Consumidor final no puede exceder $' +
          this.settingPresenter.defaultconfigurationsService
            .defaultMaxCreditDebitFinalConsumerAmount
        );
        return;
      }
      if (!this.view.debitBankSelected) {
        this.view.showWarn('Validación', 'Debe seleccionar un banco');
        return;
      }
      if (!this.view.debitCardSelected) {
        this.view.showWarn('Validación', 'Debe seleccionar una tarjeta');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.showConfirmDebitCardPayment();
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.TOTAL_WITHOUT_DISPATCH.valueOf()
    ) {
      if (this.view.payments.length) {
        this.view.showWarn(
          'Validación',
          'Este pago debe ser único y por la totalidad de la factura'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const payment: PaymentToShow = {
        paymentMethod: PaymentMethod.TOTAL_WITHOUT_DISPATCH,
        amount: round(this.view.totalInvoice, 2),
        name: 'TOTAL FACTURA SIN DESPACHO',
      };
      this.store.dispatch(new AddPaymentToInvoice(payment));
      this.view.showSuccess('', 'Pago agregado');
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.SUBVENTION.valueOf()
    ) {
      const differentPayments = this.view.payments.filter(
        (payment) => payment.paymentMethod === PaymentMethod.SUBVENTION
      ).length;
      if (differentPayments) {
        this.view.paymentMethodSelected = {
          label: 'Efectivo',
          value: PaymentMethod.CASH,
        };
        this.view.showWarn(
          'No se puede agregar',
          'Método SUBVENCION previamente agregado'
        );
        this.view.cashAmount = this.view.pending;
        return;
      }
      if (!this.view.subventionAmount || !(this.view.subventionAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const subvention: PaymentToShow = {
        paymentMethod: PaymentMethod.SUBVENTION,
        referenceNumber: null,
        amount: this.view.subventionAmount,
        bankAccountPresenter: null,
        name: 'Subvención',
      };
      this.store.dispatch(new AddPaymentToInvoice(subvention));
      this.view.subventionAmount = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CREDIRED.valueOf()
    ) {
      if (this.view.isEntryByCredired &&
        !(this.view.payments.filter(e => (e.paymentMethod.valueOf() === PaymentMethod.CASH.valueOf()) ||
          (e.paymentMethod.valueOf() === PaymentMethod.BANK_TRANSFER.valueOf())).length > 0)) {
        this.view.showError(
          'Validación',
          'No a ingresado un pago de entrada (Efectivo / Transferencia)'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      if (this.view.crediRedCode === '') {
        this.view.showError('', 'Verificar el número de cuotas, e ingresar la solicitud Credired' + codesProducts);
        return;
      }
      if (this.view.pending === 0) {
        this.view.showError('', 'Ya se encuentra abonada la factura totalmente, proceda a facturar');
        return;
      }
      const crediredPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CREDIRED,
        referenceNumber: this.view.crediRedCode,
        amount: this.view.pending,
        bankAccountPresenter: null,
        name: 'Credired',
        paymentFees: this.view.selectedQuotesCredired['value'],
      };
      this.store.dispatch(new AddPaymentToInvoice(crediredPayment));
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.DE_UNA.valueOf()
    ) {
      let transferNumber = 0;
      if (!this.view.transferAmount || !(this.view.transferAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.view.transferAmount > this.view.totalInvoice) {
        this.view.showWarn(
          'Validación',
          'El monto no puede ser mayor al total de la factura'
        );
        return;
      }
      if (
        !this.view.transferNumber ||
        this.view.transferNumber.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de transacción'
        );
        return;
      }
      // tslint:disable-next-line: radix
      transferNumber = parseInt(this.view.transferNumber);
      if (transferNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de transacción ingresado incorrecto'
        );
        return;
      }
      // if (!this.view.selectedBank) {
      //   this.view.showWarn('Validación', 'Debe  seleccionar un banco.');
      //   return;
      // }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.DE_UNA,
        referenceNumber: this.view.transferNumber,
        amount: this.view.transferAmount,
        name: 'De Una',
        bankAccountPresenter: this.view.bankAccountSelected,
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.transferNumber = null;
      this.view.transferAmount = null;
      this.view.selectedBank = null;
      this.view.bankAccountSelected = null;
    }
  }

  addPayment() {
    const withoutDispatch = this.view.payments.find(payment => payment.paymentMethod === PaymentMethod.TOTAL_WITHOUT_DISPATCH);
    if (withoutDispatch) {
      this.view.showWarn('Validación', 'Pago contado sin despacho previamente agregado');
      return;
    }
    if (!this.view.paymentMethodSelected) {
      this.view.showWarn('Validación', 'Seleccionar un método de pago');
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.CHECK.valueOf()) {
      let checkNumber = 0;
      let checkConfirmationCode = 0;
      if (!this.view.checkAmount || !(this.view.checkAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto del cheque válido');
        return;
      }
      if (!this.view.checkNumber || this.view.checkNumber.trim().length === 0) {
        this.view.showWarn('Validación', 'Debe ingresar un número de cheque');
        return;
      }
      // tslint:disable-next-line: radix
      checkNumber = parseInt(this.view.checkNumber);
      if (checkNumber <= 0) {
        this.view.showWarn('Validación', 'Número de cheque ingresado incorrecto');
        return;
      }
      if (!this.view.checkConfirmationCode || this.view.checkConfirmationCode.trim().length === 0) {
        this.view.showWarn('Validación', 'Debe ingresar un número de confirmación para el cheque');
        return;
      }
      // tslint:disable-next-line: radix
      checkConfirmationCode = parseInt(this.view.checkConfirmationCode);
      if (checkConfirmationCode <= 0) {
        this.view.showWarn('Validación', 'Número de confirmación ingresado incorrecto');
        return;
      }
      const actualDate: Date = new Date();
      actualDate.setHours(0, 0, 0, 0);
      if (!this.view.effectiveDate) {
        this.view.showWarn('Validación',
          'Debe ingresar una fecha de efectivización para el cheque');
        return;
      } else if (!(this.view.effectiveDate.getTime() >= actualDate.getTime())) {
        this.view.showWarn('Validación',
          'Debe ingresar una fecha de efectivización igual o superior a la actual');
        return;
      }
      if (!this.view.clientBankAccountSelected) {
        this.view.showWarn('Validación',
          'Debe seleccionar una cuenta bancaria del cliente');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const format = 'yyyy-MM-dd hh:mm:ss';
      const locale = 'en-US';
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CHECK,
        referenceNumber: this.view.checkNumber,
        confirmationCode: this.view.checkConfirmationCode,
        amount: this.view.checkAmount,
        bankAccountPresenter: this.view.clientBankAccountSelected.value,
        effectiveDate: formatDate(this.view.effectiveDate, format, locale),
        name: 'Cheque',
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.checkAmount = null;
      this.view.checkNumber = null;
      this.view.checkConfirmationCode = null;
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.CASH.valueOf()) {
      if (!this.view.cashAmount || !(this.view.cashAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.dataProvider.isCrediRedPrice && !this.view.isEntryByCredired) {
        this.view.showWarn('', 'No tiene activada la opción de entrada para pago de credired');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CASH,
        referenceNumber: null,
        amount: this.view.cashAmount,
        bankAccountPresenter: null,
        name: 'Efectivo'
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.cashAmount = null;
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.CREDIT_CARD.valueOf()) {
      let creditLoteDiferent0 = 0;
      let creditReferenceDiferent0 = 0;
      let creditAuthDiferent0 = 0;
      if (!this.view.creditCardAmount || !(this.view.creditCardAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.view.creditCardAmount > this.view.totalInvoice) {
        this.view.showWarn('Validación', 'El monto no puede ser mayor al total de la factura');
        return;
      }
      const value = new Decimal(this.debitControl || 0)
        .add(this.creditControl || 0)
        .add(this.view.creditCardAmount || 0)
        .toDecimalPlaces(2).toNumber();
      if (value > this.view.totalInvoice) {
        this.view.showWarn('Validación', 'El valor entre tarjetas de crédito y débito no puede ser mayor al total de la factura');
        return;
      }
      if (this.view.client.dni.valueOf() === '9999999999999' &&
        ((this.creditControl + Number(this.view.creditCardAmount)) >
          this.settingPresenter.defaultconfigurationsService.defaultMaxCreditDebitFinalConsumerAmount)) {
        this.view.showError('', 'Consumidor final no puede exceder $'
          + this.settingPresenter.defaultconfigurationsService.defaultMaxCreditDebitFinalConsumerAmount);
        return;
      }
      if (!(this.view.creditBankSelected && this.view.creditBankSelected.bankId)) {
        this.view.showWarn('', 'Debe seleccionar un banco');
        return;
      }
      if (!this.view.creditCardSelected) {
        this.view.showWarn('', 'Debe seleccionar una tarjeta de crédito');
        return;
      }
      if (!this.view.creditCardTypeSelected) {
        this.view.showWarn('', 'Debe seleccionar tipo de crédito');
        return;
      }
      if (!this.view.paymentFeeSelected) {
        this.view.showWarn('', 'Debe seleccionar el numero de cuotas');
        return;
      }
      if (!this.view.creditLote || this.view.creditLote.length < 4) {
        this.view.showWarn('Validación', 'Debe ingresar un lote correcto');
        return;
      }
      // tslint:disable-next-line: radix
      creditLoteDiferent0 = parseInt(this.view.creditLote);
      if (creditLoteDiferent0 <= 0) {
        this.view.showWarn('Validación', 'Lote ingresado incorrecto');
        return;
      }

      if (!this.view.creditReference || this.view.creditReference.length < 8) {
        this.view.showWarn('Validación', 'Debe ingresar una referencia correcta');
        return;
      }
      // tslint:disable-next-line: radix
      creditReferenceDiferent0 = parseInt(this.view.creditReference);
      if (creditReferenceDiferent0 <= 0) {
        this.view.showWarn('Validación', 'Referencia ingresada incorrecta');
        return;
      }
      if (!this.view.creditAuth || this.view.creditAuth.length < 4) {
        this.view.showWarn('Validación',
          'Debe ingresar una autorización correcta');
        return;
      }
      // tslint:disable-next-line: radix
      creditAuthDiferent0 = parseInt(this.view.creditAuth);
      if (creditAuthDiferent0 <= 0) {
        this.view.showWarn('Validación', 'Autorización ingresada incorrecta');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.creditLote = this.getPadStart(this.view.creditLote, '0', 16);
      this.view.creditReference = this.getPadStart(this.view.creditReference, '0', 16);
      this.view.creditAuth = this.getPadStart(this.view.creditAuth, '0', 8);
      this.view.voucherNumber = this.view.creditLote.concat('-', this.view.creditReference, '-', this.view.creditAuth);

      const creditCartPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CREDIT_CARD,
        referenceNumber: this.view.voucherNumber,
        amount: this.view.creditCardAmount,
        cardPresenter: this.view.creditCardSelected,
        name: 'Tarjeta de crédito',
        bankPresenter: this.view.creditBankSelected,
        creditType: this.view.creditCardTypeSelected,
        paymentFees: this.view.paymentFeeSelected.number,
        graceMonths: this.view.selectedGrace ? this.view.selectedGrace.number : 0
      };
      this.store.dispatch(new AddPaymentToInvoice(creditCartPayment));
      this.view.voucherNumber = null;
      this.view.creditCardAmount = null;
      this.view.creditEntitySelected = null;
      this.view.creditLote = null;
      this.view.creditReference = null;
      this.view.creditAuth = null;
      this.view.selectedCreditCardBank = null;
      this.view.creditCardTypeSelected = null;
      this.view.paymentFeeSelected = null;
      this.view.creditBankSelected = null;
      this.view.creditCardSelected = null;
      this.view.selectedGrace = null;

    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.BANK_TRANSFER.valueOf()) {
      let transferNumber = 0;
      if (!this.view.transferAmount || !(this.view.transferAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (!this.view.transferNumber || this.view.transferNumber.trim().length === 0) {
        this.view.showWarn('Validación', 'Debe ingresar un número de transferencia');
        return;
      }
      // tslint:disable-next-line: radix
      transferNumber = parseInt(this.view.transferNumber);
      if (transferNumber <= 0) {
        this.view.showWarn('Validación', 'Número de transferencia ingresado incorrecto');
        return;
      }
      if (!this.view.selectedBank) {
        this.view.showWarn('Validación', 'Debe  seleccionar un banco.');
        return;
      }
      if (!this.view.bankAccountSelected) {
        this.view.showWarn('Validación', 'Debe  seleccionar una cuenta bancaria.');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.BANK_TRANSFER,
        referenceNumber: this.view.transferNumber,
        amount: this.view.transferAmount,
        name: 'Transferencia bancaria',
        bankAccountPresenter: this.view.bankAccountSelected
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.transferNumber = null;
      this.view.transferAmount = null;
      this.view.selectedBank = null;
      this.view.bankAccountSelected = null;
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.WITHHOLD.valueOf()) {
      let withholdNumber = 0;
      if (!this.view.withholdAmount || !(this.view.withholdAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.dataProvider.isCrediRedPrice && !this.view.isEntryByCredired) {
        this.view.showWarn('', 'No tiene activada la opción de entrada para pago de credired');
        return;
      }
      if (!this.view.withholdNumber || this.view.withholdNumber.trim().length === 0) {
        this.view.showWarn('Validación', 'Debe ingresar un número de documento');
        return;
      }
      // tslint:disable-next-line: radix
      withholdNumber = parseInt(this.view.withholdNumber);
      if (withholdNumber <= 0) {
        this.view.showWarn('Validación', 'Número de documento ingresado incorrecto');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.withholdNumber = this.view.withholdNumber.split('-').join('');
      const withholdPayemnt: PaymentToShow = {
        paymentMethod: PaymentMethod.WITHHOLD,
        referenceNumber: this.view.withholdNumber,
        amount: this.view.withholdAmount,
        name: 'Retención'
      };
      this.store.dispatch(new AddPaymentToInvoice(withholdPayemnt));
      this.view.withholdNumber = null;
      this.view.withholdAmount = null;
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.CREDIT_MEMO.valueOf()) {
      let creditMemoNumber = 0;
      if (!this.view.creditMemoAmount || !(this.view.creditMemoAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (!this.view.creditMemoNumber || this.view.creditMemoNumber.trim().length === 0) {
        this.view.showWarn('Validación', 'Debe ingresar un número de documento');
        return;
      }
      // tslint:disable-next-line: radix
      creditMemoNumber = parseInt(this.view.creditMemoNumber);
      if (creditMemoNumber <= 0) {
        this.view.showWarn('Validación', 'Número de documento ingresado incorrecto');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.creditMemoNumber = this.view.creditMemoNumber.split('-').join('');
      const creditMemoPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CREDIT_MEMO,
        referenceNumber: this.view.creditMemoNumber,
        amount: this.view.creditMemoAmount,
        name: 'Nota de crédito'
      };
      this.store.dispatch(new AddPaymentToInvoice(creditMemoPayment));
      this.view.creditMemoNumber = null;
      this.view.creditMemoAmount = null;
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.DEBIT.valueOf()) {
      let debitLoteDiferent0 = 0;
      let debitReferenceDiferent0 = 0;
      let debitAuthDiferent0 = 0;
      if (!this.view.debitCardAmount || !(this.view.debitCardAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.view.debitCardAmount > this.view.totalInvoice) {
        this.view.showWarn('Validación', 'El monto no puede ser mayor al total de la factura');
        return;
      }
      const value = new Decimal(this.debitControl || 0)
        .add(this.creditControl || 0)
        .add(this.view.creditCardAmount || 0)
        .toDecimalPlaces(2).toNumber();
      if (value > this.view.totalInvoice) {
        this.view.showWarn('Validación', 'El valor entre tarjetas de crédito y débito no puede ser mayor al total de la factura');
        return;
      }
      if (this.view.client.dni.valueOf() === '9999999999999' &&
        ((this.debitControl + Number(this.view.debitCardAmount)) >
          this.settingPresenter.defaultconfigurationsService.defaultMaxCreditDebitFinalConsumerAmount)) {
        this.view.showError('', 'Consumidor final no puede exceder $'
          + this.settingPresenter.defaultconfigurationsService.defaultMaxCreditDebitFinalConsumerAmount);
        return;
      }
      if (!this.view.debitBankSelected) {
        this.view.showWarn('Validación',
          'Debe seleccionar un banco');
        return;
      }
      if (!this.view.debitCardSelected) {
        this.view.showWarn('Validación',
          'Debe seleccionar una tarjeta');
        return;
      }
      if (!this.view.debitLote || this.view.debitLote.length < 4) {
        this.view.showWarn('Validación',
          'Debe ingresar un lote correcto');
        return;
      }
      // tslint:disable-next-line: radix
      debitLoteDiferent0 = parseInt(this.view.debitLote);
      if (debitLoteDiferent0 <= 0) {
        this.view.showWarn('Validación', 'Lote ingresado incorrecto');
        return;
      }
      if (!this.view.debitReference || this.view.debitReference.length < 8) {
        this.view.showWarn('Validación',
          'Debe ingresar una referencia correcta');
        return;
      }
      // tslint:disable-next-line: radix
      debitReferenceDiferent0 = parseInt(this.view.debitReference);
      if (debitReferenceDiferent0 <= 0) {
        this.view.showWarn('Validación', 'Referencia ingresada incorrecta');
        return;
      }
      if (!this.view.debitAuth || this.view.debitAuth.length < 4) {
        this.view.showWarn('Validación',
          'Debe ingresar una autorización correcta');
        return;
      }
      // tslint:disable-next-line: radix
      debitAuthDiferent0 = parseInt(this.view.debitAuth);
      if (debitAuthDiferent0 <= 0) {
        this.view.showWarn('Validación', 'Referencia ingresada incorrecta');
        return;
      }

      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      this.view.debitLote = this.getPadStart(this.view.debitLote, '0', 16);
      this.view.debitReference = this.getPadStart(this.view.debitReference, '0', 16);
      this.view.debitAuth = this.getPadStart(this.view.debitAuth, '0', 8);
      this.view.voucherNumberDebit = this.view.debitLote.concat('-', this.view.debitReference, '-', this.view.debitAuth);

      const debitCartPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.DEBIT,
        referenceNumber: this.view.voucherNumberDebit,
        amount: this.view.debitCardAmount,
        cardPresenter: this.view.debitCardSelected,
        bankPresenter: this.view.debitBankSelected,
        name: 'Tarjeta de débito'
      };
      this.store.dispatch(new AddPaymentToInvoice(debitCartPayment));
      this.view.voucherNumberDebit = null;
      this.view.debitCardAmount = null;
      this.view.debitEntitySelected = null;
      this.view.debitLote = null;
      this.view.debitReference = null;
      this.view.debitAuth = null;
      this.view.selectedDebitCardBank = null;
      this.view.debitBankSelected = null;
      this.view.debitCardSelected = null;
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.TOTAL_WITHOUT_DISPATCH.valueOf()) {
      if (this.view.payments.length) {
        this.view.showWarn('Validación', 'Este pago debe ser único y por la totalidad de la factura');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const payment: PaymentToShow = {
        paymentMethod: PaymentMethod.TOTAL_WITHOUT_DISPATCH,
        amount: round(this.view.totalInvoice, 2),
        name: 'TOTAL FACTURA SIN DESPACHO'
      };
      this.store.dispatch(new AddPaymentToInvoice(payment));
      this.view.showSuccess('', 'Pago agregado');
    } else if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.SUBVENTION.valueOf()) {
      const differentPayments = this.view.payments.filter(payment => payment.paymentMethod === PaymentMethod.SUBVENTION).length;
      if (differentPayments) {
        this.view.paymentMethodSelected = { label: 'Efectivo', value: PaymentMethod.CASH };
        this.view.showWarn('No se puede agregar', 'Método SUBVENCION previamente agregado');
        this.view.cashAmount = this.view.pending;
        return;
      }
      if (!this.view.subventionAmount || !(this.view.subventionAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const subvention: PaymentToShow = {
        paymentMethod: PaymentMethod.SUBVENTION,
        referenceNumber: null,
        amount: this.view.subventionAmount,
        bankAccountPresenter: null,
        name: 'Subvención'
      };
      this.store.dispatch(new AddPaymentToInvoice(subvention));
      this.view.subventionAmount = null;
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CREDIRED.valueOf()
    ) {
      if (this.view.isEntryByCredired &&
        !(this.view.payments.filter(e => (e.paymentMethod.valueOf() === PaymentMethod.CASH.valueOf()) ||
          (e.paymentMethod.valueOf() === PaymentMethod.BANK_TRANSFER.valueOf())).length > 0)) {
        this.view.showError(
          'Validación',
          'No a ingresado un pago de entrada (Efectivo / Transferencia)'
        );
        return;
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      if (this.view.crediRedCode === '') {
        this.view.showError('', 'Verificar el número de cuotas, e ingresar la solicitud Credired' + codesProducts);
        return;
      }
      if (this.view.pending === 0) {
        this.view.showError('', 'Ya se encuentra abonada la factura totalmente, proceda a facturar');
        return;
      }
      const crediredPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CREDIRED,
        referenceNumber: this.view.crediRedCode,
        amount: this.view.pending,
        bankAccountPresenter: null,
        name: 'Credired',
        paymentFees: this.view.selectedQuotesCredired['value'],
      };
      this.store.dispatch(new AddPaymentToInvoice(crediredPayment));
    } else if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.DE_UNA.valueOf()
    ) {
      let transferNumber = 0;
      if (!this.view.transferAmount || !(this.view.transferAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.view.transferAmount > this.view.totalInvoice) {
        this.view.showWarn(
          'Validación',
          'El monto no puede ser mayor al total de la factura'
        );
        return;
      }
      if (
        !this.view.transferNumber ||
        this.view.transferNumber.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de transacción'
        );
        return;
      }
      // tslint:disable-next-line: radix
      transferNumber = parseInt(this.view.transferNumber);
      if (transferNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de transacción ingresado incorrecto'
        );
        return;
      }
      // if (!this.view.selectedBank) {
      //   this.view.showWarn('Validación', 'Debe  seleccionar un banco.');
      //   return;
      // }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.DE_UNA,
        referenceNumber: this.view.transferNumber,
        amount: this.view.transferAmount,
        name: 'De Una',
        bankAccountPresenter: this.view.bankAccountSelected,
      };
      this.store.dispatch(new AddPaymentToInvoice(checkPayment));
      this.view.transferNumber = null;
      this.view.transferAmount = null;
      this.view.selectedBank = null;
      this.view.bankAccountSelected = null;
    }
  }
  addCreditCArdPayment() {
    const baseAmountWithTaxes = new Decimal(
      new Decimal(this.view.creditCardAmount).mul(this.view.subTotal12)
    )
      .div(this.view.totalInvoice)
      .toDecimalPlaces(2)
      .toNumber();
    let transactionType: TransactionType = TransactionType.DEFERRED_TRANSACTION;
    let creditCardType: CreditCardType = null;
    if (this.creditCardPayment) {
      if (
        this.view.creditDeferredTypeSelected ===
        CreditDeferredType.NORMAL_OR_CURRENT
      ) {
        transactionType = TransactionType.CURRENT_TRANSACTION;
      }
    }
    if (this.creditCardPayment) {
      if (this.view.creditCardTypeSelected === CreditCardType.CURRENT_PAYMENT) {
        creditCardType = CreditCardType.CURRENT_PAYMENT;
      } else if (
        this.view.creditCardTypeSelected === CreditCardType.CREDIT_WITH_INTEREST
      ) {
        creditCardType = CreditCardType.CREDIT_WITH_INTEREST;
      } else {
        creditCardType = CreditCardType.CREDIT_WITHOUT_INTEREST;
      }
    } else {
      creditCardType = CreditCardType.CREDIT_WITHOUT_INTEREST;
    }
    const transactionPresenter: TransactionPresenter = {
      transactionType: transactionType,
      processType: ProcessType.PAYMENT_PROCESS,
      creditDeferredType: this.view.creditDeferredTypeSelected,
      deferredMonthTime: this.view.paymentFeeSelected.number,
      monthGrace: this.view.selectedGrace ? this.view.selectedGrace.number : 0,
      totalAmount: this.view.creditCardAmount,
      iva: 0,
      cardType: CardType.CREDIT_CARD,
      creditCardType: creditCardType,
      userId: this.dataProvider.defaultUser.userId,
      clientId: this.invoice.orderPresenter.clientPresenter.clientId,
      warehouseId: this.dataProvider.defaultWarehouse.warehouseId,
      baseAmountWithTaxes: baseAmountWithTaxes,
      baseAmountWithoutTaxes: new Decimal(
        new Decimal(this.view.creditCardAmount).mul(this.view.subTotal0)
      )
        .div(this.view.totalInvoice)
        .toDecimalPlaces(2)
        .toNumber(),
      transactionTax: new Decimal(baseAmountWithTaxes)
        .mul(GeneralConfigurations.defaultTax)
        .toDecimalPlaces(2)
        .toNumber(),
      bankPresenter: this.view.creditBankSelected
    };
    this.view.blockCard = true;
    this.view.blockUi();
    this.creditCardService.addPayment(transactionPresenter).subscribe(
      (transaction: TransactionPresenter) => {
        const response = transaction.responseTransactionPresenter;
        const lote = this.getPadStart(response.lotNumber, '0', 16);
        const reference = this.getPadStart(
          response.transactionReferenceSequence,
          '0',
          16
        );
        const auth = this.getPadStart(
          response.authorizeNumberTransaction,
          '0',
          8
        );
        const voucherNumber = lote.concat('-', reference, '-', auth);
        const creditCartPayment: PaymentToShow = {
          paymentMethod: PaymentMethod.CREDIT_CARD,
          referenceNumber: voucherNumber,
          amount: this.view.creditCardAmount,
          cardPresenter: this.view.creditCardSelected,
          name: 'Tarjeta de crédito',
          bankPresenter: this.view.creditBankSelected,
          creditType: this.view.creditCardTypeSelected,
          paymentFees: this.view.paymentFeeSelected.number,
          graceMonths: this.view.selectedGrace
            ? this.view.selectedGrace.number
            : 0,
          auth: response.authorizeNumberTransaction,
        };
        this.store.dispatch(new AddPaymentToInvoice(creditCartPayment));
        this.view.voucherNumber = null;
        this.view.creditCardAmount = null;
        this.view.creditEntitySelected = null;
        this.view.creditLote = null;
        this.view.creditReference = null;
        this.view.creditAuth = null;
        this.view.selectedCreditCardBank = null;
        this.view.creditCardTypeSelected = null;
        this.view.paymentFeeSelected = null;
        this.view.creditBankSelected = null;
        this.view.creditCardSelected = null;
        this.view.selectedGrace = null;
        this.view.creditDeferredTypeSelected = null;
        this.view.blockCard = false;
        this.view.showSuccess('', 'Transacción generada correctamente');
        this.view.showPdf(response);
      },
      (error: HttpErrorResponse) => {
        this.view.showError('Error al  agregar el pago', error.error.message);
        this.view.blockCard = false;
      }
    );
  }
  /* DEBIT CARD -- */
  addDebitCardPayment() {
    const baseAmountWithTaxes = new Decimal(
      new Decimal(this.view.debitCardAmount).mul(this.view.subTotal12)
    )
      .div(this.view.totalInvoice)
      .toDecimalPlaces(2)
      .toNumber();
    const transactionPresenter: TransactionPresenter = {
      transactionType: TransactionType.CURRENT_TRANSACTION,
      processType: ProcessType.PAYMENT_PROCESS,
      creditDeferredType: CreditDeferredType.NORMAL_OR_CURRENT,
      deferredMonthTime: 1,
      monthGrace: 0,
      totalAmount: this.view.debitCardAmount,
      iva: 0,
      cardType: CardType.DEBIT_CARD,
      userId: this.dataProvider.defaultUser.userId,
      clientId: this.invoice.orderPresenter.clientPresenter.clientId,
      warehouseId: this.dataProvider.defaultWarehouse.warehouseId,
      baseAmountWithTaxes: baseAmountWithTaxes,
      baseAmountWithoutTaxes: new Decimal(
        new Decimal(this.view.debitCardAmount).mul(this.view.subTotal0)
      )
        .div(this.view.totalInvoice)
        .toDecimalPlaces(2)
        .toNumber(),
      transactionTax: new Decimal(baseAmountWithTaxes)
        .mul(GeneralConfigurations.defaultTax)
        .toDecimalPlaces(2)
        .toNumber(),
      bankPresenter: this.view.debitBankSelected
    };
    this.view.blockCard = true;
    this.view.blockUi();
    this.creditCardService.addPayment(transactionPresenter).subscribe(
      (transaction: TransactionPresenter) => {
        const response = transaction.responseTransactionPresenter;
        const lote = this.getPadStart(response.lotNumber, '0', 16);
        const reference = this.getPadStart(
          response.transactionReferenceSequence,
          '0',
          16
        );
        const auth = this.getPadStart(
          response.authorizeNumberTransaction,
          '0',
          8
        );
        const voucherNumber = lote.concat('-', reference, '-', auth);
        const debitCartPayment: PaymentToShow = {
          paymentMethod: PaymentMethod.DEBIT,
          referenceNumber: voucherNumber,
          amount: this.view.debitCardAmount,
          cardPresenter: this.view.debitCardSelected,
          bankPresenter: this.view.debitBankSelected,
          name: 'Tarjeta de débito',
          auth: response.authorizeNumberTransaction,
        };
        this.store.dispatch(new AddPaymentToInvoice(debitCartPayment));
        this.view.voucherNumberDebit = null;
        this.view.debitCardAmount = null;
        this.view.debitEntitySelected = null;
        this.view.debitLote = null;
        this.view.debitReference = null;
        this.view.debitAuth = null;
        this.view.selectedDebitCardBank = null;
        this.view.debitBankSelected = null;
        this.view.debitCardSelected = null;
        this.view.blockCard = false;
        this.view.showSuccess('', 'Transacción generada correctamente');
        this.view.showPdf(response);
      },
      (error: HttpErrorResponse) => {
        this.view.showError('Error al  agregar el pago', error.error.message);
        this.view.blockCard = false;
      }
    );
  }

  getPadStart(value: string, replace: string, length: number) {
    return value.padStart(length, replace);
  }

  saveBankAccount() {
    let accountNumber = 0;
    if (!this.view.selectedBank) {
      this.view.showWarn('Validación', 'Debe seleccionar un banco');
      return;
    }
    if (
      !this.view.accountNumber ||
      this.view.accountNumber.trim().length === 0
    ) {
      this.view.showWarn(
        'Validación',
        'Debe ingresar un número de cuenta válido'
      );
      return;
    }
    // tslint:disable-next-line: radix
    accountNumber = parseInt(this.view.accountNumber);
    if (accountNumber <= 0) {
      this.view.showWarn('Validación', 'Número de cuenta ingresado incorrecto');
      return;
    }
    if (!this.view.relationTypeBankAccountSelected) {
      this.view.showWarn('Validación', 'Debe seleccionar un tipo de cuenta');
      return;
    }
    if (!this.view.client) {
      this.view.showWarn('Validación', 'No hay cliente seleccionado');
      return;
    }
    if (this.view.blockDetails) {
      return;
    }
    this.view.blockDetails = true;
    const bankAccount: BankAccount = {
      bankAccountId: null,
      accountNumber: this.view.accountNumber,
      relationTypeBankAccount: this.view.relationTypeBankAccountSelected.value,
      clientPresenter: this.view.client,
      bankId: this.view.selectedBank.bankId,
    };
    this.clientService
      .saveBankAccount(bankAccount)
      .then((bankAccountResult) => {
        this.view.blockDetails = false;
        const newBankAccounts: { label: string; value: BankAccount }[] = [];
        newBankAccounts.push({
          label: bankAccountResult.accountNumber,
          value: bankAccountResult,
        });
        this.view.clientBankAccounts.forEach((bankAccountFor) => {
          newBankAccounts.push(bankAccountFor);
        });
        this.view.clientBankAccounts = newBankAccounts;
        this.view.clientBankAccountSelected = this.view.clientBankAccounts[0];
      })
      .catch((error) => {
        this.view.blockDetails = false;
        this.view.showError('Error', 'Ocurrió un error desconocido');
      });
  }

  deleteCardPayment(payment: Payment) {
    this.view.blockCard = true;
    this.view.blockUi();
    this.creditCardService
      .deletePayment(payment.auth)
      .subscribe(
        (res: any) => {
          this.deletePayment();
          this.view.showPdf(res.responseTransactionPresenter);
          this.view.blockCard = false;
        },
        (error: HttpErrorResponse) => {
          this.view.showError(
            'Error al  eliminar el pago',
            error.error.message
          );
          this.view.blockCard = false;
        }
      );
  }
  deletePayment() {
    if (this.view.selectedRowIndex !== -1) {
      this.store.dispatch(new DeletePayment(this.view.selectedRowIndex));
      this.view.selectedRowIndex = -1;
      this.view.showError('', 'Pago eliminado');
    }
  }

  validateCpos() {
    const total = round(this.view.totalInvoice, 2);
    const payment = round(this.view.totalPayments, 2);
    const differentPayments = this.view.payments.filter(
      (pay) => pay.paymentMethod === PaymentMethod.CREDIT_CARD
    ).length;
    const paymentCredired = this.view.payments.filter(
      (pay) => pay.paymentMethod === PaymentMethod.CREDIRED
    ).length;

    if (
      this.view.paymentMethodSelected.value.valueOf() ===
      PaymentMethod.CREDIT.valueOf()
    ) {
      if (
        this.view.totalInvoice > this.view.client.amountAvailable &&
        this.view.totalInvoice >
        this.view.client.authorizedOverdraft +
        this.view.client.amountAvailable &&
        this.invoice.orderPresenter.creditStatus !==
        CreditStatus.APPROVED_OVERDRAFT
      ) {
        this.view.requestOverdraft = true;
        this.view.showError(
          'Validación',
          'Cupo no disponible, debe solicitar sobregiro'
        );
        return;
      }
      if (
        this.view.client.amountAvailable < this.view.totalInvoice &&
        this.view.client.authorizedOverdraft +
        this.view.client.amountAvailable >=
        this.view.totalInvoice
      ) {
        const overdraft: number =
          this.view.totalInvoice - this.view.client.amountAvailable;
        this.view.showSuccess(
          'Validación',
          'Sobregiro generado de ' + overdraft + ' automaticamente'
        );
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
    }
    if (this.dataProvider.isCrediRedPrice && !paymentCredired) {
      this.view.showError('', 'Tiene agregado un código credired, debe agregar un pago credired');
      return;
    }
    if (payment < total && !this.view.credit) {
      this.view.showError('', 'Factura no esta totalmente abonada');
      return;
    }
    /* if (
      (this.creditCardPayment ||
        this.dataProvider.processPayment === 'deferred') &&
      !differentPayments &&
      this.procesesSelected === ProcessModel.INVOICE
    ) {
      this.view.showError('Pago diferido activado', 'Añadir pago con tarjeta');
      return;
    } */
    const specialKeywords = ['Tarjeta de crédito', 'Tarjeta de débito', 'De Una'];
    const noSpecialKeywordsInPayments = this.view.payments.every((paymentItem: any) =>
      specialKeywords.every(keyword => !paymentItem.name.includes(keyword))
    );
    if (noSpecialKeywordsInPayments && this.dataProvider.processPayment === 'deferred') {
      this.view.showError('', 'Debe contener al menos un pago con Tarjeta o De Una');
      return;
    }
    this.view.showConfirmation();
  }

  validate() {
    const total = round(this.view.totalInvoice, 2);
    const payment = round(this.view.totalPayments, 2);
    const differentPayments = this.view.payments.filter(pay => pay.paymentMethod === PaymentMethod.CREDIT_CARD).length;

    if (this.view.paymentMethodSelected.value.valueOf() === PaymentMethod.CREDIT.valueOf()) {
      if ((this.view.totalInvoice > this.view.client.amountAvailable) &&
        this.view.totalInvoice > (this.view.client.authorizedOverdraft + this.view.client.amountAvailable) &&
        (this.invoice.orderPresenter.creditStatus !== CreditStatus.APPROVED_OVERDRAFT)) {
        this.view.requestOverdraft = true;
        this.view.showError('Validación', 'Cupo no disponible, debe solicitar sobregiro');
        return;
      }
      if ((this.view.client.amountAvailable < this.view.totalInvoice) &&
        (this.view.client.authorizedOverdraft + this.view.client.amountAvailable) >= this.view.totalInvoice) {
        const overdraft: number = this.view.totalInvoice - this.view.client.amountAvailable;
        this.view.showSuccess('Validación', 'Sobregiro generado de ' + overdraft + ' automaticamente');
      }
      let ifDiscount = false;
      let codesProducts = '';
      this.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (element.discountPromotion < 0) {
          ifDiscount = true;
          codesProducts = ' ' + element.product.referenceCode + ' ';
        }
      });
      if (ifDiscount) {
        this.view.showError('', 'Descuento o promoción no válida' + codesProducts);
        return;
      }
    }
    if (payment < total && !this.view.credit) {
      this.view.showError('', 'Factura no esta totalmente abonada');
      return;
    }
    /* if ((this.creditCardPayment || this.dataProvider.processPayment === 'deferred')
      && !differentPayments && this.procesesSelected === ProcessModel.INVOICE) {
      this.view.showError('Pago diferido activado', 'Añadir pago con tarjeta');
      return;
    } */
    // Validacion para pago mixto
    const specialKeywords = ['Tarjeta de crédito', 'Tarjeta de débito', 'De Una'];
    const noSpecialKeywordsInPayments = this.view.payments.every((paymentItem: any) =>
      specialKeywords.every(keyword => !paymentItem.name.includes(keyword))
    );
    if (noSpecialKeywordsInPayments && this.dataProvider.processPayment === 'deferred') {
      this.view.showError('', 'Debe contener al menos un pago con Tarjeta o De Una');
      return;
    }
    this.view.showConfirmation();

  }


  getBanksByCriteria() {
    this.view.blockUi();
    this.bankService.banksByCard().subscribe(
      (res: Bank[]) => {
        this.view.cardBanks = res;
        this.view.cardBanksCopy = res;
        if (this.view.cardBanks.length === 0) {
          this.view.cardBanks = [];
          this.view.showError('Bancos', 'Búsqueda sin registros');
        } else {
          // tslint:disable-next-line: only-arrow-functions
          this.view.cardBanks = res.sort(function (a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.dataProvider.banksSpecialDay = [];
          this.dataProvider.banksSpecialDayNames = '';
          this.view.cardBanks.forEach((bank) => {
            if (bank.promoBank) {
              this.dataProvider.banksSpecialDay.push(bank);
              let name = bank.name.toLowerCase();
              name = name[0].toUpperCase() + name.slice(1);

              this.dataProvider.banksSpecialDayNames =
                this.dataProvider.banksSpecialDayNames + name + ', ';
            }
          });
          if (this.dataProvider.banksSpecialDayNames.length) {
            this.dataProvider.banksSpecialDayNames =
              this.dataProvider.banksSpecialDayNames.slice(0, -2);
          }
          if (
            this.dataProvider.banksSpecialDay.length &&
            this.creditCardPayment
          ) {
            this.view.showBanksSpecialDay();
          }
        }
        this.evaluatePayemnts();
      },
      () => {
        this.view.cardBanks = [];
        this.view.showError('', 'Error al buscar bancos');
      }
    );
  }
  filterCardsPresenter() {
    if (this.view.paymentMethodSelected.value === PaymentMethod.CREDIT_CARD) {
      this.view.creditCards =
        this.view.creditBankSelected.cardPresenters.filter(
          (card) => card.credit
        );
      if (
        this.view.creditBankSelected.promoBank &&
        !this.view.isPromoDay &&
        this.dataProvider.processPayment === 'deferred'
      ) {
        this.view.showSelectedBanksSpecialDay();
      }
    } else if (this.view.paymentMethodSelected.value === PaymentMethod.DEBIT) {
      this.view.debitCards = this.view.debitBankSelected.cardPresenters.filter(
        (card) => card.debit
      );
    }
  }

  setNewInvoice(promoDayBank: boolean) {
    GeneralConfigurations.applyPromoDay = true;

    this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
    this.store.dispatch(new ClearInvoice());
    if (this.dataProvider.lastPromotion) {
      const res = this.dataProvider.lastPromotion;
      if (res.optional) {
        this.store.dispatch(new ApplyPromotions(res.optional.promo));
      }
    }
    this.view.isPromoDay = true;
    this.invoiceBalancePresenter.saveInvoceOrderAndDispatch(false);
    this.view.paymentsMethods = null;
    this.view.paymentsMethods = [
      { label: 'Tarjeta de Débito', value: PaymentMethod.DEBIT },
      { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD },
      { label: 'De Una', value: PaymentMethod.DE_UNA },
    ];
    this.view.cardBanks = null;
    this.view.cardBanks = this.dataProvider.banksSpecialDay;
    if (!promoDayBank) {
      this.view.showSuccess('', 'Su nuevo total es de ' + this.view.totalInvoice);
    }
  }

  validateTotal() {
    this.store.pipe(select('invoice')).subscribe((state) => {
      state.invoice.orderPresenter.orderDetailPresenters.forEach(element => {
        if (round(element.unitPrice, 4) < round(element.chainPrice, 4)) {
          this.view.isLowerPrice = true;
        }
      });
      if (state.invoice.orderPresenter.isQuotation !== undefined) {
        this.view.isQuotation = state.invoice.orderPresenter.isQuotation;
      }
      this.view.payments = [];
      if (state && state.invoice) {
        this.invoice = state.invoice;
        this.view.totalInvoice = state.invoice.orderPresenter.total;
        this.view.totalInvoice = new Decimal(this.view.totalInvoice)
          .toDecimalPlaces(2)
          .toNumber();
        let totalPayment = 0;
        this.creditControl = 0;
        this.debitControl = 0;
        this.paymentsBanksPromo = false;
        if (state && state.payments) {
          state.payments.forEach((payment) => {
            this.view.payments.push(payment);
            totalPayment = totalPayment + Number(payment.amount);
            if (payment.paymentMethod === PaymentMethod.CREDIT_CARD) {
              this.creditControl += Number(payment.amount);
              if (!payment.bankPresenter.promoBank) {
                this.paymentsBanksPromo = true;
              }
            }
            if (payment.paymentMethod === PaymentMethod.DEBIT) {
              this.debitControl += Number(payment.amount);
            }
          });
          this.creditControl = new Decimal(this.creditControl).toDecimalPlaces(2).toNumber();
          this.debitControl = new Decimal(this.debitControl).toDecimalPlaces(2).toNumber();
        }
        if (
          (this.view.client.totalAmountAvailable > 0 &&
            this.invoice.orderPresenter.creditStatus ===
            CreditStatus.APPROVED) ||
          this.invoice.orderPresenter.creditStatus ===
          CreditStatus.APPROVED_OVERDRAFT
        ) {
          this.view.paymentsMethods = [
            { label: 'Crédito', value: PaymentMethod.CREDIT },
          ];
          this.view.paymentMethodSelected = {
            label: 'Crédito',
            value: PaymentMethod.CREDIT,
          };
          this.view.amountFee = round(
            this.view.totalInvoice /
            this.view.client.paymentTermPresenter.paymentTermFees,
            2
          );
          this.view.amountFee = round(
            this.view.totalInvoice /
            this.view.client.paymentTermPresenter.paymentTermFees,
            2
          );
          this.view.credit = true;
          this.view.totalPayments = this.view.totalInvoice;
        } else {
          this.setPayments();
        }
        this.view.totalAdvance = round(
          totalPayment - this.view.totalInvoice,
          2
        );
        this.view.pending = round(this.view.totalInvoice - totalPayment, 2);
        this.view.totalPayments = totalPayment;
      } else {
        this.invoice = null;
        this.view.payments = [];
        this.view.totalInvoice = 0;
        this.view.totalAdvance = 0;
        this.view.totalPayments = 0;
      }
    });
    this.view.showSuccess('', 'Su nuevo total es de ' + this.view.totalInvoice);
  }

  validatePromoBank() {
    if (this.dataProvider.processPayment === 'deferred') {
      if (this.view.creditBankSelected.promoBank) {
        this.view.creditCardTypes = [
          { label: 'Crédito sin intereses', value: CreditCardType.CREDIT_WITHOUT_INTEREST },
        ];
      } else {
        this.view.creditCardTypes = [
          { label: 'Corriente', value: CreditCardType.CURRENT_PAYMENT },
          { label: 'Crédito con intereses', value: CreditCardType.CREDIT_WITH_INTEREST }
        ];
      }
    }
  }

  filterCards() {
    if (this.view.creditCardSelected) {
      this.view.creditCardSelected.graceMonthsBank = [];
      this.view.creditCardSelected.paymentFeesBank = [];
      const names: string[] = [];
      const namespayments: string[] = [];
      // tslint:disable-next-line: only-arrow-functions
      const graces = this.view.creditCardSelected.graceMonths
        .split(',')
        .map(function (item) {
          const graceMonthsBank: GraceMonthsBank = {
            number: parseInt(item, 10),
            name: item.trim(),
          };
          return graceMonthsBank;
        });
      // tslint:disable-next-line: only-arrow-functions
      const payments = this.view.creditCardSelected.paymentFees
        .split(',')
        .map(function (item) {
          const paymentsFees: PaymentFees = {
            number: parseInt(item, 10),
            name: item.trim(),
          };
          return paymentsFees;
        });
      graces.forEach((grace) => {
        if (
          !names.includes(grace.name) &&
          grace.name.length >= 1 &&
          !isNaN(grace.number)
        ) {
          this.view.creditCardSelected.graceMonthsBank.push(grace);
          names.push(grace.name);
        }
      });
      payments.forEach((payment) => {
        if (
          !namespayments.includes(payment.name) &&
          payment.name.length >= 1 &&
          !isNaN(payment.number)
        ) {
          this.view.creditCardSelected.paymentFeesBank.push(payment);
          namespayments.push(payment.name);
        }
      });
      if (this.view.isPromoDay) {
        if (
          this.view.creditCardAmount &&
          this.view.creditCardAmount >=
          GeneralConfigurations.defaultAmountCreditCard
        ) {
          this.view.creditCardSelected.paymentFeesBank = null;
          this.view.creditCardSelected.paymentFeesBank = [
            { number: 3, name: '3' },
            { number: 6, name: '6' },
            { number: 9, name: '9' },
            { number: 12, name: '12' },
            { number: 15, name: '15' },
            { number: 18, name: '18' },
            { number: 21, name: '21' },
            { number: 24, name: '24' },
          ];
        } else {
          this.view.creditCardSelected.paymentFeesBank = null;
          this.view.creditCardSelected.paymentFeesBank = [
            { number: 3, name: '3' },
            { number: 6, name: '6' },
            { number: 9, name: '9' },
            { number: 12, name: '12' },
          ];
        }
        this.view.creditCardSelected.graceMonthsBank = null;
        this.view.creditCardSelected.graceMonthsBank = [
          { number: 2, name: '2' },
        ];
        this.view.selectedGrace = { number: 2, name: '2' };
      }
    }
  }

  generateInvoice() {
    if (this.view.blockDetails) {
      return;
    }
    const total = round(this.view.totalInvoice, 2);
    const payment = round(this.view.totalPayments, 2);
    if (payment < total && !this.view.credit) {
      this.view.showError('', 'factura no esta totalmente abonada');
      return;
    }

    if (this.view.isChange === 'Cambio') {
      this.invoice.advance = false;
    } else {
      this.invoice.advance = true;
    }
    this.invoice.credit = this.view.credit;
    this.view.blockDetails = true;
    /* const bankPresenter: Bank = {
        bankId: '3935212c-168d-418b-abb2-4f5728bb0b3f',
        name: 'PICHINCHA',
        description: null,
        referenceId: '5637144577',
        active: null,
        cardPresenters: [],
        promoBank: false
    } */
    const bankPresenter: Bank = this.view.cardBanks.find(obj => obj.referenceId === '5637144577');
    const payments: Payment[] = [];
    if (this.view.payments) {
      this.view.payments.forEach((line) => {
        const pay: Payment = {
          amount: line.amount,
          bankAccountPresenter: line.bankAccountPresenter,
          cardPresenter: line.cardPresenter,
          confirmationCode: line.confirmationCode,
          effectiveDate: line.effectiveDate,
          paymentMethod: line.paymentMethod,
          referenceNumber: line.referenceNumber,
          bankPresenter: line.paymentMethod === PaymentMethod.DE_UNA ? bankPresenter : line.bankPresenter,
          creditType: line.creditType,
          paymentFees: line.paymentFees,
          graceMonths: line.graceMonths,
        };
        payments.push(pay);
      });
    }
    if (!this.invoice.observation) {
      this.invoice.observation = '';
    }
    if (!this.invoice.orderPresenter.observation) {
      this.invoice.orderPresenter.observation = '';
    }
    this.invoice.paymentLinePresenters = payments;
    this.invoice.paymentTermPresenter =
      this.invoice.orderPresenter.paymentTermPresenter;
    this.invoice.paymentMethod = this.invoice.orderPresenter.paymentMethod;
    this.invoice.adviserPresenter = this.view.adviser;
    this.invoice.sellerPresenter = this.view.seller;
    this.invoice.commissionCard = this.dataProvider.processPayment === 'deferred'
      ? this.dataProvider.commissionCard : 0;
    if (this.procesesSelected === ProcessModel.INVOICE) {
      this.invoiceService.saveOrderInvoiceAndDispatch(this.invoice).then((invoiceResult) => {
        this.invoiceService.postSyncroniceInvoiceAndDispatch(invoiceResult.invoiceId).subscribe();
        this.view.dispose(true);
        this.view.showInfo('Factura', 'Se generó correctamente');
        this.view.showPdf(invoiceResult);
        this.store.dispatch(new ClearEditOrder());
        this.store.dispatch(new ClearClient());
        this.store.dispatch(new ClearAdviser());
        this.store.dispatch(new DeleteAllOrderLines());
        this.store.dispatch(new ClearInvoice());
        this.store.dispatch(new SetObservationInRedux(''));
        this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.store.dispatch(new SetBilleton(false));
        this.store.dispatch(new SetAdminPriceDiscount(false));
        this.store.dispatch(new ClearSeller());
        this.dataProvider.processPayment = undefined;
        this.advanceService.advanceStatus();
        this.defaultconfigurationsService.getGeneralConfigurations();
        this.dataProvider.blockPaymentsByAdminPrice = false;
        this.dataProvider.isCrediRedPrice = false;
        this.dataProvider.quotationStatus = undefined;
        this.productQuestPresenter.clearAll();
      })
        .catch((error) => {
          this.view.blockDetails = false;
          if (error instanceof HttpErrorResponse) {
            this.view.showError('Atención', error.error.message);
          } else {
            this.view.showError('Atención', 'Algo salio mal facturando');
          }
        });
    } else {
      this.invoiceService
        .saveInvoiceFromOrder(this.invoice)
        .then((invoiceResult) => {
          this.view.dispose(true);
          this.view.showInfo('Factura', 'Se generó correctamente');
          this.view.showPdf(invoiceResult);
          this.store.dispatch(new ClearEditOrder());
          this.store.dispatch(new ClearClient());
          this.store.dispatch(new DeleteAllOrderLines());
          this.store.dispatch(new ClearInvoice());
          this.store.dispatch(new SetObservationInRedux(''));
          this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
          this.store.dispatch(new SetBilleton(false));
          this.store.dispatch(new SetAdminPriceDiscount(false));
          this.advanceService.advanceStatus();
          this.defaultconfigurationsService.getGeneralConfigurations();
          this.dataProvider.blockPaymentsByAdminPrice = false;
          this.dataProvider.isCrediRedPrice = false;
          this.dataProvider.quotationStatus = undefined;
          this.productQuestPresenter.clearAll();
        })
        .catch((error) => {
          this.view.blockDetails = false;
          if (error instanceof HttpErrorResponse) {
            this.view.showError('Atención', error.error.message);
          } else {
            this.view.showError('Atención', 'Algo salio mal facturando');
          }
        });
    }
  }

  generateOverdraft() {
    this.view.blockUi();
    this.invoice.orderPresenter.creditStatus = CreditStatus.PENDING;
    this.orderService
      .saveOrder(this.invoice.orderPresenter)
      .then((data) => {
        this.view.showPdf(data);
        this.view.dispose(true);
        this.store.dispatch(new ClearEditOrder());
        this.store.dispatch(new ClearClient());
        this.store.dispatch(new ClearAdviser());
        this.store.dispatch(new DeleteAllOrderLines());
        this.store.dispatch(new ClearInvoice());
        this.store.dispatch(new SetObservationInRedux(''));
        this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.view.showSuccess('', 'Sobregiro solicitado con éxito');
        this.dataProvider.blockPaymentsByAdminPrice = false;
      })
      .catch(() => {
        this.view.showError('Atención', 'Error al generar sobregiro');
      });
  }

  loadBanksFromAtix() {
    this.bankService.ownBanks().subscribe(
      (res: BankAccount[]) => {
        this.view.ownBanks = [];
        res.forEach((bankAcount) => {
          const bankIsPresent = this.view.ownBanks.find(
            (bank) => bank.bankId === bankAcount.bankPresenter.bankId
          );
          if (!bankIsPresent) {
            this.view.ownBanks.push(bankAcount.bankPresenter);
          }
        });
        this.view.selectedBank = null;
        this.dataProvider.bankAcounts = res;
      },
      () => {
        this.view.showError('', 'No hay bancos ');
      }
    );
  }

  filterBankAccounts() {
    this.view.bankAccountSelected = null;
    this.view.filteredBankAccounts = this.dataProvider.bankAcounts.filter(
      (bankAcount) =>
        this.view.selectedBank.bankId === bankAcount.bankPresenter.bankId
    );
  }
  getCoupons() {
    if (this.view.client.dni !== '9999999999999') {
      this.orderLines.forEach((line) => (line.subtotal = line.subTotal));
      this.view.blockUi();
      this.couponService.verifyCouponApply(this.orderLines).subscribe(
        (res: boolean) => {
          // if (res) {
          //     this.view.showCoupon();
          // }
        },
        (error) => {
          this.view.showBackException(error);
        }
      );
    }
  }

  onTypeCreditChange() {
    if (
      this.view.creditCardTypeSelected.valueOf() ===
      CreditCardType.CREDIT_WITH_INTEREST
    ) {
      this.view.creditDeferredTypes = [
        {
          label: 'Diferido cuota fija con interes',
          value: CreditDeferredType.DEFER_FIX_FEE_WITH_INTEREST,
        },
        // { label: 'Diferido pago mes a mes con interes', value: CreditDeferredType.DEFER_PAY_MONTH_TO_MONTH_WITH_INTEREST },
        // { label: 'Diferido pago mes gracia con interes', value: CreditDeferredType.DEFER_PAY_MONTH_GRACE_WITH_INTEREST },
        // { label: 'Diferido con meses de gracia con interes', value: CreditDeferredType.DEFER_WITH_MONTHS_GRACE_WITH_INTEREST },
      ];
    } else if (
      this.view.creditCardTypeSelected.valueOf() ===
      CreditCardType.CREDIT_WITHOUT_INTEREST
    ) {
      this.view.creditDeferredTypes = [
        {
          label: 'Diferido cuota fija sin intereses',
          value: CreditDeferredType.DEFER_FIX_FEE_WITHOUT_INTEREST,
        },
        // { label: 'Diferido pago mes a mes sin intereses', value: CreditDeferredType.DEFER_PAY_MONTH_WITHOUT_INTEREST },
        {
          label: 'Diferido con meses de gracia sin interes',
          value: CreditDeferredType.DEFER_WITH_MONTHS_GRACE_WITHOUT_INTEREST,
        },
        // { label: 'Diferido especial sin intereses', value: CreditDeferredType.SPECIAL_DEFER_WITHOUT_INTEREST },
      ];
    }
    if (
      this.view.creditCardTypeSelected.valueOf() !==
      CreditCardType.CREDIT_WITHOUT_INTEREST
    ) {
      this.view.selectedGrace = null;
    }
  }
  getConnectionCpos() {
    this.creditCardService
      .getConnectionCpos(
        this.dataProvider.defaultUser.userId
      )
      .subscribe((res: boolean) => {
        this.dataProvider.isConnectionCpos = res;
      });
  }
  getDefaultConfigurationCpos() {
    this.creditCardService
      .getDefaultConfigurationCpos()
      .subscribe((res: any) => {
        this.dataProvider.isConfigurationCpos = res;
      });
  }
}
